import React from "react";
import styles from "./headerDropDown.module.scss";
import { MainButton } from "@web/shared/dist/components/Old/MainButton/MainButton";

interface IProps {
  item:
    | {
        title: string;
        pathname: string;
        type: string;
        navigation: null;
        button?: undefined;
      }
    | {
        title: string;
        pathname: string;
        navigation: {
          section: string;
          data: {
            title: JSX.Element;
            href: string;
          }[];
          linkSection: string;
        }[];
        type: string;
        button: {
          title: string;
          buttonLink: string;
        };
      };
  isReponsiveTabOpen: boolean;
}

export const HeaderDropdown = ({
  item,
  isReponsiveTabOpen,
}: IProps): JSX.Element => {
  return (
    <nav
      className={
        styles.headerDropdown + " " + `${isReponsiveTabOpen && styles.visible}`
      }
    >
      <ul className={styles.headerDropdown__navigationList}>
        {item.navigation &&
          item.navigation.map((elem, index) => {
            return (
              <div className={styles.headerDropdown__menuSection} key={index}>
                {elem.section && (
                  <div className={styles.headerDropdown__sectionName}>
                    <p>{elem.section.toUpperCase()}</p>
                  </div>
                )}
                {elem.data.map((elem, index) => {
                  return (
                    <li
                      key={index}
                      className={`${styles.headerDropdown__sectionListItem}`}
                    >
                      <a href={elem.href}>{elem.title}</a>
                    </li>
                  );
                })}
                {elem.linkSection && (
                  <div className={styles.headerDropdown__linkSection}>
                    <a href={`${elem.linkSection}s`}>
                      <p>
                        Voir plus de guides {elem.section?.toLocaleLowerCase()}
                      </p>
                    </a>
                  </div>
                )}
              </div>
            );
          })}

        <div className={styles.headerDropdown__VoirTousLesGuides}>
          <MainButton
            name="Tous les guides"
            onClick={() => window.open("https://www.tucoenergie.fr/guides")}
            color="lightOrange"
          />
        </div>
      </ul>
    </nav>
  );
};
/* eslint-enable */
