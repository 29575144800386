import "./register.scss";

// Libraries import
import React from "react";

// Components import
import { RegisterWindow } from "../../containers/register/RegisterWindow/RegisterWindow";

export const RegisterPage: React.FC = () => {
  return (
    <div className="register-page">
      <RegisterWindow />
    </div>
  );
};
