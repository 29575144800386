import "./PriseEnMainTabContent.scss";

// Libraries import
import React from "react";

// Services import
import { formatDataTabPriseEnMain } from "../../../services/ProjectFollowUp/SuiviChantier/formatDataTabPriseEnMain";
import { notificationDictionary } from "../../../data/notificationDictionary";

// Images import
import ImagePriseEnMain from "../../../assets/images/ProjectFollowUp/image_prise_en_main.svg";
import { ModalContainer } from "../../Modal/Modal";
import { ModalRescheduleRDV } from "../ModalRescheduleRDV/ModalRescheduleRDV";
import { useModal } from "../../../services/useModal";

// Interfaces import
import { IChantierInformation } from "../../../interfaces/interfaces";

// Local interface declaration
interface Props {
  chantierInformation: IChantierInformation;
}

export const PriseEnMainTabContent: React.FC<Props> = ({
  chantierInformation,
}) => {
  const { toggleModal, isModalVisible } = useModal(); // Modal setup : Custom hook that handles the display of the modal on the screen.

  const { message, isCancelMeetingDisplayed, notification } =
    formatDataTabPriseEnMain(chantierInformation);

  return (
    <div className="PriseEnMainTabContent">
      <div className="PriseEnMainTabContent__PriseEnMain">
        <div className="PriseEnMainTabContent__Picto">
          {notification != "" && (
            <img src={notificationDictionary[notification]} alt="Picto alert" />
          )}
        </div>
        <div className="RaccordementTabContent__Text">
          <div className="PriseEnMainTabContent__Title">
            <p>Prise en main de votre projet</p>
          </div>

          <div className="PriseEnMainTabContent__Message">
            {message}
            {isCancelMeetingDisplayed && (
              <div className="PriseEnMainTabContent__OpenModal">
                <p
                  onClick={(e) => {
                    e.stopPropagation();
                    toggleModal();
                  }}
                >
                  Je ne pourrai pas être présent au rdv
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="PriseEnMainTabContent__Image">
        <img
          src={ImagePriseEnMain}
          alt="House process"
          width={122}
          height={109}
        />
      </div>
      {isModalVisible && (
        <ModalContainer
          onClick={(e) => {
            e.stopPropagation();
            toggleModal();
          }}
        >
          <ModalRescheduleRDV />
        </ModalContainer>
      )}
    </div>
  );
};
