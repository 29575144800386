// Libraries import
import { useState } from "react";
import { useAxiosPost } from "../../services/useAxiosPost";

// Import services
import { useSetUserAuthInfo } from "../../services/useSetUserAuthInfo";

// Interfaces import
import {
  ILoginRequestResponse,
  ILoginUserBody,
} from "../../interfaces/interfaces";

// Local interfaces delcaration
interface IFunctionReturn {
  requestLogin: (email: string, password: string) => Promise<void>;
  isLoading: boolean;
  errorMessage: string;
}

// useLoginUser : return tools to send login request and handle error messages
// Parameters
// ----------
// RAS
//
// Returns
// ----------
// Function axiosPostRequest
//     Function to call to send login request
// Boolean isLoading
//     Indicate if the asynchronous request is on going
// String errorMessage
//     Error message (no parameters or request error message)

export const useLoginUser = (): IFunctionReturn => {
  const BACKEND_URL = process.env.REACT_APP_API_AUTHENTICATION_URL;
  const {
    axiosPostRequest,
    isLoading: axiosIsLoading,
    errorMessage: axiosErrorMessage,
  } = useAxiosPost();
  const [errorMessage, setErrorMessage] = useState<string>("");
  const { setUserAuthInfo } = useSetUserAuthInfo();

  // requestLogin : send login Post request and save user authentication info in redux store and in local storage if successfull
  // Parameters
  // ----------
  // username: String
  //      the username of the user (usually an email)
  // password: String
  //      the password of the user
  // Returns
  // ----------
  // RAS

  const requestLogin = async (
    username: string,
    password: string
    // isRememberMeClicked: boolean,
  ): Promise<void> => {
    setErrorMessage("");
    if (username && password) {
      const loginUserBody: ILoginUserBody = { username, password };
      const response = await axiosPostRequest<ILoginRequestResponse>(
        `${BACKEND_URL}/api/User/login`,
        loginUserBody
      );
      response?.data &&
        setUserAuthInfo({
          userToken: response.data.token,
          userId: response.data.userId,
        });
    } else {
      setErrorMessage("Veuillez renseigner tous les champs.");
    }
  };

  return {
    requestLogin,
    isLoading: axiosIsLoading,
    errorMessage: errorMessage || axiosErrorMessage,
  };
};
