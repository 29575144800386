import "./DocumentNotCompliant.scss";

// Libraries imports
import React, { ReactElement } from "react";

// Images import
import NonConformePicto from "../../../assets/images/uploadDocuments/non-conforme.svg";

// Components imports
import { ButtonUpload } from "../../../components/ButtonUpload/ButtonUpload";
import { Status } from "../../../components/Status/Status";

// Services import
import { IuploadDocumentsInformation } from "../../../interfaces/interfaces";

// Local interface declaration
interface Props {
  document: IuploadDocumentsInformation;
  onClickUploadDocument: (clickedDoc: IuploadDocumentsInformation) => void;
}

export const DocumentNotCompliant: React.FC<Props> = ({
  document,
  onClickUploadDocument,
}): ReactElement => {
  return (
    <div className="DocumentNotCompliant">
      <div className="DocumentNotCompliant__Header">
        <div className="DocumentNotCompliant__DocumentName">
          <img src={NonConformePicto} alt="Pictogramme non conforme" />
          <div className="DocumentNotCompliant__DocumentNameText">
            <p>{document.fileName}</p>
            {/* A status null means that the document has been uploaded */}
            <Status status={document.status || "En cours de validation"} />
          </div>
        </div>
      </div>
      <div className="DocumentNotCompliant__FileName"></div>
      <div className="DocumentNotCompliant__Commentaire">
        {document.commentaires}
      </div>
      <div className="DocumentNotCompliant__UploadButton">
        <ButtonUpload
          title="Joindre un nouveau fichier"
          onClick={() => onClickUploadDocument(document)}
        />
      </div>
    </div>
  );
};
