// Import libraries
import { devToolsEnhancer } from "@redux-devtools/extension";
import { createStore } from "redux";
import { appReducer } from "../appReducer";

// Store creation
export const store = createStore(appReducer, devToolsEnhancer());

// Type declaration for useSelector
export type RootState = ReturnType<typeof store.getState>;
// Type declaration for useDispatch
export type AppDispatch = typeof store.dispatch;
