// Libraries import
import { useAxiosGet } from "../../services/useAxiosGet";

// Services import
import { useAppDispatch } from "../../redux/store/hook";
import { setUploadDocumentsInfoAction } from "../../redux/appActions";

// Interfaces import
import { IuploadDocumentsInformation } from "../../interfaces/interfaces";

// Interface delcaration
interface IFunctionReturn {
  requestUploadDocumentsInfo: (
    userToken: string | null,
    userId: string | null
  ) => Promise<void>;
  isLoading: boolean;
  errorMessage: string;
}

// useGetUploadDocumentsInfo : return tools to request upload documents information to CRM and handle error
// Parameters
// ----------
// RAS
//
// Returns
// ----------
// Function requestUploadDocumentsInfo
//     Function to call to send upload document information request
// Boolean isLoading
//     Indicate if the asynchronous request is onGoing
// String errorMessage
//     Error message (no parameters or request error message)

export const useGetUploadDocumentsInfo = (): IFunctionReturn => {
  // Get env. variable
  const BACKEND_URL = process.env.REACT_APP_API_AUTHENTICATION_URL;

  // Hooks calls
  const dispatch = useAppDispatch();
  const {
    axiosGetRequest,
    isLoading,
    errorMessage: axiosErrorMessage,
  } = useAxiosGet();

  // requestUploadDocumentsInfo : send get upload documents information request
  // Parameters
  // ----------
  // userToken: String | null
  //      the JWT authentication token of the user
  // userId: String | null
  //      the unique id of the project
  // Returns
  // ----------
  // Void

  const requestUploadDocumentsInfo = async (
    userToken: string | null,
    userId: string | null
  ): Promise<void> => {
    const response = await axiosGetRequest<IuploadDocumentsInformation[]>(
      `${BACKEND_URL}/api/User/${userId}/GetUploadDocumentsInformation`,
      {
        headers: {
          authorization: `Bearer ${userToken}`,
        },
      }
    );
    // If we have at least one document in the reponse array, we save the upload document information in redux store
    if (response && response.data?.length > 0) {
      dispatch(
        setUploadDocumentsInfoAction({ uploadDocumentsInfo: response.data })
      );
    }
  };

  return {
    requestUploadDocumentsInfo,
    isLoading,
    errorMessage: axiosErrorMessage,
  };
};
