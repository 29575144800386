import React from "react";
import "./MaCommande.scss";

// Libraries import
import { useParams } from "react-router-dom";

// Components import
import { MaCommandeContent } from "../../containers/MaCommande/MaCommandeContent/MaCommandeContent";
import { Layout } from "../../components/Layout/Layout";
import { MaCommandeHero } from "../../containers/MaCommande/MaCommandeHero/MaCommandeHero";
import { BannerContainer } from "../../components/BannerContainer/BannerContainer";

// Local interfaces declaration
type MaCommandePageParams = {
  commandId?: string;
};

export const MaCommande: React.FC = () => {
  const { commandId } = useParams<MaCommandePageParams>();
  const queryParams = new URLSearchParams(window.location.search);
  const orderNumber = queryParams.get("orderNumber");
  const date = queryParams.get("date");
  const dateFormat = date
    ? new Date(date).getDate() +
      "/" +
      (new Date(date).getMonth() + 1) +
      "/" +
      new Date(date).getFullYear()
    : null;

  return (
    <div className="maCommande">
      <div className="maCommande__BannerMobile mobileOnly">
        <BannerContainer>
          <MaCommandeHero commandId={commandId} />
        </BannerContainer>
      </div>
      <Layout>
        <div className="maCommande__Content">
          <div className="maCommande__BannerDesktop desktopOnly">
            <BannerContainer>
              <MaCommandeHero
                orderDate={dateFormat || undefined}
                commandId={commandId}
              />
            </BannerContainer>
          </div>
          <div className="maCommande__MaCommandeContent">
            <div className="maCommande__DynamicMargin" />
            <MaCommandeContent
              commandId={commandId}
              orderNumber={orderNumber}
            />
          </div>
        </div>
      </Layout>
    </div>
  );
};
