import "./resetPassword.scss";

// Libraries import
import React from "react";

// Components import
import { ResetPasswordWindow } from "../../containers/authentication/ResetPasswordWindow/resetPasswordWindow";

export const ResetPassword: React.FC = () => {
  return (
    <div className="reset-password-page">
      <ResetPasswordWindow />
    </div>
  );
};
