import React from "react";

export const formatTabDate = (
  date?: string | null,
  isHour = true
): JSX.Element => {
  const formatedDate = new Date(date || "");

  return (
    <>
      <strong>{formatedDate.toLocaleDateString("fr")}</strong>{" "}
      {isHour && (
        <>
          {"à " +
            String(formatedDate.getHours() + 1).padStart(2, "0") +
            "h" +
            String(formatedDate.getMinutes()).padStart(2, "0")}
        </>
      )}
    </>
  );
};
