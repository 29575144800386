import "./authenticationWindow.scss";

// Libraries import
import React, { useState } from "react";

// Components import
import { AuthenticationWelcome } from "../AuthenticationWelcome/AuthenticationWelcome";
import { AuthenticationCredentials } from "../AuthenticationCredentials/AuthenticationCredentials";
import { ForgotPassword } from "../ForgotPassword/ForgotPassword";

export const AuthenticationWindow: React.FC = () => {
  const [isForgotPasswordClicked, setIsForgotPasswordClicked] =
    useState<boolean>(false);

  return (
    <>
      <div className="authentication-window">
        <AuthenticationWelcome />
        {!isForgotPasswordClicked ? (
          <>
            <AuthenticationCredentials
              isForgotPasswordClicked={isForgotPasswordClicked}
              setIsForgotPasswordClicked={setIsForgotPasswordClicked}
            />
            <div className="authentication-window__EspacePro">
              <p>Vous êtes un professionnel&nbsp;? Connectez vous&nbsp;:</p>
              <a
                className="espaceProButton"
                href={process.env.REACT_APP_API_VITEE_URL}
              >
                Espace PRO
              </a>
            </div>
          </>
        ) : (
          <ForgotPassword
            isForgotPasswordClicked={isForgotPasswordClicked}
            setIsForgotPasswordClicked={setIsForgotPasswordClicked}
          />
        )}
      </div>
    </>
  );
};
