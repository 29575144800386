export interface IProjectAppointmentInfos {
  dateAppelPriseEnMain: string | null;
  lienBooking: string | null;
  lienteams: string | null;
  stautPriseEnMain: string | null;
}

// Local interfaces declaration
export interface Props {
  projectAppointmentInfos?: IProjectAppointmentInfos;
  statutControleTravaux: string | null;
}

export const formatStatusTabPriseEnMain = ({
  projectAppointmentInfos,
  statutControleTravaux,
}: Props):
  | "Réalisée"
  | "À planifier"
  | "Planifiée"
  | "À venir"
  | "Information non disponible" => {
  const formatedDatePriseEnMain = new Date(
    projectAppointmentInfos?.dateAppelPriseEnMain || ""
  );
  const currentDate = new Date().getTime();

  if (statutControleTravaux) {
    if (statutControleTravaux === "Réalisé validé") {
      if (projectAppointmentInfos?.dateAppelPriseEnMain) {
        if (currentDate - formatedDatePriseEnMain.getTime() > 0) {
          return "Réalisée";
        }
        return "Planifiée";
      }
      return "À planifier";
    }
    return "À venir";
  }
  return "À venir";
};
