import "./resetPasswordWelcome.scss";

// Libraries import
import React from "react";

export const ResetPasswordWelcome: React.FC = () => {
  return (
    <div className="reset-password-welcome">
      <div className="reset-password-welcome__message">
        <p>Réinitialiser mon mot de passe</p>
      </div>
    </div>
  );
};
