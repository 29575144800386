import React from "react";

import "./modalRescheduleRDV.scss";

export const ModalRescheduleRDV: React.FC = () => {
  return (
    <div className="ModalRescheduleRDV">
      <div className="ModalRescheduleRDV__ModaleTitle">
        <h3>Modifier le rendez-vous</h3>
      </div>
      <p>
        Vous ne pourrez pas être présent à la date fixée ?<br />
        Faites-le-nous savoir et nous replanifierons un nouveau rendez-vous.
      </p>
      <div className="ModalRescheduleRDV__CallUs">
        <a href="tel:0183628481">
          <p>01 83 62 84 81</p>
        </a>
      </div>
    </div>
  );
};
