// Libraries import
import { useState } from "react";
import { useAxiosGet } from "../useAxiosGet";

// Services import
import { useAppDispatch } from "../../redux/store/hook";
import { setChantierInformationAction } from "../../redux/appActions";

// Interfaces import
import { IChantierInformation } from "../../interfaces/interfaces";

// Local interface declaration
interface IFunctionReturn {
  requestChantierInformation: (
    userToken: string | null,
    commandId: string | null
  ) => Promise<void>;
  isLoading: boolean;
  errorMessage: string;
}

// useGetSuiviProjet : return tools to send get chantier information request to CRM and handle error
// Parameters
// ----------
// RAS
//
// Returns
// ----------
// Function requestChantierInformation
//     Function to call to get chantier information
// Boolean isLoading
//     Indicate if the asynchronous request is onGoing
// String errorMessage
//     Error message (no parameters or request error message)

export const useGetSuiviProjet = (): IFunctionReturn => {
  const BACKEND_URL = process.env.REACT_APP_API_AUTHENTICATION_URL;
  const {
    axiosGetRequest,
    isLoading,
    errorMessage: axiosErrorMessage,
  } = useAxiosGet();
  const dispatch = useAppDispatch();
  const [errorMessage, setErrorMessage] = useState<string>("");

  // requestChantierInformation : send Get chantier information request.
  // Parameters
  // ----------
  // userToken: String | null
  //      the JWT authentication token of the user
  // commandId: String | null
  //      the unique id of the command
  // Returns
  // ----------
  // Void

  const requestChantierInformation = async (
    userToken: string | null,
    commandId: string | null
  ): Promise<void> => {
    setErrorMessage("");

    const response = await axiosGetRequest<IChantierInformation>(
      `${BACKEND_URL}/api/User/${commandId}/GetSuiviProjet`,

      {
        headers: {
          authorization: `Bearer ${userToken}`,
        },
      }
    );

    // If we have a reponse, we store it in redux.
    if (response && response.data) {
      const chantierInformation = response.data;
      chantierInformation.commandId = commandId; // We add the id to the chantier information
      dispatch(
        setChantierInformationAction({
          chantierInformation: chantierInformation,
        })
      );
    }
  };

  return {
    requestChantierInformation,
    isLoading,
    errorMessage: errorMessage ? errorMessage : axiosErrorMessage,
  };
};
