// Interfaces import
import { IChantierInformation } from "../../../interfaces/interfaces";

export const isConsuelTabDisplayed = (
  chantierInformation: IChantierInformation
): boolean => {
  return chantierInformation.statutConsuel &&
    chantierInformation.statutConsuel !== "Non nécessaire"
    ? true
    : false;
};
