import "./tucoenergieContactView.scss";

// Libraries import
import React from "react";

// Images import
import PictoCustomerService from "../../../assets/images/picto_service-client.png";

export const TucoenergieContactView: React.FC = () => {
  return (
    <div className="tucoenergie-contact-view">
      <div className="picto">
        <img src={PictoCustomerService} alt="picto-customer-service" />
      </div>

      <h3>Contactez TUCO</h3>
      <p>
        Votre conseiller n&apos;est pas disponible ? Contactez notre service
        clientèle.
      </p>
      <div className="contact-information">
        <p>Service clientèle TUCO</p>
        <p>01 83 62 84 81</p>
      </div>
      <p>Du lundi au vendredi de 9h30 à 20h. (Appel gratuit)</p>
    </div>
  );
};
