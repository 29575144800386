import React from "react";

//Style import
import "./userProjectCard.scss";

//Component import
import { MainButton } from "@web/shared/dist/components/Old/MainButton/MainButton";
import { useNavigate } from "react-router-dom";
import { IUserAllProjects } from "../../../interfaces/interfaces";

interface Props {
  data: IUserAllProjects;
}

export const UserProjectCard: React.FC<Props> = ({ data }) => {
  const navigate = useNavigate();
  const dateFormat = data.orderDate
    ? new Date(data.orderDate).getDate() +
      "/" +
      (new Date(data.orderDate).getMonth() + 1) +
      "/" +
      new Date(data.orderDate).getFullYear()
    : null;

  return (
    <div className="UserProjectCard">
      <div className="UserProjectCard__Title">
        {dateFormat ? <h3>Projet du {dateFormat}</h3> : <h3>Projet</h3>}

        <h4>
          {data.orderNumber}
          <br />
        </h4>
      </div>
      <div className="UserProjectCard__Type">
        {data.orderProducts.map((type, index: number) => {
          return (
            <div key={index}>
              <p>{type.name}</p>
            </div>
          );
        })}
      </div>
      <div className="UserProjectCard__ButtonContainer">
        <MainButton
          name="Voir le détail"
          color="grey"
          onClick={() => {
            navigate(
              `/ma_commande/${data.commandId || ""}?orderNumber=${
                data.orderNumber || ""
              }&date=${data.orderDate || ""}`
            );
            scrollTo({ top: 0 });
          }}
        />
        {data.status === "Signé" && data.commandId && (
          <MainButton
            name="Suivre mon projet"
            color="orange"
            disabled={data.commandId ? false : true}
            onClick={() => {
              data.commandId && navigate(`/mon_projet/${data.commandId}`);
              scrollTo({ top: 0 });
            }}
          />
        )}
      </div>
    </div>
  );
};
