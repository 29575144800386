import "./OpenCloseBlock.scss";

// Libraries import
import React, { ReactNode, useState, useRef } from "react";
import { CSSTransition } from "react-transition-group";

// Images import
import ArrowTab from "../../assets/images/tab_arrow.png";
import PictoSandglassOrange from "../../assets/images/Pictos/picto_sandglass_orange.svg";
import PictoSandglassGrey from "../../assets/images/Pictos/picto_sandglass_grey.svg";
import PictoExclamationPointOrange from "../../assets/images/Pictos/picto_exclamation_orange.svg";
import PictoExclamationPointWhite from "../../assets/images/Pictos/picto_exclamation_white.svg";
import PictoValidatedBlue from "../../assets/images/Pictos/picto_validated_blue.svg";
import PictoExclamationPointRed from "../../assets/images/Pictos/picto_exclamation_Red.svg";

//Interface import
import { INotification } from "../../interfaces/interfaces";

// Local interface declaration
interface Props {
  children?: ReactNode;
  title: string;
  isOpen?: boolean;
  onClick: () => void;
  status?: string | null;
  notification?: INotification;
}

export const OpenCloseBlock: React.FC<Props> = ({
  children,
  title,
  isOpen = false,
  onClick,
  status,
  notification,
}) => {
  const [tabHeight, setTabHeight] = useState<number>(0);
  const nodeRef = useRef<HTMLInputElement>(null);
  const nodeRefTwo = useRef<HTMLImageElement>(null);

  const notificationDictionary = {
    sandglassOrange: PictoSandglassOrange,
    sandglassGrey: PictoSandglassGrey,
    exclamationPointOrange: PictoExclamationPointOrange,
    exclamationPointWhite: PictoExclamationPointWhite,
    validatedBlue: PictoValidatedBlue,
    exclamationPointRed: PictoExclamationPointRed,
  };

  const calcHeight = () => {
    const height = nodeRef.current?.clientHeight || 0;
    setTabHeight(height + 30);
  };

  const onExit = () => {
    setTabHeight(0);
  };

  return (
    <div
      className={`OpenCloseBlock ${isOpen ? "tabIsOpen" : ""}`}
      onClick={onClick}
    >
      <div className="OpenCloseBlock__Header">
        <div className="OpenCloseBlock__Title">
          <h4>{title}</h4>
        </div>
      </div>
      <div className="OpenCloseBlock__Status">
        <p>{status}</p>
        {notification && (
          <img
            src={notificationDictionary[notification]}
            alt="Picto notification"
          />
        )}
      </div>
      <div
        className="OpenCloseBlock__TabContent mobileOnly"
        style={{ height: tabHeight }}
      >
        <CSSTransition
          in={isOpen}
          unmountOnExit
          timeout={300}
          classNames={"tab-content"}
          onEnter={calcHeight}
          onExit={onExit}
          nodeRef={nodeRef}
        >
          <div ref={nodeRef}>{children}</div>
        </CSSTransition>
      </div>
      <div
        className="OpenCloseBlock__TabContent desktopOnly"
        style={{ height: 0 }}
      >
        <div>{children}</div>
      </div>
      <div className="OpenCloseBlock__BottomArrow">
        <CSSTransition
          in={isOpen}
          timeout={500}
          classNames={"tab-arrow"}
          nodeRef={nodeRefTwo}
        >
          <img src={ArrowTab} alt="arrow-down" ref={nodeRefTwo} />
        </CSSTransition>
      </div>
    </div>
  );
};
