// Libraries import
import { useState } from "react";
import axios, { AxiosRequestConfig, AxiosResponse, AxiosError } from "axios";

// Service import
import { useSetUserAuthInfo } from "./useSetUserAuthInfo";

// Interfaces declaration
interface IFunctionReturn {
  axiosPutRequest: <T>(
    URL: string,
    body: unknown,
    config?: AxiosRequestConfig
  ) => Promise<AxiosResponse<T> | void>;
  isLoading: boolean;
  errorMessage: string;
}

// useAxiosPut : return tools to send PUT request and handle error messages
// Parameters
// ----------
// RAS
//
// Returns
// ----------
// Function axiosPutRequest
//     Function to call to send PUT request
// Boolean isLoading
//     Indicate if the asynchronous POST request is onGoing
// String errorMessage
//     POST request error message (if request fails)

export const useAxiosPut = (): IFunctionReturn => {
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { setUserAuthInfo } = useSetUserAuthInfo();
  // axiosPostRequest : send POST request and handle error cases
  // Parameters
  // ----------
  // URL: String
  //      the URL of the API
  // body: Object
  //      contain information to send to the API
  // config (optional): Object
  //      contain configuration information to send to the API
  // Returns
  // ----------
  //   Data of the response or void

  const axiosPutRequest = async <T>(
    URL: string,
    body: unknown,
    config?: AxiosRequestConfig | undefined
  ): Promise<AxiosResponse<T> | void> => {
    setIsLoading(true);
    setErrorMessage("");
    try {
      const response = await axios.put<T>(URL, body, config);

      if (response.status.toString()[0] === "2") {
        setIsLoading(false);
        return response;
      }
      setErrorMessage("Une erreur est survenue, merci de réessayer.");
      setIsLoading(false);
      return;
    } catch (error: unknown) {
      const axiosError = error as AxiosError;
      // client received an error response (5xx, 4xx)
      if (axiosError.response) {
        if (axiosError.response.status === 401) {
          setErrorMessage("Une erreur est survenue, merci de réessayer.");
          // If response is unauthorized we reset user authorization info -> redirect the user to the login page
          setUserAuthInfo(null);
          setIsLoading(false);
          return;
        }
        if (axiosError.response.status === 422) {
          // 422 is a specific error status where the error message is defined by the CRM
          // Format of the data is : { "status": int,"errors": [[]]}
          const textError: string | undefined =
            axiosError.response?.data?.errors;

          setErrorMessage(
            textError || "Une erreur est survenue, merci de réessayer."
          );
          setIsLoading(false);
          return;
        }
        setErrorMessage("Une erreur est survenue, merci de réessayer.");
      } else if (axiosError.request) {
        // client never received a response, or request never left
        if (typeof axiosError.request.data === "string") {
          setErrorMessage(axiosError.request.data);
          setIsLoading(false);
          return;
        }
        setErrorMessage("Erreur serveur, merci de réessayer.");
        setIsLoading(false);
        return;
      } else {
        setErrorMessage("Une erreur est survenue, merci de réessayer.");
      }
      setIsLoading(false);
    }
  };

  return { axiosPutRequest, isLoading, errorMessage };
};
