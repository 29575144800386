// Libraries import
import { useState } from "react";

// Interface delcaration
interface IFunctionReturn {
  isUserRegistrationInputValid : (invitationCode: string | null, userPassword: string, confirmPassword: string) => boolean;
  errorMessage : string;
}

// useIsUserRegistrationInputValid : return tools to check registration inputs format
// Parameters 
// ----------
// RAS
// 
// Returns
// ----------
// Function isUserRegistrationInputValid
//     Function to check registration input format
// errorMessage : string
//      Error message if format invalid

export const useIsUserRegistrationInputValid = () : IFunctionReturn => {
  const [errorMessage, setErrorMessage] = useState<string>("");

  // Password : 1 lowercase, 1 uppercase, 1 numeric, 1 special, 8 characters or longer
  const passwordRegex = new RegExp(
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*?:])(?=.{8,})/
  );

// isUserRegistrationInputValid : function to check registration inputs format
// Parameters 
// ----------
// invitationCode : string
//       jwt authentication token
// userPassword : string
//       Password typed by the user
// confirmPassword : string
//       Confirmation password typed by the user
// 
// Returns
// ----------
// Boolean : true if valid format, false otherwise
  const isUserRegistrationInputValid = (
    invitationCode: string | null,
    userPassword: string,
    confirmPassword: string
  ): boolean => {
    setErrorMessage("");    
    if ( userPassword && confirmPassword) {
      if (invitationCode) {
        if (passwordRegex.test(userPassword)) {
          if (userPassword === confirmPassword) {
            return true;
          } else {
            setErrorMessage("Vos mots de passe doivent être identiques.");
          }
        } else {
          setErrorMessage(
            "Le mot de passe doit contenir minimum 8 caractères dont 1 minuscule, 1 majuscule, 1 chiffre et 1 caractère spécial."
          );
        }
      } else {
        setErrorMessage("L'invitation n'est pas valide.");
      }
    } else {
      setErrorMessage("Veuillez renseigner tous les champs.");
    }
    return false;
  };

  return { isUserRegistrationInputValid, errorMessage };
};