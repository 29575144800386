import "./buttonGoBack.scss";

// Libraries import
import React, { FC } from "react";

// Images import
import GoBackArrowLeft from "../../assets/images/go-back-arrow-left.svg";

// Local interfaces declaration

interface Props {
  name?: string;
  onClick?: () => void;
}

export const ButtonGoBack: FC<Props> = ({
  name = "Button Go Back",
  onClick,
}) => {
  return (
    <div className="buttonGoBack">
      <button className="buttonGoBack__Button" onClick={onClick}>
        <img src={GoBackArrowLeft} alt="Go back arrow left" />
        <p className="buttonGoBack__ButtonName">{name}</p>
      </button>
    </div>
  );
};
