import React, { useState } from "react";
import "./codeParrainBlock.scss";
import { CopyToClipBoardLottie } from "./CopyToClipBoardLottie";
import codeParrainageImage from "../../../assets/images/parrainage-image.jpg";

interface IProps {
  codeParrain: string | null;
}

export const CodeParrainBlock = ({ codeParrain }: IProps): JSX.Element => {
  const [triggerAnimation, setTriggerAnimation] = useState<boolean>(false);
  const [isIconVisible, setIsIconVisible] = useState<boolean>(false);

  const copyOnClick = (
    e: React.MouseEvent<HTMLParagraphElement, MouseEvent>
  ) => {
    const textToCopy = e.currentTarget.textContent;
    textToCopy && navigator.clipboard.writeText(textToCopy);

    // handle Copy Icon
    setTriggerAnimation(true);
    setTimeout(() => setTriggerAnimation(false), 2000);
  };

  return (
    <div className="codeParrainBlock">
      <section className="codeParrainBlock__TitleContainer">
        <h4>Parrainage</h4>
      </section>
      <section className="codeParrainBlock__ImageContainer">
        <img
          src={codeParrainageImage}
          alt="Un client content de bénéficier d'un code parrainage pour ses amis et sa famille"
          width={300}
          height={163}
        />
      </section>
      <section className="codeParrainBlock__CodeContainer">
        <p>{"Votre code".toUpperCase()}</p>
        <div
          className="codeParrain"
          id="codeParrain"
          onClick={(e) => copyOnClick(e)}
          onMouseEnter={() => setIsIconVisible(true)}
          onMouseLeave={() => setIsIconVisible(false)}
        >
          <p>{codeParrain}</p>
          <div
            className="lottieContainer"
            style={{
              display: `${
                isIconVisible || triggerAnimation ? "block" : "none"
              }`,
            }}
          >
            <CopyToClipBoardLottie isAnimationPlayed={triggerAnimation} />
          </div>
        </div>
      </section>
      <section className="codeParrainBlock__TextDescription">
        <p>
          Vous connaissez quelqu’un ayant un projet de rénovation énergétique ?
        </p>
        <p>
          <strong>
            Partagez votre code de parrainage à un proche et gagnez 200 € chacun
            après son installation !
          </strong>
        </p>
      </section>
    </div>
  );
};
