// Libraries import
import { useState } from "react";
import { useAxiosPost } from "../../services/useAxiosPost";
import { useNavigate } from "react-router-dom";

// Interface import
import { IRegisterUserBody } from "../../interfaces/interfaces";

// Interface declaration
interface IFunctionReturn {
  requestRegistration: (
    invitationCode: string,
    password: string,
    email: string
  ) => Promise<void>;
  isLoading: boolean;
  errorMessage: string;
}

interface IRequestRegistrationAnswer {
  status: number;
  errors: string;
}

// useResgisterUser : return tools to send registration request and handle error messages
// Parameters
// ----------
// RAS
//
// Returns
// ----------
// Function requestRegistration
//     Function to call to send registration request
// Boolean isLoading
//     Indicate if the asynchronous request is onGoing
// String errorMessage
//     Error message (missing parameters or request error message)

export const useRegisterUser = (): IFunctionReturn => {
  const BACKEND_URL = process.env.REACT_APP_API_AUTHENTICATION_URL;
  const navigate = useNavigate();
  const {
    axiosPostRequest,
    isLoading: axiosIsLoading,
    errorMessage: axiosErrorMessage,
  } = useAxiosPost();
  const [errorMessage, setErrorMessage] = useState<string>("");

  // requestRegistration : send registration Post request
  // Parameters
  // ----------
  // invitationCode: String
  //      the code sent through an URL inviting th user to register
  // password: String
  //      the password of the user
  // email: String
  //      the email of the user
  // Returns : void
  // ----------
  // Void : if successfull redirect to login page. Update error message otherwise.

  const requestRegistration = async (
    invitationCode: string,
    password: string,
    email: string
  ): Promise<void> => {
    setErrorMessage("");
    if (invitationCode && password && email) {
      const registerUserBody: IRegisterUserBody = {
        invitationCode,
        password,
        email,
      };
      const response = await axiosPostRequest<IRequestRegistrationAnswer>(
        `${BACKEND_URL}/api/User/register`,
        registerUserBody
      );
      if (response && response.status === 200) {
        navigate("/login", {
          state: {
            message: "Inscription réussie !",
          },
        });
      }
    } else {
      setErrorMessage("Champs manquants.");
    }
  };

  return {
    requestRegistration,
    isLoading: axiosIsLoading,
    errorMessage: errorMessage ? errorMessage : axiosErrorMessage,
  };
};
