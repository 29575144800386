import React from "react";

//Style import
import "./userAccountHero.scss";

//Img import
import mesInfosLogo from "../../../assets/images/mesInfosLogo.svg";

interface Props {
  userFirstName: string | null;
}

export const UserAccountHero: React.FC<Props> = ({ userFirstName }) => {
  return (
    <div className="UserAccountHero">
      <div className="UserAccountHero__DynamicMargin" />
      <div className="UserAccountHero__Content">
        <div className="UserAccountHero__Header">
          <div className="UserAccountHero__HeaderImage">
            <img src={mesInfosLogo} alt="mesInfosLogo" />
          </div>
          <div className="UserAccountHero__HeaderText">
            <h1>Bonjour {userFirstName && userFirstName} !</h1>
            <h3>Bienvenue dans votre espace personnel.</h3>
          </div>
        </div>
      </div>
    </div>
  );
};
