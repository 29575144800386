import "./AccordMairieTabContent.scss";

// Libraries import
import React from "react";

// Services import
import { formatDataTabAccordMairie } from "../../../services/ProjectFollowUp/DemarchesAdministratives/formatDataTabAccordMairie";

// Images import
import ImageAccordMairie from "../../../assets/images/ProjectFollowUp/image_accord_mairie.svg";
import { notificationDictionary } from "../../../data/notificationDictionary";

//Interface import
import { IChantierInformation } from "../../../interfaces/interfaces";

// Local interfaces declaration
interface Props {
  chantierInformation: IChantierInformation;
}

export const AccordMairieTabContent: React.FC<Props> = ({
  chantierInformation,
}) => {
  const { message, notification } =
    formatDataTabAccordMairie(chantierInformation);

  return (
    <div className="AccordMairieTabContent">
      <div className="AccordMairieTabContent__Accord">
        <div className="AccordMairieTabContent__Picto">
          {notification != "" && (
            <img src={notificationDictionary[notification]} alt="Picto alert" />
          )}
        </div>
        <div className="AccordMairieTabContent__Text">
          <div className="AccordMairieTabContent__Title">
            <p>Installation</p>
          </div>
          <div className="AccordMairieTabContent__Status">
            <p>{message}</p>
          </div>
        </div>
      </div>
      <div className="AccordMairieTabContent__Image">
        <img
          src={ImageAccordMairie}
          alt="Business deal"
          width={122}
          height={109}
        />
      </div>
    </div>
  );
};
