import React from "react";

// Interfaces import
import {
  IChantierInformation,
  ITabData,
  INotification,
} from "../../../interfaces/interfaces";

// Local interfaces declaration
interface IFunctionReturn {
  aidesMPRData: ITabData;
  aidesCEEData: ITabData;
  notification: INotification;
  isTabActive: boolean;
}

// formatDataTabAides : return various values to set Aides tab data
// Parameters
// ----------
// chantierInformation
//
// Returns
// ----------
// object : aidesMPRData => data about aides tab
//     Boolean : isAidesTabActive
//                    to active tab when Demarches Administratives is activated
//     String : notification
//                    to set the notification icon
//     JSX element : message
//                    to set message intAides tab
// boolean : isAidesTabDisplayed
//                    displaytAides tab when Demarches Administratives is activated
//
//

export const formatDataTabAides = (
  chantierInformation: IChantierInformation
): IFunctionReturn => {
  const statutAidesMPR = chantierInformation.statutMPR;
  const statutAidesCEE = chantierInformation.statutCEE;

  //Function setAidesMPRDataFunction
  const aidesMPRData = ((): ITabData => {
    switch (statutAidesMPR) {
      case "Non nécessaire":
        return {
          isTabActive: false,
          isBlockHided: true,
          notification: "",
          message: <>Non applicable.</>,
        };
      case "Annulé":
        return {
          isTabActive: false,
          isBlockHided: true,
          notification: "",
          message: <>Non applicable</>,
        };
      case "En cours":
        return {
          isTabActive: true,
          notification: "sandglassOrange",
          message: (
            <>
              Votre dossier de demande de prime MaPrimeRenov’ est en cours de
              traitement.{" "}
            </>
          ),
        };
      case "A réaliser":
        return {
          isTabActive: true,
          notification: "sandglassOrange",
          message: (
            <>
              Vous n’avez pas à vous soucier des démarches pour récupérer la
              prime MaPrimeRenov’ en remboursement. Nous prenons en charge
              toutes les démarches administratives afin que vous puissiez vous
              focaliser entièrement sur le déroulement de votre projet.
            </>
          ),
        };
      case "Envoyé":
        return {
          isTabActive: true,
          notification: "sandglassOrange",
          message: (
            <>
              Votre dossier de demande de prime MaPrimeRenov’ est en cours de
              traitement.{" "}
            </>
          ),
        };
      case "Attente ANAH":
        return {
          isTabActive: true,
          notification: "sandglassOrange",
          message: (
            <>
              Votre dossier de demande de prime MaPrimeRenov’ est en cours de
              traitement.{" "}
            </>
          ),
        };
      case "Complété":
        return {
          isTabActive: false,
          notification: "validatedBlue",
          message: (
            <>Votre dossier de demande de prime MaPrimeRenov’ a été traité.</>
          ),
        };
      case "Encaissé":
        return {
          isTabActive: false,
          notification: "validatedBlue",
          message: (
            <>Votre dossier de demande de prime MaPrimeRenov’ a été traité.</>
          ),
        };
      default:
        return {
          isTabActive: false,
          notification: "",
          message: (
            <>
              Vous n’avez pas à vous soucier des démarches pour récupérer la
              prime MaPrimeRenov’ en remboursement. Nous prenons en charge
              toutes les démarches administratives afin que vous puissiez vous
              focaliser entièrement sur le déroulement de votre projet.
            </>
          ),
        };
    }
  })();

  //Function setAidesCEEDataFunction
  const aidesCEEData = ((): ITabData => {
    switch (statutAidesCEE) {
      case "Non nécessaire":
        return {
          isTabActive: false,
          isBlockHided: true,
          notification: "",
          message: <>Non applicable.</>,
        };
      case "Annulé":
        return {
          isTabActive: false,
          isBlockHided: true,
          notification: "",
          message: <>Non applicable.</>,
        };
      case "En cours":
        return {
          isTabActive: true,
          notification: "sandglassOrange",
          message: (
            <>
              Votre dossier de demande de prime CEE est en cours de traitement.
            </>
          ),
        };
      case "A réaliser":
        return {
          isTabActive: true,
          notification: "sandglassOrange",
          message: (
            <>
              Vous n’avez pas à vous soucier des démarches pour récupérer la
              prime CEE en remboursement. Nous prenons en charge toutes les
              démarches administratives afin que vous puissiez vous focaliser
              entièrement sur le déroulement de votre projet.
            </>
          ),
        };
      case "Attente ANAH":
        return {
          isTabActive: true,
          notification: "sandglassOrange",
          message: (
            <>
              Votre dossier de demande de prime CEE est en cours de traitement.
            </>
          ),
        };
      case "Envoyé":
        return {
          isTabActive: true,
          notification: "sandglassOrange",
          message: (
            <>
              Votre dossier de demande de prime CEE est en cours de traitement.
            </>
          ),
        };
      case "Complété":
        return {
          isTabActive: false,
          notification: "validatedBlue",
          message: <>Votre dossier de demande de prime CEE a été traité.</>,
        };
      case "Encaissé":
        return {
          isTabActive: false,
          notification: "validatedBlue",
          message: <>Votre dossier de demande de prime CEE a été traité.</>,
        };
      default:
        return {
          isTabActive: false,
          notification: "",
          message: (
            <>
              Vous n’avez pas à vous soucier des démarches pour récupérer la
              prime CEE en remboursement. Nous prenons en charge toutes les
              démarches administratives afin que vous puissiez vous focaliser
              entièrement sur le déroulement de votre projet.
            </>
          ),
        };
    }
  })();

  const notification = aidesMPRData.notification || aidesCEEData.notification;
  const isTabActive = aidesMPRData.isTabActive || aidesCEEData.isTabActive;

  return {
    aidesMPRData,
    aidesCEEData,
    notification,
    isTabActive,
  };
};
