import React from "react";

//style import
import "./userProjectsInProgress.scss";

//Component import
import { UserProjectCard } from "../UserProjectCard/UserProjectCard";
import { IUserAllProjects } from "../../../interfaces/interfaces";

interface Props {
  data: IUserAllProjects[];
}

export const UserProjectsInProgress: React.FC<Props> = ({ data }) => {
  return (
    <>
      {data.length === 1 ? (
        <div className="UserProjectsInProgressContainerSingle">
          {data.map((item, index) => {
            return <UserProjectCard data={item} key={index} />;
          })}
        </div>
      ) : (
        <div className="UserProjectsInProgressContainerMultiple">
          {data.map((item, index) => {
            return <UserProjectCard data={item} key={index} />;
          })}
        </div>
      )}
    </>
  );
};
