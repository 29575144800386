import styles from "./greentechCertification.module.scss";

import greentechImage from "../../assets/images/greentech-certification.svg";

/* eslint-disable */
export const GreentechCertification = () => {
  return (
    <div className={styles.greentechCertification}>
      <img loading="lazy" src={greentechImage} alt="greentech-certification" />
    </div>
  );
};
/* eslint-enable */
