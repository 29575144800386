import "./ConsuelTabContent.scss";

// Libraries import
import React from "react";

// Services import
import { formatDataTabConsuel } from "../../../services/ProjectFollowUp/DemarchesAdministratives/formatDataTabConsuel";
import { useModal } from "../../../services/useModal";

// Components
import { ModalContainer } from "../../Modal/Modal";
import { ModalRescheduleRDV } from "../ModalRescheduleRDV/ModalRescheduleRDV";

// Images import
import ImageConsuel from "../../../assets/images/ProjectFollowUp/image_consuel.svg";
import { notificationDictionary } from "../../../data/notificationDictionary";

//interface import
import { IChantierInformation } from "../../../interfaces/interfaces";

//local interface

interface Props {
  chantierInformation: IChantierInformation;
}

export const ConsuelTabContent: React.FC<Props> = ({ chantierInformation }) => {
  const { toggleModal, isModalVisible } = useModal();
  const { message, notification, isCancelMeetingDisplayed, isBlockHided } =
    formatDataTabConsuel(chantierInformation);

  return (
    <div className="ConsuelTabContent">
      {!isBlockHided && (
        <div className="ConsuelTabContent__Consuel">
          <div className="ConsuelTabContent__Picto">
            {notification != "" && (
              <img
                src={notificationDictionary[notification]}
                alt="Picto alert"
              />
            )}
          </div>
          <div className="ConsuelTabContent__Text">
            <div className="ConsuelTabContent__Title">
              <p>Rendez-vous consuel :</p>
            </div>
            <div className="ConsuelTabContent__Date">
              <p>{message}</p>
              {isCancelMeetingDisplayed && (
                <div className="PriseEnMainTabContent__OpenModal">
                  <p
                    onClick={(e) => {
                      e.stopPropagation();
                      toggleModal();
                    }}
                  >
                    Je ne pourrai pas être présent au rdv
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
      <div className="ConsuelTabContent__Image">
        <img src={ImageConsuel} alt="Working man" width={122} height={109} />
      </div>
      {isModalVisible && (
        <ModalContainer
          onClick={(e) => {
            e.stopPropagation();
            toggleModal();
          }}
        >
          <ModalRescheduleRDV />
        </ModalContainer>
      )}
    </div>
  );
};
