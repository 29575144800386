import "./uploadDocumentsHero.scss";

// Libraries imports
import React from "react";

// Images import
import HouseSearching from "../../../assets/images/uploadDocuments/house-searching-rafiki.svg";

export const UploadDocumentsHero: React.FC = () => {
  return (
    <div className="uploadDocumentsHero">
      <div className="uploadDocumentsHero__DynamicMargin" />
      <div className="uploadDocumentsHero__Content">
        <div className="uploadDocumentsHero__Header">
          <div className="uploadDocumentsHero__HeaderImage">
            <img src={HouseSearching} alt="Woman searching an house" />
          </div>
          <div className="uploadDocumentsHero__HeaderText">
            <h1>Mes documents</h1>
            <h3>
              Retrouvez et gérez vos documents personnels depuis votre espace
              client !
            </h3>
          </div>
        </div>
      </div>
    </div>
  );
};
