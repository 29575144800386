import styles from "./headerResponsive.module.scss";

// Libraries import
import React, { useState, FC } from "react";

// Components import
import { Layout } from "../Layout/Layout";
import { Logo } from "../Logo/Logo";
import { HeaderNavigation } from "./HeaderNavigation/HeaderNavigation";

// img import
import burgerMenu from "../../assets/images/HeaderElements/burger-menu.svg";
import burgerMenuFerme from "../../assets/images/HeaderElements/burger-menu-ferme.svg";
import userLogo from "../../assets/images/user-logo.svg";

//Other import
import { useAppSelector } from "../../redux/store/hook";
import { Link } from "react-router-dom";
import { useDisconnectApp } from "../../services/useDisconnectApp";

export const HeaderResponsive: FC = () => {
  // Hooks calls
  const userProfileInfo = useAppSelector((states) => states.userProfileInfo);

  const [isResponsiveMenuActive, setIsResponsiveMenuActive] =
    useState<boolean>(false);

  const [isModalLogoutActive, setIsModalLogoutActive] =
    useState<boolean>(false);

  const toggleResponsiveMenu = () => {
    setIsResponsiveMenuActive(!isResponsiveMenuActive);
  };

  const { disconnectApp } = useDisconnectApp();

  const onClickDisconnectButton = () => {
    toggleModalLogoutActive();
    disconnectApp();
  };

  const toggleModalLogoutActive = () => {
    setIsModalLogoutActive(!isModalLogoutActive);
  };

  return (
    <header className={styles.header}>
      <Layout>
        <div className={styles.header__container}>
          <div className={styles.header__logoContainer}>
            <a href={process.env.REACT_APP_API_WWW_URL}>
              <div className={styles.header__logo}>
                <Logo />
              </div>
            </a>
          </div>

          <HeaderNavigation
            isResponsiveMenuActive={isResponsiveMenuActive}
            userProfileInfo={userProfileInfo}
            toggleResponsiveMenu={toggleResponsiveMenu}
          />

          <div
            className={styles.header__iconBurger}
            onClick={toggleResponsiveMenu}
          >
            <img
              src={isResponsiveMenuActive ? burgerMenuFerme : burgerMenu}
              alt="responsive-button-burgerMenu"
              width={22}
              height={18}
            />
          </div>
          {userProfileInfo.firstname && (
            <div className={styles.header__UserAccountContainer}>
              <div
                className={styles.header__UserAccount}
                onClick={toggleModalLogoutActive}
              >
                {userProfileInfo.firstname && (
                  <img
                    src={userLogo}
                    alt={"user logo"}
                    className={styles.header__UserAvatar}
                  />
                )}

                {userProfileInfo.firstname &&
                  (userProfileInfo.firstname.length > 10 ? (
                    <Link to="/mes_informations">
                      <p>{userProfileInfo.firstname.slice(0, 9) + "..."}</p>
                    </Link>
                  ) : (
                    <Link to="/mes_informations">
                      {" "}
                      <p>{userProfileInfo.firstname}</p>
                    </Link>
                  ))}

                {userProfileInfo.id && (
                  <div className={styles.header__UserAccountLogoutModalActive}>
                    <Link to="/mes_informations">
                      <p>Mes informations</p>
                    </Link>
                    <button onClick={onClickDisconnectButton}>
                      <p>Déconnexion</p>
                    </button>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </Layout>
    </header>
  );
};
