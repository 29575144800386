import { Reducer, AnyAction } from "redux";

// Other imports
import * as actions from "./appActions";
import * as types from "./constants";
import { initialState } from "./initialState";

// Interfaces import
import { IAppStates } from "../interfaces/interfaces";

// Reducer functions : change state in Redux store
const setUserAuthInfo = (state: IAppStates, action: AnyAction) => {
  if (actions.setUserAuthInfoAction.match(action)) {
    return {
      ...state,
      userAuthInfo: action.payload.userAuthInfo,
    };
  }
  return state;
};

const setUserProfileInfo = (state: IAppStates, action: AnyAction) => {
  if (actions.setUserProfileInfoAction.match(action)) {
    return {
      ...state,
      userProfileInfo: action.payload.userProfileInfo,
    };
  }
  return state;
};

const setProjectManagerInfo = (state: IAppStates, action: AnyAction) => {
  if (actions.setProjectManagerInfoAction.match(action)) {
    return {
      ...state,
      projectManagerInfo: action.payload.projectManagerInfo,
    };
  }
  return state;
};

const setUploadDocumentsInfo = (state: IAppStates, action: AnyAction) => {
  if (actions.setUploadDocumentsInfoAction.match(action)) {
    return {
      ...state,
      uploadDocumentsInfo: action.payload.uploadDocumentsInfo,
    };
  }
  return state;
};

const setDownloadDocumentsInfo = (state: IAppStates, action: AnyAction) => {
  if (actions.setDownloadDocumentsInfoAction.match(action)) {
    return {
      ...state,
      downloadDocumentsInfo: action.payload.downloadDocumentsInfo,
    };
  }
  return state;
};

const setCommandInformation = (state: IAppStates, action: AnyAction) => {
  if (actions.setCommandInformationAction.match(action)) {
    return {
      ...state,
      commandInformation: action.payload.commandInformation,
    };
  }
  return state;
};

const setChantierInformation = (state: IAppStates, action: AnyAction) => {
  if (actions.setChantierInformationAction.match(action)) {
    return {
      ...state,
      chantierInformation: action.payload.chantierInformation,
    };
  }
  return state;
};

const setUserAllProjectsAction = (state: IAppStates, action: AnyAction) => {
  if (actions.setUserAllProjectsAction.match(action)) {
    return {
      ...state,
      userAllProjects: action.payload.userAllProjects,
    };
  }
  return state;
};

const setNumberDocumentstoUpload = (state: IAppStates, action: AnyAction) => {
  if (actions.setNumberDocumentstoUpload.match(action)) {
    return {
      ...state,
      nbdocumentstoUpload: action.payload.nbdocumentstoUpload,
    };
  }
  return state;
};

const setUserOrders = (state: IAppStates, action: AnyAction) => {
  if (actions.setUserOrdersAction.match(action)) {
    return {
      ...state,
      userOrders: action.payload.userOrders,
    };
  }
  return state;
};

const setUserDocuments = (state: IAppStates, action: AnyAction) => {
  if (actions.setUserDocumentsAction.match(action)) {
    return {
      ...state,
      userDocuments: action.payload.userDocuments,
    };
  }
  return state;
};

const setSelectedOrder = (state: IAppStates, action: AnyAction) => {
  if (actions.setSelectedOrderAction.match(action)) {
    return {
      ...state,
      orderSelected: action.payload.orderSelected,
    };
  }
  return state;
};

const setInitalState = () => {
  return initialState;
};

// Redux reducer
export const appReducer: Reducer<IAppStates, AnyAction> = (
  state = initialState,
  action
): IAppStates => {
  switch (action.type) {
    case types.SET_USER_AUTH_INFO:
      return setUserAuthInfo(state, action);
    case types.SET_USER_PROFILE_INFO:
      return setUserProfileInfo(state, action);
    case types.SET_PROJECT_MANAGER_INFO:
      return setProjectManagerInfo(state, action);
    case types.SET_UPLOAD_DOCUMENTS_INFO:
      return setUploadDocumentsInfo(state, action);
    case types.SET_DOWNLOAD_DOCUMENTS_INFO:
      return setDownloadDocumentsInfo(state, action);
    case types.SET_COMMAND_INFORMATION:
      return setCommandInformation(state, action);
    case types.SET_CHANTIER_INFORMATION:
      return setChantierInformation(state, action);
    case types.SET_USER_ORDERS:
      return setUserOrders(state, action);
    case types.SET_USER_DOCUMENTS:
      return setUserDocuments(state, action);
    case types.SET_SELECTED_ORDER:
      return setSelectedOrder(state, action);
    case types.SET_INITAL_STATE:
      return setInitalState();
    case types.SET_USER_ALL_PROJECTS:
      return setUserAllProjectsAction(state, action);
    case types.SET_USER_NUMBER_DOCUMENT_TO_UPLOAD:
      return setNumberDocumentstoUpload(state, action);
    default:
      return state;
  }
};
