// Libraries import
import { useState } from "react";
import { useAxiosGet } from "../../services/useAxiosGet";

// Service import
import { useSetUserProfileInfo } from "../../services/useSetUserProfileInfo";

// Interfaces import
import { IUserProfileInfo } from "../../interfaces/interfaces";

// Other import
import { useAppSelector } from "../../redux/store/hook";

// Interface delcaration
interface IFunctionReturn {
  requestUserProfileInfo: (userToken: string) => Promise<void>;
  isLoading: boolean;
  errorMessage: string;
}

// useGetUserProfileInfo : return tools to send get user profile information and handle error
// Parameters
// ----------
// RAS
//
// Returns
// ----------
// Function requestUserProfileInfo
//     Function to call to send user profile info request
// Boolean isLoading
//     Indicate if the asynchronous request is onGoing
// String errorMessage
//     Error message (no parameters or request error message)

export const useRequestUserProfileInfo = (): IFunctionReturn => {
  const BACKEND_URL = process.env.REACT_APP_API_AUTHENTICATION_URL;
  const {
    axiosGetRequest,
    isLoading,
    errorMessage: axiosErrorMessage,
  } = useAxiosGet();
  const [errorMessage, setErrorMessage] = useState<string>("");
  const { setUserProfileInfo } = useSetUserProfileInfo();
  const userId = useAppSelector((states) => states.userAuthInfo?.userId);

  // requestUserProfileInfo : send Get user profile information request. If successfull-> save user profile information info in session storage and in redux store
  // Parameters
  // ----------
  // userToken: String
  //      the JWT authentication token of the user
  // Returns
  // ----------
  // Void

  const requestUserProfileInfo = async (userToken: string): Promise<void> => {
    setErrorMessage("");
    const response = await axiosGetRequest<IUserProfileInfo>(
      `${BACKEND_URL}/api/User/${userId}`,
      {
        headers: {
          authorization: `Bearer ${userToken}`,
        },
      }
    );
    if (response && response.data?.id) {
      setUserProfileInfo(response.data);
    } else {
      setErrorMessage("Erreur lors du chargement des données utilisateurs");
    }
  };

  return {
    requestUserProfileInfo,
    isLoading,
    errorMessage: errorMessage ? errorMessage : axiosErrorMessage,
  };
};
