// Libraries import
import React, { useEffect, useRef } from "react";
import lottie from "lottie-web/build/player/lottie_light";
import copyClipboard from "../../../assets/Lotties/clipboard-copy-lottie.json";

// Interface declaration
interface IProps {
  diameter?: number;
  isAnimationPlayed: boolean;
}

export const CopyToClipBoardLottie: React.FC<IProps> = ({
  diameter = 40,
  isAnimationPlayed,
}) => {
  const element = useRef(null);
  useEffect(() => {
    element.current &&
      lottie.loadAnimation({
        container: element.current,
        animationData: copyClipboard,
        renderer: "svg", // "canvas", "html"
        loop: false, // boolean
        autoplay: false, // boolean
      });
  }, [element]);

  useEffect(() => {
    isAnimationPlayed && lottie.play();
  }, [isAnimationPlayed]);

  return (
    <div
      className="copyToClipBoard-component"
      style={{ width: diameter, height: diameter }}
      ref={element}
    />
  );
};
