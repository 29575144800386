import styles from "./newsletterInput.module.scss";
import React, { FC } from "react";

// Components import
import { ButtonAction } from "../ButtonAction/ButtonAction";

export const NewsletterInput: FC = () => {
  return (
    <form className={styles.newsletterInput}>
      <label htmlFor="email" />
      <input
        id="email"
        type="text"
        autoComplete="email"
        required
        placeholder="Votre e-mail"
      />
      <div className={styles.buttonAction}>
        <ButtonAction isWhite={true} />
      </div>
    </form>
  );
};
