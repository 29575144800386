import "./forgotPassword.scss";

// Libraries import
import React, { ReactElement, useState, KeyboardEvent } from "react";

// Components import
import { TextInput } from "@web/shared/dist/components/Old/TextInput/TextInput";
import { ButtonConnect } from "../../../components/ButtonConnect/ButtonConnect";
import { LoadingComponent } from "@web/shared/dist/components/Old/LoadingComponent/LoadingComponent";
import { ErrorMessage } from "@web/shared/dist/components/Old/ErrorMessage/ErrorMessage";

// Services import
import { useResetPasswordSendEmail } from "../../../services/login/useResetPasswordSendEmail";

// Local interface declaration
interface Props {
  isForgotPasswordClicked: boolean;
  setIsForgotPasswordClicked: (value: boolean) => void;
}

export const ForgotPassword = ({
  setIsForgotPasswordClicked,
}: Props): ReactElement => {
  // Hook calls
  const { resetPasswordSendEmail, isLoading, errorMessage } =
    useResetPasswordSendEmail();
  // Local state declaration
  const [email, setEmail] = useState<string>("");
  const [isEmailRequestSuccessfull, setIsEmailRequestSuccessfull] =
    useState<boolean>(false);
  const [isButtonValiderClicked, setIsButtonValiderClicked] =
      useState<boolean>(false);

  // Event handlers
  const onEmailInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };

  const onClickBack = () => {
    setIsForgotPasswordClicked(false);
  };

  // On click on the "Réinitialiser" button, check user inputs and send reset password request. Debounce function : max 1 call every 350ms.
  const onClickConnectButton = async () => {
    setIsButtonValiderClicked(true)
    if (await resetPasswordSendEmail(email)) {
      setIsEmailRequestSuccessfull(true);
    }
  };

  // On press on the "Enter" key, check user inputs and send password request. Debounce function : max 1 call every 350ms.
  const handleValidateOnPress = async (
    event: KeyboardEvent<HTMLInputElement>
  ) => {
    if (event.code === "Enter") {
      if (await resetPasswordSendEmail(email)) {
        setIsEmailRequestSuccessfull(true);
      }
    }
  };

  return (
    <>
      <div className="forgot-password">
        <div className="forgot-password-inputs">
          {isLoading ? (
            <LoadingComponent diameter={60} />
          ) : isEmailRequestSuccessfull ? (
            <p className="reset-password-success">
              Email valide ! Veuillez consulter votre boite mail pour terminer
              la réinitialisation de votre mot de passe.
            </p>
          ) : (
            <>
              <p>
                Veuillez renseigner l&apos;adresse mail avec laquelle vous avez
                créé votre compte Tucoenergie :
              </p>
              <TextInput
                value={email}
                onChange={onEmailInputChange}
                placeholder="Email"
                onKeyPress={handleValidateOnPress}
              />
            </>
          )}
        </div>
        <div className="forget-password-message">
          {errorMessage && <ErrorMessage errorMessage={errorMessage} />}
        </div>
        <div className="forgot-password-buttons">
          <button
            className="go-to-login"
            onClick={onClickBack}
            data-testid="button-go-back"
          >
            Retour
          </button>
          <div hidden={!errorMessage && isButtonValiderClicked}>
            <ButtonConnect
                name="Valider"
                onClick={onClickConnectButton}
                disabled={isLoading || isEmailRequestSuccessfull}
            />
          </div>
        </div>
      </div>
    </>
  );
};
