// Format status received from CRM to display the right status to the user
export const formatStatusTabAccordMairie = (
  statusAccordMairie?: string | null
): string => {
  switch (statusAccordMairie) {
    case "En cours":
      return "En cours";
    case "A réaliser":
      return "À réaliser";
    case "Augmentation du délai d'instruction":
      return "En cours";
    case "Demande d'informations complémentaires":
      return "En cours";
    case "Accord écrit":
      return "Obtenu";
    case "Accord tacite":
      return "En cours";
    case "Accord avec préconisations":
      return "Obtenu";
    case "Refus":
      return "Refusé";
    case "Mairie dérogée":
      return "En cours";
    default:
      return "Information non disponible";
  }
};
