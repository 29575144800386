// Libraries import
import { useState } from "react";
import { useAxiosGet } from "../useAxiosGet";

// Services import
import { useAppDispatch } from "../../redux/store/hook";
import { setCommandInformationAction } from "../../redux/appActions";

// Interfaces import
import { ICommandInformation } from "../../interfaces/interfaces";

// Local interface declaration
interface IFunctionReturn {
  requestCommandInformation: (
    userToken: string | null,
    userId: string | null
  ) => Promise<void>;
  isLoading: boolean;
  errorMessage: string;
}

// useGetCommandeInformations : return tools to send get command information request to CRM and handle error
// Parameters
// ----------
// RAS
//
// Returns
// ----------
// Function requestCommandInformation
//     Function to call to get command information
// Boolean isLoading
//     Indicate if the asynchronous request is onGoing
// String errorMessage
//     Error message (no parameters or request error message)

export const useGetCommandeInformations = (): IFunctionReturn => {
  const BACKEND_URL = process.env.REACT_APP_API_AUTHENTICATION_URL;
  const {
    axiosGetRequest,
    isLoading,
    errorMessage: axiosErrorMessage,
  } = useAxiosGet();
  const dispatch = useAppDispatch();
  const [errorMessage, setErrorMessage] = useState<string>("");

  // requestCommandInformation : send Get command information request.
  // Parameters
  // ----------
  // userToken: String | null
  //      the JWT authentication token of the user
  // commandId: String | null
  //      the unique id of the command
  // Returns
  // ----------
  // Void

  const requestCommandInformation = async (
    userToken: string | null,
    commandId: string | null
  ): Promise<void> => {
    setErrorMessage("");
    const response = await axiosGetRequest<ICommandInformation>(
      `${BACKEND_URL}/api/User/${commandId}/getCommandeInformations`,
      {
        headers: {
          authorization: `Bearer ${userToken}`,
        },
      }
    );

    // If we have a reponse, we store it in redux.
    if (response && response.data) {
      response.data.commandId = commandId; // We add the id to the command information
      dispatch(
        setCommandInformationAction({ commandInformation: response.data })
      );
    }
  };

  return {
    requestCommandInformation,
    isLoading,
    errorMessage: errorMessage ? errorMessage : axiosErrorMessage,
  };
};
