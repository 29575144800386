import "./InstallationTabContent.scss";

// Libraries import
import React from "react";

// Services import
import { formatDataTabInstallation } from "../../../services/ProjectFollowUp/SuiviChantier/formatDataTabInstallation";
import { notificationDictionary } from "../../../data/notificationDictionary";

// Images import
import ImageInstallation from "../../../assets/images/ProjectFollowUp/image_installation.svg";
import { ModalContainer } from "../../Modal/Modal";
import { ModalRescheduleRDV } from "../ModalRescheduleRDV/ModalRescheduleRDV";
import { useModal } from "../../../services/useModal";

// Interfaces import
import { IChantierInformation } from "../../../interfaces/interfaces";

// Local interface declaration
interface Props {
  chantierInformation: IChantierInformation;
}

export const InstallationTabContent: React.FC<Props> = ({
  chantierInformation,
}) => {
  const { toggleModal, isModalVisible } = useModal(); // Modal setup : Custom hook that handles the display of the modal on the screen.
  const { message, isCancelMeetingDisplayed, notification } =
    formatDataTabInstallation(chantierInformation);

  return (
    <div className="InstallationTabContent">
      <div className="InstallationTabContent__Installation">
        <div className="InstallationTabContent__Picto">
          {notification != "" && (
            <img src={notificationDictionary[notification]} alt="Picto alert" />
          )}
        </div>
        <div className="RaccordementTabContent__Text">
          <div className="InstallationTabContent__Title">
            <p>Installation de votre projet</p>
          </div>
          <div className="InstallationTabContent__Date">
            {message}
            {isCancelMeetingDisplayed && (
              <div className="InstallationTabContent__OpenModal">
                <p
                  onClick={(e) => {
                    e.stopPropagation();
                    toggleModal();
                  }}
                >
                  Je ne pourrai pas être présent au rdv
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="InstallationTabContent__Image">
        <img
          src={ImageInstallation}
          alt="House construction work"
          width={122}
          height={109}
        />
      </div>
      {isModalVisible && (
        <ModalContainer
          onClick={(e) => {
            e.stopPropagation();
            toggleModal();
          }}
        >
          <ModalRescheduleRDV />
        </ModalContainer>
      )}
    </div>
  );
};
