import "./authenticationWelcome.scss";

// Libraries import
import React from "react";

export const AuthenticationWelcome: React.FC = () => {
  return (
    <div className="authentication-welcome">
      <div className="authentication-welcome__message">
        <h1>Mon espace</h1>
        <p>
          Suivez facilement vos projets de travaux de rénovation énergétique
        </p>
      </div>
    </div>
  );
};
