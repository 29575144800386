import "./userMessage.scss";

// Libraries import
import React from "react";

// Interfaces declaration
interface Props {
  userMessage: string;
  className?: string;
}

export const UserMessage: React.FC<Props> = ({
  userMessage = "",
  className = "",
}) => {
  return (
    <p className={`user-message bounce-in-fwd ${className}`}>{userMessage}</p>
  );
};
