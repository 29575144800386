// Interfaces import
import { IChantierInformation } from "../../../interfaces/interfaces";

export const isAidesTabDisplayed = (
  chantierInformation: IChantierInformation
): boolean => {
  const { statutMPR, statutCEE, aides } = chantierInformation;

  const isThereNonDeductedAides =
    aides?.find((aide) => aide.isDeducted === false) || false; // We display the tab only if there is at least one help non deductable

  return (
    isThereNonDeductedAides &&
    ((statutMPR !== "Non nécessaire" && statutMPR !== "Annulé") || // And if the status are different from "Non nécessaire" and "Annulé"
      (isThereNonDeductedAides &&
        statutCEE !== "Non nécessaire" &&
        statutCEE !== "Annulé"))
  );
};
