import styles from "./ISO9001.module.scss";

import iso9001 from "../../assets/images/afaq-9001-logo.png";

/* eslint-disable */
export const ISO9001 = () => {
  return (
    <div className={styles.ISO9001}>
      <img
        loading="lazy"
        src={iso9001}
        alt="ISO 9001 logo"
        width={92}
        height={85}
      />
    </div>
  );
};
/* eslint-enable */
