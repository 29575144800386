// library imports
import { FileWithPath, FileError } from "react-dropzone";

// service imports
import { formatBytes } from "./formatBytes";

// fileSizeValidator : check if droped file is less than 15MB
// Parameters
// ----------
// file: The dropped file (type is given by dropZone library)
//
// Returns
// ----------
// code: string
//     code to identify the error
// message: string
//     the message to display to the user

export const fileSizeValidator = (
  file: FileWithPath
): FileError | FileError[] | null => {
  const maxSize = 15728640;
  if (file.size > maxSize) {
    return {
      code: "file-too-large",
      message: `Le fichier est trop lourd. Taille maximum : ${formatBytes(
        maxSize
      )}`,
    };
  } else {
    return null;
  }
};
