import React from "react";
import { IChantierInformation, ITabData } from "../../../interfaces/interfaces";

// formatDataTabAccordMairie : return various values to set AccordMairie tab
// Parameters
// ----------
// chantierInformation
//
// Returns
// ----------
// object : accordMairieData => data about accord marie tab
//     Boolean : isTabActive
//                    to active tab when Demarches Administratives is activated
//     String : notification
//                    to set the notification icon
//     JSX element : message
//                    to set message in accord mairie tab
//
// boolean : isAccordMairieTabDisplayed
//                    to display accord mairie tab when Demarches Administratives is activated
//
//

export const formatDataTabAccordMairie = (
  chantierInformation: IChantierInformation
): ITabData => {
  const statutMairie = chantierInformation.statutMairie;

  const accordMairieData = ((): ITabData => {
    switch (statutMairie) {
      case "En cours": // En cours
        return {
          isTabActive: true,
          notification: "sandglassOrange",
          message: <>Votre dossier est en cours de traitement par la mairie.</>,
        };
      case "A réaliser": // À réaliser
        return {
          isTabActive: true,
          notification: "sandglassOrange",
          message: (
            <>
              L’accord de votre mairie est nécessaire pour votre installation,
              le dossier d’instruction va être déposé par TUCO.
            </>
          ),
        };
      case "Augmentation du délai d'instruction": // En cours
        return {
          isTabActive: true,
          notification: "sandglassOrange",
          message: <>Votre dossier est en cours de traitement par la mairie.</>,
        };
      case "Demande d'informations complémentaires": // En cours
        return {
          isTabActive: true,
          notification: "sandglassOrange",
          message: <>Votre dossier est en cours de traitement par la mairie.</>,
        };
      case "Accord écrit": // Obtenu
        return {
          isTabActive: false,
          notification: "validatedBlue",
          message: <>L’accord de la mairie a été obtenu.</>,
        };
      case "Accord tacite": // En cours
        return {
          isTabActive: true,
          notification: "sandglassOrange",
          message: <>Votre dossier est en cours de traitement par la mairie.</>,
        };

      case "Accord avec préconisations": // Obtenu
        return {
          isTabActive: false,
          notification: "validatedBlue",
          message: <>L’accord de la mairie a été obtenu.</>,
        };

      case "Refus": // Refusé
        return {
          isTabActive: true,
          notification: "exclamationPointRed",
          message: <>L’accord de la mairie n&apos;a pas été obtenu.</>,
        };
      case "Mairie dérogée": // En cours
        return {
          isTabActive: true,
          notification: "sandglassOrange",
          message: <>Votre dossier est en cours de traitement.</>,
        };
      default: // Aucune information disponible
        return {
          isTabActive: false,
          notification: "",
          message: <>Aucune information disponible.</>,
        };
    }
  })();

  return accordMairieData;
};
