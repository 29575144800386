// Libraries import
import { useState } from "react";
import { useAxiosGet } from "../../services/useAxiosGet";
import { useAppDispatch } from "../../redux/store/hook";

// Actions import
import { setProjectManagerInfoAction } from "../../redux/appActions";

// Interfaces import
import { IProjectManagerInfo } from "../../interfaces/interfaces";

// Interface delcaration
interface IFunctionReturn {
  requestProjectManagerInfo: (
    userToken: string | null,
    projectManagerId: string | null
  ) => Promise<IProjectManagerInfo | void>;
  isLoading: boolean;
  errorMessage: string;
}

// useRequestProjectManagerInfo : return tools to send request project manager information to CRM and handle error
// Parameters
// ----------
// RAS
//
// Returns
// ----------
// Function requestProjectManagerInfo
//     Function to call to send project manager info request
// Boolean isLoading
//     Indicate if the asynchronous request is onGoing
// String errorMessage
//     Error message (no parameters or request error message)

export const useRequestProjectManagerInfo = (): IFunctionReturn => {
  const BACKEND_URL = process.env.REACT_APP_API_AUTHENTICATION_URL;
  const dispatch = useAppDispatch();
  const {
    axiosGetRequest,
    isLoading,
    errorMessage: axiosErrorMessage,
  } = useAxiosGet();
  const [errorMessage, setErrorMessage] = useState<string>("");

  // requestProjectManagerInfo : send Get project manager information request. If successfull-> save project manager information info in redux store
  // Parameters
  // ----------
  // userToken: String | null
  //      the JWT authentication token of the user
  // projectManagerId: String | null
  //      the unique id of the project manager
  // Returns
  // ----------
  // Void

  const requestProjectManagerInfo = async (
    userToken: string | null,
    projectManagerId: string | null
  ): Promise<IProjectManagerInfo | void> => {
    setErrorMessage("");
    if (userToken) {
      if (projectManagerId) {
        const response = await axiosGetRequest<IProjectManagerInfo>(
          `${BACKEND_URL}/api/User/${projectManagerId}/GetProjectManagerInformation`,
          {
            headers: {
              authorization: `Bearer ${userToken}`,
            },
          }
        );
        if (response && response.data) {
          dispatch(
            setProjectManagerInfoAction({ projectManagerInfo: response.data })
          );
        }
      } else {
        setErrorMessage("Informations utilisateur manquantes");
      }
    } else {
      setErrorMessage("Utilisateur non authentifié");
    }
  };

  return {
    requestProjectManagerInfo,
    isLoading,
    errorMessage: errorMessage ? errorMessage : axiosErrorMessage,
  };
};
