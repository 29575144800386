import React from "react";

//Style import
import "./userProjectsBannerContent.scss";

//logo import
import mesProjetsLogo from "../../../assets/images/mesProjetsLogo.svg";

export const UserProjectsBannerContent: React.FC = () => {
  return (
    <div className="UserProjectsHero">
      <div className="UserProjectsHero__DynamicMargin" />
      <div className="UserProjectsHero__Content">
        <div className="UserProjectsHero__Header">
          <div className="UserProjectsHero__HeaderImage">
            <img src={mesProjetsLogo} alt="Woman searching an house" />
          </div>
          <div className="UserProjectsHero__HeaderText">
            <h1>Mes projets</h1>
            <h3>
              Retrouvez et suivez l’avancement de vos projets de rénovation
              énergétique.
            </h3>
          </div>
        </div>
      </div>
    </div>
  );
};
