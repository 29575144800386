// Libraries imports
import React, { useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import ReactGA from "react-ga4";

// Components imports
import { MaCommande } from "../pages/MaCommande/MaCommande";
import { UploadDocuments } from "../pages/UploadDocuments/UploadDocuments";
import { Login } from "../pages/Login/Login";
import { RegisterPage } from "../pages/Register/Register";
import { ContactPage } from "../pages/Contact/Contact";
import { ResetPassword } from "../pages/ResetPassword/ResetPassword";
import { HeaderResponsive } from "../components/HeaderResponsive/HeaderResponsive";
import { Footer } from "../components/Footer/Footer";
import { ProjectFollowUp } from "../pages/ProjectFollowUp/ProjectFollowUp";

// Services imports
import { useGetUserAuthInfo } from "../services/useGetUserAuthInfo";
import { useGetUserProfileInfo } from "../services/useGetUserProfileInfo";

//Other imports
import { useAppSelector } from "../redux/store/hook";
import { UserAccount } from "../pages/UserAccount/UserAccount";
import { UserProjects } from "../pages/UserProjects/UserProjects";

// Google analytic initialization
ReactGA.initialize("G-7E604CMTZT");
ReactGA.send("pageview");

export const Navigation: React.FC = () => {
  const { getUserAuthInfo } = useGetUserAuthInfo();
  const { getUserProfileInfo } = useGetUserProfileInfo();
  const { userAuthInfo, userProfileInfo } = useAppSelector((states) => states);

  // At the opening of the portail
  useEffect(() => {
    // Get user authentication information from local storage if they exist.
    getUserAuthInfo();
    // Get user profil  information from local storage if they exist. There is no token as argument so we just try to get the information from local storage.
    getUserProfileInfo();
  }, []);

  return !userAuthInfo?.userToken || !userProfileInfo.id ? (
    <Router>
      <HeaderResponsive />
      <Routes>
        <Route path="/inscription" element={<RegisterPage />} />
        <Route path="/mot-de-passe-oublie" element={<ResetPassword />} />
        <Route path="/*" element={<Login />} />
      </Routes>
    </Router>
  ) : (
    <Router>
      <HeaderResponsive />
      <Routes>
        <Route path="/contact" element={<ContactPage />} />
        <Route path="/ma_commande/:commandId" element={<MaCommande />} />
        <Route path="/mon_projet/:commandId" element={<ProjectFollowUp />} />
        <Route
          path="/transmettre_mes_documents"
          element={<UploadDocuments />}
        />
        <Route path="/mes_informations" element={<UserAccount />} />
        {/* Using path="*"" means "match anything", so this route
                acts like a catch-all for URLs that we don't have explicit
                routes for. */}
        <Route path="/*" element={<UserProjects />} />
      </Routes>
      <Footer />
    </Router>
  );
};
