// Parameters
// ----------
// tabPathname: string
//     the pathname of the tab
// routerPathname: string
//     the active url path
//
// Returns
// ----------
// Boolean
//     true if the tab include the active page, false otherwise

export const isHeaderTabActive = (
  tabPathname: string,
  routerPathname: string
): boolean => {
  // General case : pathname need to math tab path
  if (routerPathname === tabPathname) {
    return true;
  }

  // Case landing page ("Mes projets", navigation path : "/")
  if (tabPathname === "/") {
    // Page "ma_commande" is a child of "Mes projets"
    if (routerPathname.includes("ma_commande")) {
      return true;
    }
    // Page "mon_projet" is a child of "Mes projets"
    if (routerPathname.includes("mon_projet")) {
      return true;
    }
  }
  return false;
};
