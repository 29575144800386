// Interface import
import { IChantierInformation } from "../../../interfaces/interfaces";

// Format status received from CRM to display the right status to the user
export const formatStatusTabRaccordement = (
  chantierInformation?: IChantierInformation
): string => {
  switch (chantierInformation?.statutRaccordement) {
    case "A réaliser":
      return "À venir";
    case "En cours":
      return "En cours";
    case "Demande de compléments":
      return "En cours";
    case "Réalisé":
      if (chantierInformation?.statutMiseEnService === "Validé") {
        return "Réalisé";
      }
      if (chantierInformation?.statutMiseEnService === "KO") {
        return "En cours";
      }
      return "Traité";

    default:
      return "Information non disponible";
  }
};
