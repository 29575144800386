import React from "react";
import { IreglementComptants } from "../../../../../interfaces/interfaces";

// Style import
import styles from "./payementInfoCard.module.scss";

// Local interface
interface IProps {
  PayementInfo: IreglementComptants;
  index: number;
}

export const PayementInfoCard: React.FC<IProps> = ({ PayementInfo, index }) => {
  return (
    <div className={styles.PayementInfoCard}>
      <p>
        Paiement {index + 1}&nbsp;: le&nbsp;
        {new Date(PayementInfo.dateVersement).getDate() +
          "/" +
          (new Date(PayementInfo.dateVersement).getMonth() + 1) +
          "/" +
          new Date(PayementInfo.dateVersement).getFullYear()}
      </p>
      <p>{PayementInfo.natureVersement}</p>
      <p>{PayementInfo.montant}€</p>
    </div>
  );
};
