import "./dropZone.scss";

// Libraries import
import React from "react";
import { DropzoneRootProps } from "react-dropzone";

// Components Imports
import AddFileIcon from "../../assets/images/uploadDocuments/add-file.svg";

// Interface declaration
interface Props {
  getRootProps: <T extends DropzoneRootProps>(props?: T) => T;
  isDragActive: boolean;
}

export const DropZone: React.FC<Props> = ({ getRootProps, isDragActive }) => {
  return (
    <div
      className={`dropZone ${isDragActive ? "dropZone__DragActive" : ""}`}
      {...getRootProps()}
    >
      <img src={AddFileIcon} alt="upload file" className="dropZone__Image" />
      <div className="dropZone__ModalUploadText">
        <label
          htmlFor="file-upload"
          className="dropZone__customFileUpload"
          data-testid="custom-file-upload"
        >
          Séléctionnez votre ou vos fichiers(s)
        </label>
      </div>
    </div>
  );
};
