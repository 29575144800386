import "./status.scss";

// Libraries import
import React from "react";

// Local interfaces declaration
interface Props {
  status: string | null;
}

export const Status: React.FC<Props> = ({ status = "Non transmis" }) => {
  return (
    <p className={`Status`}>
      {status
        ? " - " + status[0].toUpperCase() + status.slice(1).toLowerCase()
        : " - Non transmis"}
    </p>
  );
};
