import "./modal.scss";

// Libraries imports
import React, { ReactElement, ReactNode } from "react";
import ReactDOM from "react-dom";

// Import components
import { ButtonExitCross } from "../../components/ButtonExitCross/ButtonExitCross";

interface Props {
  children: ReactNode;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
}

export const ModalContainer: React.FC<Props> = ({
  children,
  onClick,
}): ReactElement => {
  return ReactDOM.createPortal(
    <div
      className="modal"
      role="dialog"
      aria-modal="true"
      data-testid="modal-container"
    >
      <ButtonExitCross onClick={onClick} diameter={25} />
      {children}
    </div>,
    document.body
  );
};
