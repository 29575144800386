import React from "react";

// Services import
import { formatTabDate } from "../formatTabDate";

// Interfaces import
import { IChantierInformation, ITabData } from "../../../interfaces/interfaces";

// formatDataTabRaccordement : return various values to set Raccordement tab
// Parameters
// ----------
// chantierInformation
//
// Returns
// ----------
// object : raccordementData => data about raccordement tab
//     Boolean : isTabActive
//                    to active tab when Demarches Administratives is activated
//     String : notification
//                    to set the notification icon
//     JSX element : message
//                    to set message in Raccordement tab
// boolean : isRaccordementTabDisplayed
//                    display Raccordement tab when Demarches Administratives is activated
//
//

export const formatDataTabRaccordement = (
  chantierInformation: IChantierInformation
): ITabData => {
  const { statutRaccordement, statutMiseEnService } = chantierInformation;

  //Function setRaccordementDataFunction
  const raccordementData = ((): ITabData => {
    switch (statutRaccordement) {
      case "A réaliser": // À venir
        return {
          isTabActive: true,
          notification: "sandglassOrange",
          message: (
            <>Votre demande de raccordement est en cours de traitement.</>
          ),
        };
      case "En cours": // En cours
        return {
          isTabActive: true,
          notification: "sandglassOrange",
          message: (
            <>Votre demande de raccordement est en cours de traitement.</>
          ),
        };
      case "Demande de compléments": // En cours
        return {
          isTabActive: true,
          notification: "sandglassOrange",
          message: (
            <>Votre demande de raccordement est en cours de traitement.</>
          ),
        };
      case "Réalisé":
        if (statutMiseEnService === "Validé") {
          return {
            isTabActive: false,
            notification: "validatedBlue",
            message: (
              <p>
                Votre mise en service a été réalisée le{" "}
                {formatTabDate(
                  chantierInformation.dateMiseEnServiceProposee,
                  false
                )}
              </p>
            ),
          };
        }
        if (statutMiseEnService === "KO") {
          return {
            isTabActive: true,
            notification: "sandglassOrange",
            message: (
              <p>Votre demande de raccordement est en cours de traitement.</p>
            ),
          };
        }
        return {
          isTabActive: true,
          notification: "validatedBlue",
          message: (
            <p>
              Votre demande de raccordement a été traitée, elle aura lieu le{" "}
              {formatTabDate(
                chantierInformation.dateMiseEnServiceProposee,
                false
              )}
            </p>
          ),
        };
      case "Non nécessaire":
        return {
          isTabActive: false,
          isBlockHided: true,
          notification: "validatedBlue",
          message: <>La demande de raccordement est non nécessaire.</>,
        };
      default: // Information non disponible
        return {
          isTabActive: false,
          notification: "",
          message: <>Aucune information disponible.</>,
        };
    }
  })();

  return raccordementData;
};
