import "./buttonExitCross.scss";

// Libraries import
import React from "react";

//Containers imports
import cross from "../../assets/images/cross.svg";

// Interfaces declaration
interface Props {
  diameter?: number;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  id?: string;
}

// The component takes the onClick function from his parent
// It also takes 2 more arguments :
// Diameter: number  => the diameter of the component
// id = string : a css id that can be used to stylize the button

export const ButtonExitCross: React.FC<Props> = ({
  diameter = 25,
  onClick,
  id = "",
}) => {
  return (
    <button
      className="button-exit-cross"
      id={id}
      type="button"
      onClick={onClick}
      style={{ height: diameter, width: diameter }}
    >
      <img src={cross} alt="close-button" />
    </button>
  );
};
