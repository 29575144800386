import React from "react";
import { BannerContainer } from "../../components/BannerContainer/BannerContainer";
import { Layout } from "../../components/Layout/Layout";
import { UserProjectsBannerContent } from "../../containers/UserProjectsContent/UserProjectBannerContent/UserProjectsBannerContent";
import { UserProjectsContentDetail } from "../../containers/UserProjectsContent/UserProjectsContentDetail/UserProjectsContentDetail";

//Style import
import "./userProjects.scss";

//Component import

export const UserProjects: React.FC = () => {
  return (
    <div className="UserProjects">
      <div className="UserProjects__BannerMobile mobileOnly">
        <BannerContainer>
          <UserProjectsBannerContent />
        </BannerContainer>
      </div>
      <Layout>
        <div className="UserProjects__Content">
          <div className="UserProjects__BannerDesktop desktopOnly">
            <BannerContainer>
              <UserProjectsBannerContent />
            </BannerContainer>
          </div>
          <div className="UserProjects__UserProjectsContent">
            <div className="UserProjects__DynamicMargin" />
            <UserProjectsContentDetail />
            <div className="UserProjects__DynamicMargin" />
          </div>
        </div>
      </Layout>
    </div>
  );
};
