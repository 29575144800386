// Initial state
import { IAppStates } from "../interfaces/interfaces";
export const initialState: IAppStates = {
  userAuthInfo: {
    userToken: null,
    userId: null,
  },
  userProfileInfo: {
    id: null,
    projectManagerId: null,
    username: null,
    firstname: null,
    lastname: null,
    email: null,
    phone: null,
    adresse: null,
    codeParrainage: null,
  },
  projectManagerInfo: {
    username: null,
    firstname: null,
    lastname: null,
    email: null,
    phone: null,
  },
  uploadDocumentsInfo: [],
  downloadDocumentsInfo: [],
  commandInformation: {
    montantCommande: null,
    codePromo: null,
    montantRemise: null,
    montantTotalRecu: null,
    salesProducts: [],
    aides: null,
    reglementComptants: [],
    reglementFinancements: [],
  },
  chantierInformation: {
    commandId: null,
    dateInstallation: null,
    partenaireInstallateur: null,
    dateMiseEnServiceProposee: null,
    status: null,
    statutMairie: null,
    statutConsuel: null,
    dateVisiteConsuel: null,
    statutRaccordement: null,
    statutMiseEnService: null,
    visiteTechniqueInfos: [],
    montantMPR: null,
    statutMPR: null,
    statutMandatMPR: null,
    montantCEE: null,
    statutCEE: null,
    primeAutoConso: null,
    aides: null,
    dateFindeTravaux: null,
    statutFindeTravaux: null,
    chargeProjet: null,
    projectAppointmentInfos: {
      dateAppelPriseEnMain: null,
      lienBooking: null,
      lienteams: null,
      stautPriseEnMain: null,
    },
    statutControleTravaux: null,
  },
  userOrders: [],
  orderSelected: {
    orderNumber: "",
    orderId: "",
  },
  userDocuments: [],
  userAllProjects: [],
  nbdocumentstoUpload: null,
};
