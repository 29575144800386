// Interfaces import
import { IfooterLinksData } from "../interfaces/interfaces";

export const footerLinksData: IfooterLinksData[] = [
  {
    title: "Nos produits",
    pathnames: [
      {
        name: "Photovoltaïque",
        pathname: "/nos-solutions/panneaux-photovoltaiques",
      },
      {
        name: "Borne de recharge",
        pathname: "/nos-solutions/borne-de-recharge-vehicule-electrique",
      },
      {
        name: "Pompe à chaleur Air/Eau",
        pathname: "/nos-solutions/pompe-a-chaleur-air-eau",
      },
      {
        name: "Pompe à chaleur Air/Air",
        pathname: "/nos-solutions/pompe-a-chaleur-air-air",
      },
      {
        name: "Chauffe-eau thermodynamique",
        pathname: "/nos-solutions/chauffe-eau-thermodynamique",
      },
      {
        name: "Chaudière à granulés",
        pathname: "/nos-solutions/chaudiere-a-granules",
      },
      {
        name: "Isolation thermique extérieure",
        pathname: "/nos-solutions/isolation-thermique-par-l-exterieur",
      },
      {
        name: "Isolation des combles",
        pathname: "/nos-solutions/isolation-des-combles",
      },
    ],
  },
  {
    title: "Plus sur TUCO",
    pathnames: [
      { name: "Contact", pathname: "/contact" },
      // { name: "Nos références", pathname: "#" },
      { name: "Espace presse", pathname: "/presse" },
      // { name: "Devenir partenaire", pathname: "#" },
      {
        name: "Rejoignez-nous, on recrute !",
        pathname:
          "https://www.welcometothejungle.com/fr/companies/tuco-energie",
      },
      { name: "FAQ", pathname: "/FAQ" },
      {
        name: "Le Blog de TUCO",
        pathname: "/blog",
      },
    ],
  },
  {
    title: "Mentions légales",
    pathnames: [
      {
        name: "Mentions légales",
        pathname: "/mentions-legales/mentions-legales",
      },
      {
        name: "Politique de cookies",
        pathname: "/mentions-legales/politique-de-cookies",
      },
      {
        name: "Politique de confidentialité",
        pathname: "/mentions-legales/politique-de-confidentialite",
      },
    ],
  },
];
