import React from "react";
import { IChantierInformation, ITabData } from "../../../interfaces/interfaces";

// Services import
import { formatStatusTabPriseEnMain } from "./formatStatusTabPriseEnMain";
import { formatTabDate } from "../formatTabDate";

// Images import
import iconTeams from "../../../assets/images/iconTeams.png";

// formatDataTabPriseEnMain : return various values to set Raccordement tab
// Parameters
// ----------
// chantierInformation
//
// Returns
// ----------
// object : raccordementData => data about raccordement tab
//     Boolean : isTabActive
//                    to active tab when Demarches Administratives is activated
//     String : notification
//                    to set the notification icon
//     JSX element : message
//                    to set message in Raccordement tab
// boolean : isRaccordementTabDisplayed
//                    display Raccordement tab when Demarches Administratives is activated
//
//

export const formatDataTabPriseEnMain = (
  chantierInformation: IChantierInformation
): ITabData => {
  const {
    status: statusInstallation,
    chargeProjet,
    projectAppointmentInfos,
    statutControleTravaux,
  } = chantierInformation;

  const statusPriseEnMain = formatStatusTabPriseEnMain({
    projectAppointmentInfos,
    statutControleTravaux,
  });

  //Function setRaccordementDataFunction
  const priseEnMainData = ((): ITabData => {
    switch (statusPriseEnMain) {
      case "À planifier":
        return {
          isTabActive: true,
          notification: "exclamationPointOrange",
          message: (
            <>
              {statutControleTravaux !== "Réalisé validé" && (
                <>
                  <p>
                    La prise en main sera à planifier une fois
                    l&apos;installation réalisée.
                  </p>
                  <br />
                </>
              )}
              {statutControleTravaux === "Réalisé validé" &&
                !projectAppointmentInfos.dateAppelPriseEnMain && (
                  <>
                    <p>
                      Félicitations ! Votre installation est terminée. Nous vous
                      invitons à prendre rendez-vous avec votre chargé(e) de
                      projet TUCO pour découvrir votre installation et poser
                      toutes vos questions.
                    </p>
                    <br />
                    <p>
                      Votre appel de prise en main est à planifier avec :{" "}
                      {chargeProjet}
                    </p>
                    <br />
                    {projectAppointmentInfos.lienBooking && (
                      <a
                        href={projectAppointmentInfos.lienBooking}
                        target="_blank"
                        rel="noreferrer noopener"
                      >
                        Prendre rendez-vous pour la prise en main.
                      </a>
                    )}
                  </>
                )}
            </>
          ),
        };
      case "Planifiée":
        return {
          isTabActive: true,
          isCancelMeetingDisplayed: true,
          notification: "sandglassOrange",
          message: (
            <>
              {(statutControleTravaux === "Réalisé non conforme" ||
                statutControleTravaux === "Réalisé validé") &&
                projectAppointmentInfos.dateAppelPriseEnMain && (
                  <>
                    <p>
                      Date de votre appel de prise en main :{" "}
                      {formatTabDate(
                        projectAppointmentInfos.dateAppelPriseEnMain
                      )}
                    </p>
                    <br />
                    {projectAppointmentInfos.lienteams && (
                      <div>
                        <a
                          href={projectAppointmentInfos.lienteams || ""}
                          target="_blank"
                          rel="noreferrer noopener"
                        >
                          <img src={iconTeams} alt="" />
                          Votre lien de rendez-vous
                        </a>
                      </div>
                    )}
                  </>
                )}
            </>
          ),
        };
      case "Réalisée":
        return {
          isTabActive: false,
          notification: "validatedBlue",
          message: (
            <>
              <p>
                Réalisée le{" "}
                {formatTabDate(projectAppointmentInfos.dateAppelPriseEnMain)}
              </p>
            </>
          ),
        };
      case "À venir":
        return {
          isTabActive: false,
          notification: "sandglassOrange",
          message: (
            <>
              <p>
                La prise en main sera à planifier une fois l&apos;installation
                réalisée.
              </p>
            </>
          ),
        };
      default:
        return {
          isTabActive: false,
          notification: "",
          message: (
            <>
              La prise en main sera à planifier une fois l&apos;installation
              réalisée.
            </>
          ),
        };
    }
  })();

  return priseEnMainData;
};
