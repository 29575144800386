import React from "react";

//Style import
import "./bannerContainer.scss";

interface Props {
  children: React.ReactNode;
  backgroundColor?: "White" | "Blue";
}

export const BannerContainer: React.FC<Props> = ({
  children,
  backgroundColor = "White",
}) => {
  return <div className={`BannerContainer ${backgroundColor}`}>{children}</div>;
};
