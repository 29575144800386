import "./productInfoBox.scss";

// Libraries import
import React from "react";

// Interfaces import
import { ISalesProducts } from "../../interfaces/interfaces";

//image import
import produitLibre from "../../assets/images/produit-libre.svg";

// Local interface declaration
interface Props {
  productInfo: ISalesProducts;
}

export const ProductInfoBox: React.FC<Props> = ({ productInfo }) => {
  return (
    <div className="productInfoBox">
      <div className="productInfoBox__Image">
        <img
          src={`data:${productInfo.picture?.mimetype};base64,${productInfo.picture?.documentbody}`}
          alt="product image"
          onError={(e) => {
            e.currentTarget.src = produitLibre;
            e.currentTarget.onerror = null;
          }}
        />
      </div>
      <div className="productInfoBox__Content">
        <div className="productInfoBox__Title">
          <p>{productInfo?.name || "Nom du produit inconnu"}</p>
        </div>
        <div className="productInfoBox__Information">
          {productInfo.properties?.map((property, index) => {
            return (
              <div key={index}>
                {property.name && property.value && (
                  <p>
                    {property.name} : {property.value}
                  </p>
                )}
              </div>
            );
          })}
        </div>
        <div className="productInfoBox__Price">
          <p>
            Prix TTC :{" "}
            {productInfo.price === 0
              ? "Offert"
              : productInfo.price
              ? new Intl.NumberFormat("fr-FR").format(productInfo.price) + " €"
              : "Prix non disponible"}
          </p>
        </div>
      </div>
    </div>
  );
};
