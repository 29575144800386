import "./DemarchesAdministrativesContent.scss";

// Libraries import
import React from "react";

// Components import
import { OpenCloseBlock } from "../../../components/OpenCloseBlock/OpenCloseBlock";
import { AccordMairieTabContent } from "../AccordMairieTabContent/AccordMairieTabContent";
import { RaccordementTabContent } from "../RaccordementTabContent/RaccordementTabContent";
import { ConsuelTabContent } from "../ConsuelTabContent/ConsuelTabContent";
import { RemboursementAidesTabContent } from "../RemboursementAidesTabContent/RemboursementAidesTabContent";
import { DemarchesAdministrativesFallbackMessage } from "../DemarchesAdministrativesFallbackMessage/DemarchesAdministrativesFallbackMessage";

// Services import
import { isAccordMairieTabDisplayed } from "../../../services/ProjectFollowUp/DemarchesAdministratives/isAccordMairieTabDisplayed";
import { formatDataTabAccordMairie } from "../../../services/ProjectFollowUp/DemarchesAdministratives/formatDataTabAccordMairie";
import { formatStatusTabAccordMairie } from "../../../services/ProjectFollowUp/DemarchesAdministratives/formatStatusTabAccordMairie";
import { isRaccordementTabDisplayed } from "../../../services/ProjectFollowUp/DemarchesAdministratives/isRaccordementTabDisplayed";
import { formatDataTabRaccordement } from "../../../services/ProjectFollowUp/DemarchesAdministratives/formatDataTabRaccordement";
import { formatStatusTabRaccordement } from "../../../services/ProjectFollowUp/DemarchesAdministratives/formatStatusTabRaccordement";
import { isConsuelTabDisplayed } from "../../../services/ProjectFollowUp/DemarchesAdministratives/isConsuelTabDisplayed";
import { formatDataTabConsuel } from "../../../services/ProjectFollowUp/DemarchesAdministratives/formatDataTabConsuel";
import { formatStatusTabConsuel } from "../../../services/ProjectFollowUp/DemarchesAdministratives/formatStatusTabConsuel";
import { isAidesTabDisplayed } from "../../../services/ProjectFollowUp/DemarchesAdministratives/isAidesTabDisplayed";
import { formatDataTabAides } from "../../../services/ProjectFollowUp/DemarchesAdministratives/formatDataTabAides";

// Interfaces import
import { IChantierInformation } from "../../../interfaces/interfaces";

// Local interfaces declaration
interface Props {
  areAdministrativeMobileTabsOpen: boolean[];
  areAdministrativeDesktopTabsOpen: boolean[];
  onClickOnAdministrativeTab: (index: number) => void;
  chantierInformation: IChantierInformation;
  isDemarcheAdministrativeFallbackMessageDisplayed: boolean;
}

export const DemarchesAdministrativesContent: React.FC<Props> = ({
  areAdministrativeMobileTabsOpen,
  areAdministrativeDesktopTabsOpen,
  onClickOnAdministrativeTab,
  chantierInformation,
  isDemarcheAdministrativeFallbackMessageDisplayed,
}) => {
  return (
    <div className="DemarchesAdministrativesContent">
      <div className="DemarchesAdministrativesContent__Mobile mobileOnly">
        {isAccordMairieTabDisplayed(chantierInformation) && (
          <div className="DemarchesAdministrativesContent__OpencloseBlock">
            <OpenCloseBlock
              title="Accord Mairie"
              isOpen={areAdministrativeMobileTabsOpen[0]}
              onClick={() => onClickOnAdministrativeTab(0)}
              status={formatStatusTabAccordMairie(
                chantierInformation?.statutMairie
              )}
              notification={
                formatDataTabAccordMairie(chantierInformation).notification
              }
            >
              <AccordMairieTabContent
                chantierInformation={chantierInformation}
              />
            </OpenCloseBlock>
          </div>
        )}

        {isConsuelTabDisplayed(chantierInformation) && (
          <div className="DemarchesAdministrativesContent__OpencloseBlock">
            <OpenCloseBlock
              title="Consuel"
              isOpen={areAdministrativeMobileTabsOpen[1]}
              onClick={() => onClickOnAdministrativeTab(1)}
              status={formatStatusTabConsuel(chantierInformation.statutConsuel)}
              notification={
                formatDataTabConsuel(chantierInformation).notification
              }
            >
              <ConsuelTabContent chantierInformation={chantierInformation} />
            </OpenCloseBlock>
          </div>
        )}

        {isRaccordementTabDisplayed(chantierInformation) && (
          <div className="DemarchesAdministrativesContent__OpencloseBlock">
            <OpenCloseBlock
              title="Raccordement"
              isOpen={areAdministrativeMobileTabsOpen[2]}
              onClick={() => onClickOnAdministrativeTab(2)}
              status={formatStatusTabRaccordement(chantierInformation)}
              notification={
                formatDataTabRaccordement(chantierInformation).notification
              }
            >
              <RaccordementTabContent
                chantierInformation={chantierInformation}
              />
            </OpenCloseBlock>
          </div>
        )}

        {isAidesTabDisplayed(chantierInformation) && (
          <div className="DemarchesAdministrativesContent__OpencloseBlock">
            <OpenCloseBlock
              title="Remboursement des aides"
              isOpen={areAdministrativeMobileTabsOpen[3]}
              onClick={() => onClickOnAdministrativeTab(3)}
              notification={
                formatDataTabAides(chantierInformation).notification
              }
            >
              <RemboursementAidesTabContent
                chantierInformation={chantierInformation}
              />
            </OpenCloseBlock>
          </div>
        )}
        {isDemarcheAdministrativeFallbackMessageDisplayed && (
          <DemarchesAdministrativesFallbackMessage />
        )}
      </div>
      <div className="DemarchesAdministrativesContent__Desktop desktopOnly">
        {isAccordMairieTabDisplayed(chantierInformation) && (
          <div className="DemarchesAdministrativesContent__OpencloseBlock">
            <OpenCloseBlock
              title="Accord Mairie"
              isOpen={areAdministrativeDesktopTabsOpen[0]}
              onClick={() => onClickOnAdministrativeTab(0)}
              status={formatStatusTabAccordMairie(
                chantierInformation?.statutMairie
              )}
              notification={
                formatDataTabAccordMairie(chantierInformation).notification
              }
            />
          </div>
        )}

        {isConsuelTabDisplayed(chantierInformation) && (
          <div className="DemarchesAdministrativesContent__OpencloseBlock">
            <OpenCloseBlock
              title="Consuel"
              isOpen={areAdministrativeDesktopTabsOpen[1]}
              onClick={() => onClickOnAdministrativeTab(1)}
              status={formatStatusTabConsuel(chantierInformation.statutConsuel)}
              notification={
                formatDataTabConsuel(chantierInformation).notification
              }
            />
          </div>
        )}

        {isRaccordementTabDisplayed(chantierInformation) && (
          <div className="DemarchesAdministrativesContent__OpencloseBlock">
            <OpenCloseBlock
              title="Raccordement"
              isOpen={areAdministrativeDesktopTabsOpen[2]}
              onClick={() => onClickOnAdministrativeTab(2)}
              status={formatStatusTabRaccordement(chantierInformation)}
              notification={
                formatDataTabRaccordement(chantierInformation).notification
              }
            />
          </div>
        )}

        {isAidesTabDisplayed(chantierInformation) && (
          <div className="DemarchesAdministrativesContent__OpencloseBlock">
            <OpenCloseBlock
              title="Remboursement des aides"
              isOpen={areAdministrativeDesktopTabsOpen[3]}
              onClick={() => onClickOnAdministrativeTab(3)}
              notification={
                formatDataTabAides(chantierInformation).notification
              }
            />
          </div>
        )}
      </div>
    </div>
  );
};
