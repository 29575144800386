import "./contact.scss";

// Libraries import
import React from "react";

// Components import
import { Layout } from "../../components/Layout/Layout";
import { SubHeader } from "../../containers/SubHeader/SubHeader";
import { BusinessContactView } from "../../containers/contactPage/BusinessContactView/BusinessContactView";
import { TucoenergieContactView } from "../../containers/contactPage/TucoenergieContactView/TucoenergieContactView";
import { TucoenergieAdressView } from "../../containers/contactPage/TucoenergieAdressView/TucoenergieAdressView";

export const ContactPage: React.FC = () => {
  return (
    <>
      <SubHeader title="Contacter TUCO" />
      <Layout>
        <div className="contact-page">
          <BusinessContactView />
          <TucoenergieContactView />
          <TucoenergieAdressView />
        </div>
      </Layout>
    </>
  );
};
