import React from "react";
import "./DemarchesAdministrativesFallbackMessage.scss";

export const DemarchesAdministrativesFallbackMessage: React.FC = () => {
  return (
    <div className="DemarchesAdministrativesFallbackMessage">
      <p>Vous n&apos;avez aucune démarche administrative en cours !</p>
    </div>
  );
};
