// Libraries import
import { useState } from "react";
import { useAxiosGet } from "../useAxiosGet";

// Services import
import { useAppDispatch } from "../../redux/store/hook";
import { setUserDocumentsAction } from "../../redux/appActions";

// Interfaces import
import { IUserDocument } from "../../interfaces/interfaces";

// Local interface declaration
interface IFunctionReturn {
  getMyDocuments: (
    userToken: string | null,
    userId: string | null,
    orderNumber: string | null
  ) => Promise<void>;
  isLoading: boolean;
  errorMessage: string;
}

// useGetMyDocuments : return tools to send get user's documents request to CRM and handle error
// Parameters
// ----------
// RAS
//
// Returns
// ----------
// Function getMyDocuments
//     Function to call to get user's documents
// Boolean isLoading
//     Indicate if the asynchronous request is onGoing
// String errorMessage
//     Error message (no parameters or request error message)

export const useGetMyDocuments = (): IFunctionReturn => {
  // Retrieve env. variable
  const BACKEND_URL = process.env.REACT_APP_API_AUTHENTICATION_URL;
  // Hooks call
  const {
    axiosGetRequest,
    isLoading,
    errorMessage: axiosErrorMessage,
  } = useAxiosGet();
  const dispatch = useAppDispatch();
  const [errorMessage, setErrorMessage] = useState<string>("");

  // getMyDocuments : send Get my documents request.
  // Parameters
  // ----------
  // userToken: String | null
  //      the JWT authentication token of the user
  // userId: String | null
  //      the unique id of the user
  // orderSelected : string | null
  //      the id of the order selected by the user in the dropdown menu
  // Returns
  // ----------
  // Void

  const getMyDocuments = async (
    userToken: string | null,
    userId: string | null,
    orderNumber: string | null
  ): Promise<void> => {
    setErrorMessage("");
    const response = await axiosGetRequest<IUserDocument[]>(
      `${BACKEND_URL}/api/User/${userId}/getMyDocuments?orderNumber=${orderNumber}`,
      {
        headers: {
          authorization: `Bearer ${userToken}`,
        },
      }
    );
    // If we have a reponse and the array contains data (i.e contains documents) we update redux
    if (response && response.data?.length) {
      const formatedResponse = {...response}
      formatedResponse.data.forEach((file) => {
        file.fileTag =
          file.fileTag +
          `${file.fileTag?.indexOf(".pdf") === -1 ? ".pdf" : ""}`;
      });
      dispatch(setUserDocumentsAction({ userDocuments: formatedResponse.data }));
    }
  };

  return {
    getMyDocuments,
    isLoading,
    errorMessage: errorMessage ? errorMessage : axiosErrorMessage,
  };
};
