import styles from "./footer.module.scss";

// Libraries import

import React from "react";

// Component import
import { LogoWhite } from "../LogoWhite/LogoWhite";
import { Layout } from "../Layout/Layout";
import { NewsletterInput } from "../NewsletterInput/NewsLetterInput";
import { SocialMedias } from "../SocialMedias/SocialMedias";
import { GreentechCertification } from "../GreentechCertification/GreentechCertification";
import { ISO9001 } from "../ISO9001/ISO9001";

// Data import
import { footerLinksData } from "../../data/footerData";

interface IProps {
  areProductsLinksVisible?: boolean;
}

export const Footer: React.FC<IProps> = ({ areProductsLinksVisible }) => {
  return (
    <footer className={styles.footer}>
      <Layout>
        <div className={styles.footerContainer}>
          <div className={styles.footerLeft}>
            <GreentechCertification />
            <ISO9001 />
          </div>
          <div className={styles.footerCenter}>
            {areProductsLinksVisible !== false &&
              footerLinksData.map((elem, index) => {
                return (
                  <div className={styles.footerColumn} key={index}>
                    <p className={styles.footerTitle}>{elem.title}</p>
                    <div className={styles.footerLinks}>
                      {elem.pathnames.map((link, index) => {
                        return (
                          <a
                            className={styles.footerText}
                            href={`https://tucoenergie.fr${link.pathname}`}
                            key={index}
                          >
                            {link.name}
                          </a>
                        );
                      })}
                    </div>
                  </div>
                );
              })}
            {areProductsLinksVisible === false &&
              footerLinksData.slice(1).map((elem, index) => {
                //We slice here to remove the first item of data (products list)
                return (
                  <div className={styles.footerColumn} key={index}>
                    <p className={styles.footerTitle}>{elem.title}</p>
                    <div className={styles.footerLinks}>
                      {elem.pathnames.map((link, index) => {
                        return (
                          <a
                            className={styles.footerText}
                            href={`https://www.tucoenergie.fr${link.pathname}`}
                            key={index}
                          >
                            {link.name}
                          </a>
                        );
                      })}
                    </div>
                  </div>
                );
              })}
          </div>
          <div className={styles.footerRight}>
            <div className={styles.footerColumn}>
              <p className={styles.footerTitle}>Restez informé</p>
              <p className={styles.footerText}>Avec la newsletter TUCO</p>
            </div>
            <NewsletterInput />
            <div className={styles.socialMedias}>
              <SocialMedias />
            </div>
          </div>
        </div>
      </Layout>
    </footer>
  );
};
