import React, { useEffect } from "react";

//Style import
import "./userProjectsContentDetail.scss";

//import Service
import { useAppSelector } from "../../../redux/store/hook";
import { UserProjectsDone } from "../UserProjectsDone/UserProjectsDone";
import { UserProjectsInProgress } from "../UserProjectsInProgress/UserProjectsInProgress";

import { useGetAllClientProjects } from "../../../services/UsersProjects/useGetAllClientProjects";
import { LoadingComponent } from "@web/shared/dist/components/Old/LoadingComponent/LoadingComponent";

export const UserProjectsContentDetail: React.FC = () => {
  const { userAuthInfo, userAllProjects } = useAppSelector((states) => states);
  const { requestAllClientProjects, isLoading, errorMessage } =
    useGetAllClientProjects();

  useEffect(() => {
    if (userAllProjects.length === 0) {
      requestAllClientProjects(userAuthInfo.userToken, userAuthInfo.userId);
    }
  }, [userAuthInfo.userToken]);

  const userProjectsDone = userAllProjects?.filter(
    (project) => project.status === "Done"
  );
  const userProjectsEnCours = userAllProjects?.filter(
    (project) => project.status === "Signé"
  );

  return (
    <>
      {isLoading ? (
        <div className="UserProjectsContentDetail__LoadingComponent">
          {" "}
          <LoadingComponent diameter={60} />
        </div>
      ) : (
        <div className="UserProjectsContentDetail">
          {userProjectsEnCours?.length != 0 && (
            <div className="UserProjectsContentDetail__ProjectsWIP">
              <h2>Projets en cours</h2>
              <UserProjectsInProgress data={userProjectsEnCours} />
            </div>
          )}

          {userProjectsDone?.length != 0 && (
            <div className="UserProjectsContentDetail__ProjectsDone">
              <h2>Projets installés</h2>
              <UserProjectsDone data={userProjectsDone} />
            </div>
          )}

          {!errorMessage && userAllProjects?.length === 0 && (
            <div className="UserProjectsContentDetail__ProjectsDone">
              <h2>Vous n&apos;avez pas encore de projet en cours.</h2>
            </div>
          )}
          {errorMessage && userAllProjects?.length === 0 && (
            <div className="UserProjectsContentDetail__ProjectsDone">
              <h2>
                Vous pourrez bientôt retrouver toutes les informations sur votre
                projet.
              </h2>
            </div>
          )}
        </div>
      )}
    </>
  );
};
