import "./MaCommandeContent.scss";

// Libraries import
import React, { ReactElement, useEffect } from "react";

// Components import
import { SingleFileDisplay } from "../../../components/SingleFileDisplay/SingleFileDisplay";
import { LoadingComponent } from "@web/shared/dist/components/Old/LoadingComponent/LoadingComponent";
import { NoDocumentFound } from "../NoDocumentFound/NoDocumentFound";
import { ProductInfoBox } from "../../../components/ProductInfoBox/ProductInfoBox";
import { PaymentInfoBlock } from "../../../containers/MaCommande/PaymentInfoBlock/PaymentInfoBlock";
import { ErrorMessage } from "@web/shared/dist/components/Old/ErrorMessage/ErrorMessage";

// Services import
import { useAppSelector } from "../../../redux/store/hook";
import { useGetMyDocuments } from "../../../services/maCommande/useGetMyDocuments";
import { useGetCommandeInformations } from "../../../services/maCommande/useGetCommandeInformations";

// Local interfaces declaration
interface Props {
  commandId?: string;
  orderNumber: string | null;
}

export const MaCommandeContent: React.FC<Props> = ({
  commandId,
  orderNumber,
}): ReactElement => {
  // Hook calls
  const { userAuthInfo, userProfileInfo, userDocuments, commandInformation } =
    useAppSelector((states) => states);

  const { getMyDocuments, isLoading: isGetMyDocumentsLoading } =
    useGetMyDocuments();
  const {
    requestCommandInformation,
    isLoading: isGetCommandInformationLoading,
    errorMessage: getCommandInformationErrorMessage,
  } = useGetCommandeInformations();

  useEffect(() => {
    // We request informations only if the commandId in the URL exist and is different from the commandId of the command informations we already might have saved in redux (to no request them if we already have them)
    if (commandId && commandId !== commandInformation.commandId) {
      requestCommandInformation(userAuthInfo.userToken, commandId);
      if (userDocuments.length === 0) {
        getMyDocuments(userAuthInfo.userToken, userProfileInfo.id, orderNumber);
      }
    }
  }, []);

  return isGetCommandInformationLoading ? (
    <div className="maCommandeContent__LoadingComponent">
      <LoadingComponent />
    </div>
  ) : getCommandInformationErrorMessage ? (
    <div className="maCommandeContent__ErrorMessage">
      <ErrorMessage errorMessage={getCommandInformationErrorMessage} />{" "}
    </div>
  ) : (
    <div className="maCommandeContent">
      <div className="maCommandeContent__ProjectSummary">
        <h2 className="maCommandeContent__Title" id="recapitulatif_projet">
          Récapitulatif de mon projet
        </h2>
        <p className="maCommandeContent__SubTitle">{"N° " + orderNumber}</p>
        <div className="maCommandeContent__ProductsInfoBoxes">
          {commandInformation.salesProducts.map((productInfo, index) => {
            return (
              <div className="maCommandeContent__ProductInfoBox" key={index}>
                <ProductInfoBox productInfo={productInfo} />
              </div>
            );
          })}
        </div>
      </div>
      <div className="maCommandeContent__Paying">
        <h2 className="maCommandeContent__Title" id="reglement">
          Règlement
        </h2>
        <div className="maCommandeContent__PayingContent">
          <PaymentInfoBlock
            montantCommande={commandInformation.montantCommande}
            codePromo={commandInformation.codePromo}
            montantRemise={commandInformation.montantRemise}
            aides={commandInformation.aides}
            montantTotalRecu={commandInformation.montantTotalRecu}
            reglementComptants={commandInformation.reglementComptants}
            reglementFinancements={commandInformation.reglementFinancements}
          />
        </div>
      </div>
      <div className="maCommandeContent__MyDocuments">
        <h2 className="maCommandeContent__Title" id="documents">
          Documents à disposition
        </h2>
        {isGetMyDocumentsLoading ? (
          <div className="maCommandeContent__LoadingComponent">
            <LoadingComponent diameter={60} />
          </div>
        ) : userDocuments.length === 0 ? (
          <NoDocumentFound />
        ) : (
          <div className="maCommandeContent__MyDocumentsContent">
            {userDocuments.map((content, index) => {
              return (
                <SingleFileDisplay
                  fileName={content.fileTag}
                  fileLink={content.downloadLink}
                  key={index}
                />
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
};
