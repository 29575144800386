import "./uploadDocuments.scss";

// Libraries imports
import React from "react";

// components imports
import { Layout } from "../../components/Layout/Layout";
import { UploadDocumentsContent } from "../../containers/uploadDocuments/uploadDocumentsContent/UploadDocumentsContent";
import { UploadDocumentsHero } from "../../containers/uploadDocuments/UploadDocumentsHero/UploadDocumentsHero";
import { BannerContainer } from "../../components/BannerContainer/BannerContainer";

export const UploadDocuments: React.FC = () => {
  return (
    <div className="uploadDocuments" data-testid="upload-documents">
      <div className="uploadDocuments__BannerMobile mobileOnly">
        <BannerContainer>
          <UploadDocumentsHero />
        </BannerContainer>
      </div>
      <Layout>
        <div className="uploadDocuments__Content">
          <div className="uploadDocuments__BannerDesktop desktopOnly">
            <BannerContainer>
              <UploadDocumentsHero />
            </BannerContainer>
          </div>
          <div className="uploadDocuments__UploadDocumentContent">
            <div className="uploadDocuments__DynamicMargin" />
            <UploadDocumentsContent />
          </div>
        </div>
      </Layout>
    </div>
  );
};
