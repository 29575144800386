import styles from "./socialMedias.module.scss";

import { FC } from "react";

// Data import
import { socialMediasData } from "../../data/socialMediasData";

// Local interface declaration
interface Props {
  color?: string;
}

export const SocialMedias: FC<Props> = ({ color = "white" }) => {
  /* eslint-disable */

  return (
    <div className={styles.socialMedias}>
      {socialMediasData.map((elem, index) => {
        return (
          <div
            key={index}
            style={color === "grey" ? { maxHeight: 30 } : {}}
            onClick={() => window.open(elem.href, "_blank")}
          >
            <img
              src={color === "grey" ? elem.pictoGreySrc : elem.pictoSrc}
              alt={elem.pictoAlt}
              height={50}
              width={50}
            />
          </div>
        );
      })}
    </div>
  );
};
