import React from "react";

// Interface import
import { IVisiteTechniqueInfo } from "../../../interfaces/interfaces";

export const formatVisiteTechniqueDate = (
  visiteTechnique: IVisiteTechniqueInfo
): JSX.Element => {
  const dateVisiteTechniqueFormated = new Date(
    visiteTechnique.dateVisite || ""
  );
  return (
    <span>
      {visiteTechnique.dateVisite ? (
        <>
          <strong>
            {dateVisiteTechniqueFormated.toLocaleDateString("fr")}
          </strong>
        </>
      ) : (
        <>xx/xx/xx</>
      )}
      {visiteTechnique.finViste && visiteTechnique.dateVisite ? (
        <>
          {" "}
          entre{" "}
          {String(dateVisiteTechniqueFormated.getHours()).padStart(2, "0")}h
          {String(dateVisiteTechniqueFormated.getMinutes()).padStart(2, "0")} et{" "}
          {visiteTechnique.finViste}
        </>
      ) : (
        visiteTechnique.dateVisite && (
          <>
            {" "}
            à {String(dateVisiteTechniqueFormated.getHours()).padStart(2, "0")}h
            {String(dateVisiteTechniqueFormated.getMinutes()).padStart(2, "0")}{" "}
          </>
        )
      )}
    </span>
  );
};
