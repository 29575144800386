import "./ChantierContent.scss";

// Libraries import
import React from "react";

// Components import
import { OpenCloseBlock } from "../../../components/OpenCloseBlock/OpenCloseBlock";
import { VisiteTechniqueTabContent } from "../../../containers/ProjectFollowUp/VisiteTechniqueTabContent/VisiteTechniqueTabContent";
import { InstallationTabContent } from "../../../containers/ProjectFollowUp/InstallationTabContent/InstallationTabContent";
import { PriseEnMainTabContent } from "../../../containers/ProjectFollowUp/PriseEnMainTabContent/PriseEnMainTabContent";

// Services import
import { formatStatusTabVisitesTechniques } from "../../../services/ProjectFollowUp/SuiviChantier/formatStatusTabVisitesTechniques";
import { formatStatusTabPriseEnMain } from "../../../services/ProjectFollowUp/SuiviChantier/formatStatusTabPriseEnMain";
import { formatDataTabPriseEnMain } from "../../../services/ProjectFollowUp/SuiviChantier/formatDataTabPriseEnMain";
import { formatDataTabInstallation } from "../../../services/ProjectFollowUp/SuiviChantier/formatDataTabInstallation";
import { formatStatusTabInstallation } from "../../../services/ProjectFollowUp/SuiviChantier/formatStatusTabInstallation";

// Interfaces import
import { IChantierInformation } from "../../../interfaces/interfaces";

// Local interfaces declaration
interface Props {
  areChantierMobileTabsOpen: boolean[];
  areChantierDesktopTabsOpen: boolean[];
  onClickOnChantierTab: (index: number) => void;
  chantierInformation: IChantierInformation;
}

export const ChantierContent: React.FC<Props> = ({
  areChantierMobileTabsOpen,
  areChantierDesktopTabsOpen,
  onClickOnChantierTab,
  chantierInformation,
}) => {
  const { globalStatusVisitesTechniques, globalNotificationVisitesTechniques } =
    formatStatusTabVisitesTechniques(chantierInformation);

  const {
    projectAppointmentInfos,
    status: statusInstallation,
    statutControleTravaux,
  } = chantierInformation;

  return (
    <div className="ChantierContent">
      <div className="ChantierContent__Mobile mobileOnly">
        <div className="ChantierContent__OpencloseBlock">
          <OpenCloseBlock
            title="Visite technique"
            isOpen={areChantierMobileTabsOpen[0]}
            onClick={() => onClickOnChantierTab(0)}
            status={globalStatusVisitesTechniques}
            notification={globalNotificationVisitesTechniques}
          >
            <VisiteTechniqueTabContent
              chantierInformation={chantierInformation}
            />
          </OpenCloseBlock>
        </div>
        <div className="ChantierContent__OpencloseBlock">
          <OpenCloseBlock
            title="Installation"
            isOpen={areChantierMobileTabsOpen[1]}
            onClick={() => onClickOnChantierTab(1)}
            status={formatStatusTabInstallation(statusInstallation)}
            notification={
              formatDataTabInstallation(chantierInformation).notification
            }
          >
            <InstallationTabContent chantierInformation={chantierInformation} />
          </OpenCloseBlock>
        </div>

        <div className="ChantierContent__OpencloseBlock">
          <OpenCloseBlock
            title="Rendez-vous de prise en main"
            isOpen={areChantierMobileTabsOpen[2]}
            onClick={() => onClickOnChantierTab(2)}
            status={formatStatusTabPriseEnMain({
              projectAppointmentInfos,
              statutControleTravaux,
            })}
            notification={
              formatDataTabPriseEnMain(chantierInformation).notification
            }
          >
            <PriseEnMainTabContent chantierInformation={chantierInformation} />
          </OpenCloseBlock>
        </div>
      </div>
      <div className="ChantierContent__Desktop desktopOnly">
        <div className="ChantierContent__OpencloseBlock">
          <OpenCloseBlock
            title="Visite technique"
            isOpen={areChantierDesktopTabsOpen[0]}
            onClick={() => onClickOnChantierTab(0)}
            status={globalStatusVisitesTechniques}
            notification={globalNotificationVisitesTechniques}
          />
        </div>

        <div className="ChantierContent__OpencloseBlock">
          <OpenCloseBlock
            title="Installation"
            isOpen={areChantierDesktopTabsOpen[1]}
            onClick={() => onClickOnChantierTab(1)}
            status={formatStatusTabInstallation(statusInstallation)}
            notification={
              formatDataTabInstallation(chantierInformation).notification
            }
          />
        </div>

        <div className="ChantierContent__OpencloseBlock">
          <OpenCloseBlock
            title="Rendez-vous de prise en main"
            isOpen={areChantierDesktopTabsOpen[2]}
            onClick={() => onClickOnChantierTab(2)}
            status={formatStatusTabPriseEnMain({
              projectAppointmentInfos,
              statutControleTravaux,
            })}
            notification={
              formatDataTabPriseEnMain(chantierInformation).notification
            }
          />
        </div>
      </div>
    </div>
  );
};
