import PictoSandglassOrange from "../assets/images/Pictos/picto_sandglass_orange.svg";
import PictoSandglassGrey from "../assets/images/Pictos/picto_sandglass_grey.svg";
import PictoExclamationPointOrange from "../assets/images/Pictos/picto_exclamation_orange.svg";
import PictoExclamationPointWhite from "../assets/images/Pictos/picto_exclamation_white.svg";
import PictoValidatedBlue from "../assets/images/Pictos/picto_validated_blue.svg";
import PictoExclamationPointRed from "../assets/images/Pictos/picto_exclamation_Red.svg";

export const notificationDictionary = {
  sandglassOrange: PictoSandglassOrange,
  sandglassGrey: PictoSandglassGrey,
  exclamationPointOrange: PictoExclamationPointOrange,
  exclamationPointWhite: PictoExclamationPointWhite,
  validatedBlue: PictoValidatedBlue,
  exclamationPointRed: PictoExclamationPointRed,
};
