import styles from "./headerNavigation.module.scss";

// Libraries import
import { Link, useLocation } from "react-router-dom";
import React, { FC, useEffect, useState } from "react";

//Service Import
import { useDisconnectApp } from "../../../services/useDisconnectApp";

// Data import
import { headerNavigationData } from "../../../data/headerNavigationData";
import { isHeaderTabActive } from "../../../services/Header/isHeaderTabActive";

//Img import
import userLogo from "../../../assets/images/user-logo.svg";

//Interface import
import { IUserProfileInfo } from "../../../interfaces/interfaces";
import { HeaderDropdown } from "../HeaderDropDown/HeaderDropDown";

interface Props {
  isResponsiveMenuActive: boolean;
  userProfileInfo: IUserProfileInfo;
  toggleResponsiveMenu: () => void;
}

export const HeaderNavigation: FC<Props> = ({
  isResponsiveMenuActive,
  userProfileInfo,
  toggleResponsiveMenu,
}) => {
  const location = useLocation();
  const { disconnectApp } = useDisconnectApp();

  const onClickDisconnectButton = () => {
    toggleResponsiveMenu();
    disconnectApp();
  };

  const initialResponsivesTabOpen: boolean[] = [];
  for (let i = 0; i < headerNavigationData.length; i++) {
    initialResponsivesTabOpen.push(false);
  }
  const [openResponsiveTab, setOpenResponsiveTab] = useState<boolean[]>(
    initialResponsivesTabOpen
  );

  // Close tab when user close the menu
  useEffect(() => {
    !isResponsiveMenuActive && setOpenResponsiveTab(initialResponsivesTabOpen);
  }, [isResponsiveMenuActive]);

  // Event handler
  const updateResponsiveTabIsOpen = (index: number, status?: boolean) => {
    setOpenResponsiveTab((prevState) => {
      const newState = initialResponsivesTabOpen;
      newState[index] = status || !prevState[index];
      return newState;
    });
  };

  return (
    <div
      className={
        styles.HeaderNavigation +
        " " +
        `${isResponsiveMenuActive && styles.responsiveMenuActive}`
      }
    >
      <nav className={styles.HeaderNavigation__navigationContent}>
        <div className={styles.HeaderNavigation__UserAccountContainer}>
          <div className={styles.HeaderNavigation__UserAccount}>
            <img
              src={userLogo}
              alt={"user logo"}
              className={styles.HeaderNavigation__UserAvatar}
            />
            <p>Portail client</p>
          </div>

          <p>
            Bonjour {userProfileInfo.firstname} {userProfileInfo.lastname} !
          </p>
          {userProfileInfo.id ? (
            <Link to="/mes_informations" onClick={toggleResponsiveMenu}>
              <p>Mon compte</p>
            </Link>
          ) : (
            <>
              <Link to="/" onClick={toggleResponsiveMenu}>
                <p>Se connecter à mon espace client</p>
              </Link>
              <a href="https://vt.tucoenergie.fr/">
                <p>Mon espace pro</p>
              </a>
            </>
          )}
        </div>

        {userProfileInfo.id && (
          <ul className={styles.HeaderNavigation__navigationList}>
            {headerNavigationData.map((item, index) => {
              return (
                <div
                  key={index}
                  className={`${styles.HeaderNavigation__navigationListItem}`}
                >
                  {item.type === "one" ? (
                    <li>
                      <Link
                        to={`${item.pathname}`}
                        onClick={toggleResponsiveMenu}
                      >
                        <p
                          onClick={
                            isResponsiveMenuActive
                              ? () => updateResponsiveTabIsOpen(index)
                              : undefined
                          }
                        >
                          {item.title}
                        </p>
                      </Link>
                      <div
                        className={
                          styles.underline +
                          ` ${
                            isHeaderTabActive(
                              `${item.pathname}`,
                              location.pathname
                            ) && styles.active
                          }`
                        }
                      />
                    </li>
                  ) : (
                    <li>
                      <p
                        onClick={
                          isResponsiveMenuActive
                            ? () => updateResponsiveTabIsOpen(index)
                            : undefined
                        }
                      >
                        {item.title}
                      </p>

                      <HeaderDropdown
                        item={item}
                        isReponsiveTabOpen={openResponsiveTab[index]}
                      />
                      <div className={styles.underline} />
                      {/* for css purposes */}
                    </li>
                  )}
                </div>
              );
            })}
          </ul>
        )}

        <div className={styles.HeaderNavigation__ContactUs}>
          <div className={styles.HeaderNavigation__CallUs}>
            <a href="tel:0183628481">
              <p>01 83 62 84 81</p>
            </a>
          </div>
          <div className={styles.ourHours}>
            <p>Appelez notre expert énergétique</p>
            <p>du lundi au vendredi de 9h00 à 19h,</p>
            <p>(Prix d’un appel local)</p>
          </div>
        </div>

        {userProfileInfo.id && (
          <div
            className={styles.HeaderNavigation__Logout}
            onClick={onClickDisconnectButton}
          >
            <p>Déconnexion</p>
          </div>
        )}
      </nav>
    </div>
  );
};
