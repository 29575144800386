// Format status received from CRM to display the right status to the user
export const formatStatusTabInstallation = (
  statusInstallation?: string | null
): string => {
  switch (statusInstallation) {
    case "A planifier":
      return "À venir";
    case "En cours":
      return "En cours";
    case "Planifiée":
      return "Planifiée";
    case "Dossier à régulariser":
      return "À venir";
    case "A commander":
      return "À planifier";
    case "Commande envoyée":
      return "À planifier";
    case "Terminé":
      return "Réalisée";
    default:
      return "À venir";
  }
};
