// Function that converts bytes into KB, MB, GB etc.. (depending on the file size)

// Parameters
// ----------
// bytes: number
//     The size of the file in bytes
// 
// decimals: number
//      By default, decimals = 2. For readability, do not change it. 
// 
// Returns
// ----------
// String
//     The converted bytes.

export const formatBytes = (bytes: number, decimals = 2): string => {
  if (bytes === 0) return "0 Bytes";

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
};
