// Libraries import
import { useState } from "react";
import { useAxiosPost } from "../../services/useAxiosPost";
import PDFMerger from "pdf-merger-js/browser";

// Service imports
import { complexRawBase64 } from "./complexRawBase64";
import { useAppSelector } from "../../redux/store/hook";

// Interfaces import
import {
  IUploadFileData,
  IFileUploadAPIResponse,
} from "../../interfaces/interfaces";

// Interface delcaration
interface IFunctionReturn {
  uploadDocument: (
    uploadedFile: IUploadFileData[],
    fileName: string,
    nextWhenSuccessfull: () => void
  ) => Promise<void>;
  isLoading: boolean;
  errorMessage: string;
  setErrorMessage: React.Dispatch<React.SetStateAction<string>>;
  isUploadSuccessfull: boolean;
}

// useUploadDocuments : return tools to send files to the Sharepoint and handle error messages
// Parameters
// ----------
// RAS
//
// Returns
// ----------
// Function uploadDocuments
//     Function to call to upload file
// Boolean isLoading
//     Indicate if the asynchronous request is onGoing
// String errorMessage
//     Error message
// isUploadSuccessfull : boolean
//     Indicate if all the files have been successfully sent

export const useUploadDocument = (): IFunctionReturn => {
  const {
    axiosPostRequest,
    isLoading,
    errorMessage: axiosErrorMessage,
  } = useAxiosPost();
  const BACKEND_URL = process.env.REACT_APP_API_AUTHENTICATION_URL;
  const userId = useAppSelector((states) => states.userAuthInfo.userId);
  const userToken = useAppSelector((states) => states.userAuthInfo.userToken);
  const [isUploadSuccessfull, setIsUploadSuccessfull] =
    useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");

  // uploadDocuments : upload list of files to SharePoint and handle error messages
  // Parameters
  // ----------
  // uploadedFiles: IUploadFileData[]
  //      list of files to upload
  // setUploadedFiles: Function
  //      Function to upload the state containing the files to upload
  // ----------
  // Promise<void>

  const uploadDocument = async (
    uploadedFiles: IUploadFileData[],
    fileName: string,
    nextWhenSuccessfull: () => void
  ): Promise<void> => {
    setErrorMessage("");
    setIsUploadSuccessfull(false);
    if (uploadedFiles?.length > 0) {
      // We merged all files in one pdf
      const merger = new PDFMerger();

      for (const file of uploadedFiles) {
        file.fileContentBlob && (await merger.add(file.fileContentBlob));
      }

      const mergedPdf = await merger.saveAsBlob();

      const reader = new FileReader();
      reader.readAsDataURL(mergedPdf);
      reader.onload = async (event) => {
        const formatedFile: IUploadFileData = {
          userId,
          fileName: fileName,
          fileType: mergedPdf.type,
          fileSize: mergedPdf.size,
          fileContentBase64: event?.target?.result || "",
        };
        const filesInComplexRawBase64 = complexRawBase64(formatedFile);
        const body = [
          {
            complexRawBase64: filesInComplexRawBase64,
          },
        ];

        const response = await axiosPostRequest<IFileUploadAPIResponse>(
          `${BACKEND_URL}/api/User/${userId}/saveDocumentsToSharePoint`,
          body,
          { headers: { Authorization: `Bearer ${userToken}` } }
        );

        if (response && response.status === 200) {
          setIsUploadSuccessfull(true);
          nextWhenSuccessfull();
        } else {
          setErrorMessage("Erreur lors de l'upload du fichier");
        }
      };
    } else {
      setErrorMessage("Merci d'insérer au moins un fichier avant de valider");
    }
  };

  return {
    uploadDocument,
    isLoading,
    errorMessage: axiosErrorMessage ? axiosErrorMessage : errorMessage,
    setErrorMessage,
    isUploadSuccessfull,
  };
};
