import React, { ReactElement } from "react";
import "./SubHeader.scss";

interface Props {
  title: string;
}

export const SubHeader: React.FC<Props> = ({ title }): ReactElement => {
  return (
    <div className="sub-header-container">
      <h1 className="sub-header-title" data-testid="subHeader-title">
        {title}
      </h1>
    </div>
  );
};
