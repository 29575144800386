import "./uploadDocumentsContent.scss";

// Libraries imports
import React, { ReactElement, useEffect, useState } from "react";

//Component imports
import { DocumentToSend } from "../DocumentToSend/DocumentToSend";
import { DocumentNotCompliant } from "../DocumentNotCompliant/DocumentNoCompliant";
import { SingleFileDisplay } from "../../../components/SingleFileDisplay/SingleFileDisplay";
import { ModalContainer } from "../../../containers/Modal/Modal";
import { ModalUploadDocumentsContent } from "../ModalUploadDocumentsContent/ModalUploadDocumentsContent";
import { NoDocument } from "../NoDocument/NoDocument";
import { LoadingComponent } from "@web/shared/dist/components/Old/LoadingComponent/LoadingComponent";
import { MainButton } from "@web/shared/dist/components/Old/MainButton/MainButton";

// Service imports
import { useModal } from "../../../services/useModal";
import { useAppSelector } from "../../../redux/store/hook";
import { useGetUploadDocumentsInfo } from "../../../services/uploadDocuments/useGetUploadDocumentsInfo";
import { useGetDownloadDocumentsInfo } from "../../../services/uploadDocuments/useGetDownloadDocumentsInfo";

// Interfaces import
import { IuploadDocumentsInformation } from "../../../interfaces/interfaces";

export const UploadDocumentsContent: React.FC = (): ReactElement => {
  // Hooks calls
  const { userAuthInfo, uploadDocumentsInfo, downloadDocumentsInfo } =
    useAppSelector((states) => states);
  const {
    requestDownloadDocumentsInfo,
    isLoading: downloadDocumentsInfoIsLoading,
  } = useGetDownloadDocumentsInfo();
  const {
    requestUploadDocumentsInfo,
    isLoading: uploadDocumentsInfoIsLoading,
  } = useGetUploadDocumentsInfo();
  const [clickedDocument, setClickedDocument] =
    useState<IuploadDocumentsInformation | null>(null);

  const {
    toggleModal: toggleModalUploadDocument,
    isModalVisible: isModalUploadDocumentVisible,
  } = useModal(); // Modal setup : Custom hook that handles the display of the modal on the screen.
  const {
    toggleModal: toggleModalAddADocument,
    isModalVisible: isModalAddADocumentVisible,
  } = useModal();

  useEffect(() => {
    if (uploadDocumentsInfo.length === 0) {
      requestUploadDocumentsInfo(userAuthInfo.userToken, userAuthInfo.userId);
    }
    if (downloadDocumentsInfo.length === 0) {
      requestDownloadDocumentsInfo(userAuthInfo.userToken, userAuthInfo.userId);
    }
  }, []);

  // event handler
  // The function open the modal. It takes the clicked document as an argument. => this way, we know on which document we clicked when the modal is opened.
  const handleClickOnUploadDocument = (
    clickedDoc: IuploadDocumentsInformation
  ): void => {
    setClickedDocument(clickedDoc);
    toggleModalUploadDocument();
  };

  // List of documents not yet uploaded by the user
  const notUploadedDocuments = uploadDocumentsInfo
    .filter((singleDocument) => {
      return (
        singleDocument.status?.toLocaleLowerCase() === "absent" ||
        singleDocument.status?.toLocaleLowerCase() === "non conforme"
      );
    })
    .sort((singleDocumentA, singleDocumentB) => {
      if (
        singleDocumentA.status &&
        singleDocumentB.status &&
        singleDocumentA.status > singleDocumentB.status
      ) {
        return 1;
      }
      return -1;
    })
    .map((singleDocument, index) => {
      if (singleDocument.status?.toLocaleLowerCase() === "non conforme") {
        return (
          <DocumentNotCompliant
            document={singleDocument}
            key={index}
            onClickUploadDocument={handleClickOnUploadDocument}
          />
        );
      }
      return (
        <DocumentToSend
          document={singleDocument}
          key={index}
          onClickUploadDocument={handleClickOnUploadDocument}
        />
      );
    });

  // List of documents already uploded by the user
  const uploadedDocuments = uploadDocumentsInfo
    .filter((singleDocument) => {
      return (
        singleDocument.status?.toLocaleLowerCase() !== "absent" &&
        singleDocument.status?.toLocaleLowerCase() !== "non conforme"
      );
    })
    .map((singleDocument, index) => {
      return (
        <SingleFileDisplay fileName={singleDocument.fileName} key={index} />
      );
    })
    .reverse();

  const downloadedDocuments = downloadDocumentsInfo
    .map((singleDocument, index) => {
      return (
        <SingleFileDisplay
          fileName={singleDocument.fileTag}
          key={index}
          fileLink={singleDocument.downloadLink}
        />
      );
    })
    .reverse();

  return (
    <div className="uploadDocumentContent">
      {uploadDocumentsInfoIsLoading || downloadDocumentsInfoIsLoading ? (
        <div className="uploadDocumentContent__LoadingComponent">
          {" "}
          <LoadingComponent diameter={60} />
        </div>
      ) : (
        <div className="uploadDocumentContent__ListContainer">
          <h2
            className="uploadDocumentContent__ToSendTitle"
            data-count={notUploadedDocuments.length}
          >
            Documents à transmettre
          </h2>
          {notUploadedDocuments.length === 0 ? (
            <NoDocument text="Vous pourrez bientôt nous transmettre ici tous les documents nécessaires pour votre projet." />
          ) : (
            <div className="uploadDocumentContent__NotUploadedDocuments">
              {notUploadedDocuments}
            </div>
          )}
          <div className="uploadDocumentContent__AddDocumentButton">
            <MainButton
              name="+ Ajouter un autre document"
              onClick={toggleModalAddADocument}
            />
          </div>
          <h2
            className="uploadDocumentContent__SentTitle"
            data-count={uploadedDocuments.length}
          >
            Documents transmis
          </h2>
          {uploadedDocuments.length === 0 ? (
            <NoDocument text="Vous pourrez bientôt retrouvez ici tous vos documents personnels transmis." />
          ) : (
            <div className="uploadDocumentContent__UploadedDocuments">
              {uploadedDocuments}
            </div>
          )}
          <h2
            className="uploadDocumentContent__SentTitle"
            data-count={downloadDocumentsInfo.length}
            style={{ marginTop: "30px" }}
          >
            Document(s) reçu(s)
          </h2>
          {downloadDocumentsInfo.length === 0 ? (
            <NoDocument text="Vous pourrez bientôt retrouvez ici tous vos documents reçus." />
          ) : (
            <div className="uploadDocumentContent__UploadedDocuments">
              {downloadedDocuments}
            </div>
          )}
        </div>
      )}
      {isModalUploadDocumentVisible && (
        <ModalContainer onClick={toggleModalUploadDocument}>
          <ModalUploadDocumentsContent
            toggleModal={toggleModalUploadDocument}
            clickedDocument={clickedDocument}
            getUploadedDocumentInfo={() =>
              requestUploadDocumentsInfo(
                userAuthInfo.userToken,
                userAuthInfo.userId
              )
            }
          />
        </ModalContainer>
      )}
      {isModalAddADocumentVisible && (
        <ModalContainer onClick={toggleModalAddADocument}>
          <ModalUploadDocumentsContent
            toggleModal={toggleModalAddADocument}
            clickedDocument={null}
            getUploadedDocumentInfo={() =>
              requestUploadDocumentsInfo(
                userAuthInfo.userToken,
                userAuthInfo.userId
              )
            }
          />
        </ModalContainer>
      )}
    </div>
  );
};
