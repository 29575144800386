import "./App.scss";

// Libraries import
import React from "react";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faEyeSlash,
  faEye,
  faSortDown,
  faSortUp,
} from "@fortawesome/free-solid-svg-icons";
library.add(faEyeSlash, faEye, faSortDown, faSortUp);

// Component import
import { Navigation } from "./navigation/Navigation";

const App: React.FC = () => {
  return (
    <div className="App">
      <Navigation />
    </div>
  );
};

export default App;
