import { useState } from "react";
import { IUserAllProjectsData } from "../../interfaces/interfaces";
import {
  setNumberDocumentstoUpload,
  setUserAllProjectsAction,
} from "../../redux/appActions";
import { useAppDispatch } from "../../redux/store/hook";
import { useAxiosGet } from "../useAxiosGet";

interface IFunctionReturn {
  requestAllClientProjects: (
    userToken: string | null,
    userId: string | null
  ) => Promise<void>;
  isLoading: boolean;
  errorMessage: string;
}

export const useGetAllClientProjects = (): IFunctionReturn => {
  const BACKEND_URL = process.env.REACT_APP_API_AUTHENTICATION_URL;
  const {
    axiosGetRequest,
    isLoading,
    errorMessage: axiosErrorMessage,
  } = useAxiosGet();
  const dispatch = useAppDispatch();
  const [errorMessage, setErrorMessage] = useState<string>("");

  const requestAllClientProjects = async (
    userToken: string | null,
    userId: string | null
  ): Promise<void> => {
    setErrorMessage("");

    const response = await axiosGetRequest<IUserAllProjectsData>(
      `${BACKEND_URL}/api/User/${userId}/GetCommandes`,
      {
        headers: {
          authorization: `Bearer ${userToken}`,
        },
      }
    );

    if (response && response.data) {
      const userAllProjectsData = response.data;
      userAllProjectsData &&
        dispatch(
          setUserAllProjectsAction({
            userAllProjects: userAllProjectsData.commandes,
          })
        );

      userAllProjectsData &&
        dispatch(
          setNumberDocumentstoUpload({
            nbdocumentstoUpload: userAllProjectsData.nbdocumentstoUpload,
          })
        );
    }
  };

  return {
    requestAllClientProjects,
    isLoading,
    errorMessage: errorMessage ? errorMessage : axiosErrorMessage,
  };
};
