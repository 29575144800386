/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck

import "./maCommandeHero.scss";
// Libraries imports
import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";

// Components import
import { MainButton } from "@web/shared/dist/components/Old/MainButton/MainButton";
import { ButtonGoBack } from "../../../components/ButonGoBack/ButtonGoBack";

// Local interfaces declaration
interface iHeading {
  id: string;
  title: string;
}

interface propsHeadings {
  headings: iHeading[];
  activeId: string | undefined;
}

// Local interfaces declaration
interface Props {
  orderDate?: string;
  commandId?: string | null;
}

export const MaCommandeHero: React.FC<Props> = ({ orderDate, commandId }) => {
  const navigate = useNavigate();

  /* Function to clear all properties from all h2 to keep only innerText and id
   * ----------
   * h2 whith all properties array
   * ----------
   * h2 array with innerText and id key
   */
  const getHeadings = (headingElements: HTMLHeadingElement[]) => {
    const headings: iHeading[] = [];

    headingElements.forEach((heading: HTMLHeadingElement) => {
      const { innerText: title, id } = heading;
      headings.push({ id, title });
    });

    return headings;
  };

  /* Function that return an object array of all titles h2 with all properties releated to h2
   * ----------
   *
   * ----------
   * object array
   */
  const useHeadingsData = () => {
    const [heading, setdHeadings] = useState<iHeading[]>([]);

    useEffect(() => {
      const headingElements = Array.from(document.querySelectorAll("h2"));

      const newHeadings: iHeading[] = getHeadings(headingElements);

      setdHeadings(newHeadings);
    }, []);

    return {
      heading,
    };
  };

  /* function to set h2 visibility */
  const useIntersectionObserver = (
    setActiveId: React.Dispatch<React.SetStateAction<string | undefined>>
  ) => {
    const headingElementsRef = useRef({});

    useEffect(() => {
      const callback = (headings: IntersectionObserverEntry[]) => {
        /* save the propreties of the visible headings in useRef */
        headingElementsRef.current = headings.reduce((map, headingElement) => {
          map[headingElement.target.id] = headingElement;
          return map;
        }, headingElementsRef.current);

        /* create array whit all visible title on page*/
        const visibleHeadings = [];

        Object.keys(headingElementsRef.current).forEach((key) => {
          const headingElement = headingElementsRef.current[key];
          if (headingElement.isIntersecting)
            visibleHeadings.push(headingElement);
        });

        const getIndexFromId = (id: string) => {
          headingElements.findIndex((heading) => heading.id === id);
        };

        /* set setActiveId whit the upper title of all title visible on page*/
        if (visibleHeadings.length === 1) {
          setActiveId(visibleHeadings?.[0]?.target?.id);
        } else if (visibleHeadings.length > 1) {
          const sortedVisibleHeadings = visibleHeadings.sort(
            (a, b) => getIndexFromId(a.target.id) > getIndexFromId(b.target.id)
          );
          setActiveId(sortedVisibleHeadings[0].target.id);
        }
      };

      const observer = new IntersectionObserver(callback, {
        rootMargin: `0px 0px -20% 0px`,
      });

      const headingElements = Array.from(document.querySelectorAll("h2"));
      headingElements.forEach((element) => observer.observe(element));

      return () => observer.disconnect();
    }, []);
  };

  /* Title h2 list component */
  const Headings = ({ headings, activeId }: propsHeadings) => (
    <ul>
      {headings.map((heading: iHeading, index: number) => (
        <li
          key={index}
          className={`${"maCommandeHero__anchor"} ${
            heading.id === activeId
              ? "maCommandeHero__anchorActive"
              : "maCommandeHero__anchorInactive"
          }`}
        >
          <a href={`#${heading.id}`}>
            <p>
              {heading.id === activeId ? heading.title : "> " + heading.title}
            </p>
          </a>
        </li>
      ))}
    </ul>
  );

  /* Title container component */
  const TableOfContents = () => {
    const { heading } = useHeadingsData();
    const [activeId, setActiveId] = useState<string>();

    useIntersectionObserver(setActiveId);

    return <Headings headings={heading} activeId={activeId} />;
  };

  return (
    <div className="maCommandeHero">
      <div className="maCommandeHero__DynamicMargin" />
      <div className="maCommandeHero__Content">
        <div className="maCommandeHero__Header">
          <div className="maCommandeHero__GoBackButton">
            <ButtonGoBack
              name="Mes projets"
              onClick={() => {
                navigate("/");
                scrollTo({ top: 0 });
              }}
            />
          </div>
          <div className="maCommandeHero__HeaderText">
            <h1>Mon projet {orderDate ? `du ${orderDate}` : ""}</h1>
          </div>

          <nav className="maCommandeHero__MenuDesktop desktopOnly">
            <TableOfContents />
          </nav>
          <div className="maCommandeHero__HeaderButton">
            {
              <MainButton
                name="Suivre mon installation"
                disabled={commandId ? false : true}
                onClick={() => {
                  commandId && navigate(`/mon_projet/${commandId}`);
                  scrollTo({ top: 0 });
                }}
              />
            }
          </div>
        </div>
      </div>
    </div>
  );
};
