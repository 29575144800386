import React from "react";

// export const dataMenuFour: IDataMenu[] = [
export const dataMenuFour = [
  {
    section: "Photovoltaïques",
    data: [
      {
        title: (
          <>
            Panneaux&nbsp;solaires&nbsp;-&nbsp;<strong>Fonctionnement</strong>
          </>
        ),
        href: "https://tucoenergie.fr/guides/fonctionnement-panneau-photovoltaique",
      },
      {
        title: (
          <>
            Panneaux solaires - <strong>Installation</strong>
          </>
        ),
        href: "https://tucoenergie.fr/guides/installation-panneau-solaire",
      },
      {
        title: (
          <>
            Panneaux solaires - <strong>Prix</strong>
          </>
        ),
        href: "https://tucoenergie.fr/guides/prix-panneau-solaire",
      },
    ],
     linkSection: "https://tucoenergie.fr/guides#Photovoltaïque",
  },
  {
    section: "Chauffages",
    data: [
      {
        title: (
          <>
            Pompe&nbsp;à&nbsp;chaleur - <strong>Prix</strong>
          </>
        ),
        href: "https://tucoenergie.fr/guides/prix-pompe-a-chaleur-air-eau",
      },
      {
        title: (
          <>
            Pompe&nbsp;à&nbsp;chaleur&nbsp;-&nbsp;<strong>Fontionnement</strong>
          </>
        ),
        href: "https://tucoenergie.fr/guides/pompe-a-chaleur-air-eau-fonctionnement",
      },
    ],
     linkSection: "https://tucoenergie.fr/guides#Chauffage",
  },
  {
    section: "Isolations",
    data: [
      {
        title: (
          <>
            Isolation par extérieur - <strong>Aides</strong>
          </>
        ),
        href: "https://tucoenergie.fr/guides/aide-etat-isolation-exterieure",
      },
      {
        title: (
          <>
            Isolation par extérieur - <strong>Primes CEE</strong>
          </>
        ),
        href: "https://tucoenergie.fr/guides/cee-isolation-exterieure",
      },
    ],
     linkSection: "https://tucoenergie.fr/guides#Isolation",
  },
];

export const headerNavigationData = [
  {
    title: "Mes projets",
    pathname: "/",
    type: "one",
    navigation: null,
  },
  {
    title: "Mes documents",
    pathname: "/transmettre_mes_documents",
    type: "one",
    navigation: null,
  },
  {
    title: "Guides",
    pathname: "/guides",
    navigation: dataMenuFour,
    type: "two",
    button: {
      title: "Voir tous les guides",
      buttonLink: "/guides",
    },
  },
];
