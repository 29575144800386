// Libraries imports
import { createAction } from "@reduxjs/toolkit";

// Other imports
import * as types from "./constants";

// Interfaces import
import {
  IUserProfileInfo,
  IUserDocument,
  IUserOrder,
  IuploadDocumentsInformation,
  IUserAuthInfo,
  IProjectManagerInfo,
  ICommandInformation,
  IUserAllProjects,
  IChantierInformation,
  IDownloadDocumentsInformation
} from "../interfaces/interfaces";

// Reset redux state
export const setInitalStateAction = createAction(types.SET_INITAL_STATE);

// REDUX ACTIONS TO CALL WHEN REDUX STORE MODIFICATION IS NEEDED

// Save user authentication information in redux
export const setUserAuthInfoAction = createAction<{
  userAuthInfo: IUserAuthInfo;
}>(types.SET_USER_AUTH_INFO);

// Save user profile information in redux
export const setUserProfileInfoAction = createAction<{
  userProfileInfo: IUserProfileInfo;
}>(types.SET_USER_PROFILE_INFO);

// Save project manager information in redux
export const setProjectManagerInfoAction = createAction<{
  projectManagerInfo: IProjectManagerInfo;
}>(types.SET_PROJECT_MANAGER_INFO);

// Save upload document information in redux
export const setUploadDocumentsInfoAction = createAction<{
  uploadDocumentsInfo: IuploadDocumentsInformation[];
}>(types.SET_UPLOAD_DOCUMENTS_INFO);

// Save upload document information in redux
export const setDownloadDocumentsInfoAction = createAction<{
  downloadDocumentsInfo: IDownloadDocumentsInformation[];
}>(types.SET_DOWNLOAD_DOCUMENTS_INFO);

// Save command information in redux store
export const setCommandInformationAction = createAction<{
  commandInformation: ICommandInformation;
}>(types.SET_COMMAND_INFORMATION);

// Save chantier information in redux store
export const setChantierInformationAction = createAction<{
  chantierInformation: IChantierInformation;
}>(types.SET_CHANTIER_INFORMATION);

// Save client's orders in redux store
export const setUserOrdersAction = createAction<{ userOrders: IUserOrder[] }>(
  types.SET_USER_ORDERS
);

// Save user's documents in redux store
export const setUserDocumentsAction = createAction<{
  userDocuments: IUserDocument[];
}>(types.SET_USER_DOCUMENTS);

// Save selected order in redux store
export const setSelectedOrderAction = createAction<{
  orderSelected: IUserOrder;
}>(types.SET_SELECTED_ORDER);

//Save all user projects information in redux store
export const setUserAllProjectsAction = createAction<{
  userAllProjects: IUserAllProjects[];
}>(types.SET_USER_ALL_PROJECTS);

//Save nbdocumentstoUpload in redux store
export const setNumberDocumentstoUpload = createAction<{
  nbdocumentstoUpload: number | null;
}>(types.SET_USER_NUMBER_DOCUMENT_TO_UPLOAD);
