import "./registerForm.scss";

// Libraries import
import React, { useState, KeyboardEvent } from "react";
import { useLocation } from "react-router-dom";

// Components import
import { ButtonConnect } from "../../../components/ButtonConnect/ButtonConnect";
import { PasswordInput } from "@web/shared/dist/components/Old/PasswordInput/PasswordInput";
import { ErrorMessage } from "@web/shared/dist/components/Old/ErrorMessage/ErrorMessage";
import { LoadingComponent } from "@web/shared/dist/components/Old/LoadingComponent/LoadingComponent";

// Services import
import { useRegisterUser } from "../../../services/register/useRegisterUser";
import { useIsUserRegistrationInputValid } from "../../../services/register/useIsUserRegistrationInputValid";

export const RegisterForm: React.FC = () => {
  // Custom hooks call
  const {
    requestRegistration,
    isLoading,
    errorMessage: requestErrorMessage,
  } = useRegisterUser();
  const { isUserRegistrationInputValid, errorMessage: inputsErrorMessage } =
    useIsUserRegistrationInputValid();

  // Retrieve data from URL
  const search = useLocation().search;
  const userEmail = new URLSearchParams(search).get("email");
  const invitationCode = new URLSearchParams(search).get("invitationCode");

  // States initialization
  const [userPassword, setUserPassword] = useState<string>("");
  const [confirmPassword, setConfirmPassword] = useState<string>("");

  // Events handlers
  const onUserPasswordInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setUserPassword(event.target.value);
  };

  const onConfirmPasswordInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setConfirmPassword(event.target.value);
  };

  // Ckeck user inputs and send registration request when clicking on register button. Debounce function : max 1 call every 350ms.
  const onClickConnectButton =
    // If user inputs are valid we call registration request.
    async () => {
      isUserRegistrationInputValid(
        invitationCode,
        userPassword,
        confirmPassword
      ) &&
        invitationCode &&
        userEmail &&
        (await requestRegistration(invitationCode, userPassword, userEmail));
    };

  // Check user inputs and send registration request when when pressing on enter key. Debounce function : max 1 call every 350ms.
  const handleConnectionOnPress =
    // If user inputs are valid we call registration request.
    async (event: KeyboardEvent<HTMLInputElement>) => {
      if (event.code === "Enter") {
        isUserRegistrationInputValid(
          invitationCode,
          userPassword,
          confirmPassword
        ) &&
          invitationCode &&
          userEmail &&
          (await requestRegistration(invitationCode, userPassword, userEmail));
      }
    };

  return (
    <div className="register-form">
      {isLoading ? (
        <div className="loading-container">
          <LoadingComponent />
        </div>
      ) : (
        <>
          <div className="register-notice">
            <p className="register-instruction">
              Vous y êtes presque… Merci de renseigner un mot de passe pour
              finaliser la création de votre compte ! Au moins 8 caractères,
              dont 1 majuscule, 1 minuscule, 1 chiffre et 1 caractère spécial
              (parmi : !@#$%&*?:).
            </p>
          </div>
          <div className="register-inputs">
            <div className="register-user-email-input">
              {userEmail && <p>{userEmail}</p>}
            </div>
            <div className="register-passwordInput">
              <PasswordInput
                value={userPassword}
                onChange={onUserPasswordInputChange}
                onKeyPress={handleConnectionOnPress}
                placeholder="Mot de passe"
              />
            </div>
            <PasswordInput
              value={confirmPassword}
              onChange={onConfirmPasswordInputChange}
              onKeyPress={handleConnectionOnPress}
              placeholder="Confirmez votre mot de passe"
            />
          </div>
          <div className="register-message">
            {(inputsErrorMessage || requestErrorMessage) && (
              <ErrorMessage
                errorMessage={inputsErrorMessage || requestErrorMessage}
              />
            )}
          </div>
          <div className="register-buttons">
            <ButtonConnect name="M'inscrire" onClick={onClickConnectButton} />
          </div>
        </>
      )}
    </div>
  );
};
