import React, { useState } from "react";

// Styles import
import styles from "./payementInfoDetailsBlock.module.scss";

// Components import
import { ProgressBar } from "../../../../components/ProgressBar/ProgressBar";
import { IreglementComptants } from "../../../../interfaces/interfaces";
import { PayementInfoCard } from "./PayementInfoCard/PayementInfoCard";

// Local interface
interface IProgresseBarData {
  montantTotalRecu?: number | null;
  resteACharge?: number | null;
}

interface IProps {
  progresseBarData: IProgresseBarData;
  PayementDetailsData: IreglementComptants[] | null;
}

export const PayementInfoDetailsBlock: React.FC<IProps> = ({
  progresseBarData,
  PayementDetailsData,
}) => {
  const [numberCardToDisplay, setNumberCardToDisplay] = useState(3);

  return (
    <div className={styles.PayementInfoDetailsBlock}>
      <p>
        <strong>Paiement</strong>
        <br></br>
        <br></br>
      </p>
      <ProgressBar
        montantTotalRecu={progresseBarData.montantTotalRecu}
        resteACharge={progresseBarData.resteACharge}
      />
      <div className={styles.PayementInfoDetailsBlock__CardContainer}>
        {PayementDetailsData &&
          PayementDetailsData.length != 0 &&
          PayementDetailsData.slice(0, numberCardToDisplay).map(
            (PayementInfo, index) => {
              return (
                <PayementInfoCard
                  PayementInfo={PayementInfo}
                  index={index}
                  key={index}
                />
              );
            }
          )}
      </div>
      {PayementDetailsData && numberCardToDisplay < PayementDetailsData.length && (
        <div className={styles.PayementInfoDetailsBlock__ButtonContainer}>
          <div
            onClick={() => {
              setNumberCardToDisplay((state) => state + 3);
            }}
          >
            Voir plus
          </div>
        </div>
      )}
    </div>
  );
};
