import facebookPicto from "../assets/images/facebook-picto.svg";
import instagramPicto from "../assets/images/instagram-picto.svg";
import linkedinPicto from "../assets/images/linkedin-picto.svg";
import youtubePicto from "../assets/images/youtube-picto.svg";
import facebookPictoGrey from "../assets/images/facebook-picto-grey.svg";
import instagramPictoGrey from "../assets/images/instagram-picto-grey.svg";
import linkedinPictoGrey from "../assets/images/linkedin-picto-grey.svg";
import youtubePictoGrey from "../assets/images/youtube-picto-grey.svg";

// Interfaces import
import { IsocialMediasData } from "../interfaces/interfaces";

export const socialMediasData: IsocialMediasData[] = [
  {
    pictoSrc: facebookPicto,
    pictoGreySrc: facebookPictoGrey,
    pictoAlt: "facebook-picto",
    href: "https://www.facebook.com/tucoenergie",
  },
  {
    pictoSrc: instagramPicto,
    pictoGreySrc: instagramPictoGrey,
    pictoAlt: "instagram-picto",
    href: "https://www.instagram.com/tucoenergie/",
  },
  {
    pictoSrc: linkedinPicto,
    pictoGreySrc: linkedinPictoGrey,
    pictoAlt: "linkedin-picto",
    href: "https://www.linkedin.com/company/tucoenergie/",
  },
  {
    pictoSrc: youtubePicto,
    pictoGreySrc: youtubePictoGrey,
    pictoAlt: "youtube-picto",
    href: "https://www.youtube.com/channel/UCp2rNxPuOBNhdtHL0QgrZRQ",
  },
];
