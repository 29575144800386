import "./login.scss";

// Libraries import
import React from "react";

// Components import
import { AuthenticationWindow } from "../../containers/authentication/AuthenticationWindow/AuthenticationWindow";

export const Login: React.FC = () => {
  return (
    <div className="login">
      <AuthenticationWindow />
    </div>
  );
};
