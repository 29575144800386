import "./buttonConnect.scss";

// Libraries import
import React from "react";

// Interfaces declaration
interface Props {
  name?: string;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  disabled?: boolean;
  type?: "button" | "submit" | "reset" | undefined;
}

export const ButtonConnect: React.FC<Props> = ({
  name = "Connexion",
  onClick,
  disabled = false,
  type = "button",
}) => {
  return (
    <button
      className="button-connect"
      data-testid="button-connect"
      onClick={onClick}
      disabled={disabled}
      type={type}
    >
      {name}
    </button>
  );
};
