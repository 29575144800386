import React from "react";

// Services import
import { formatTabDate } from "../formatTabDate";

// Interfaces import
import { IChantierInformation, ITabData } from "../../../interfaces/interfaces";

// formatDataTabConsuel : return various values to set Consuel tab
// Parameters
// ----------
// chantierInformation
//
// Returns
// ----------
// object : consuelData => data about accord marie tab
//     Boolean : isTabActive
//                    to active tab when Demarches Administratives is activated
//     String : notification
//                    to set the notification icon
//     JSX element : message
//                    to set message in Consuel tab
// boolean : isConsuelTabDisplayed
//                    display Consuel tab when Demarches Administratives is activated
//
//

export const formatDataTabConsuel = (
  chantierInformation: IChantierInformation
): ITabData => {
  const statutConsuel = chantierInformation.statutConsuel;

  //Function setConsuelDataFunction
  const consuelData = ((): ITabData => {
    switch (statutConsuel) {
      case "A envoyer": // En cours
        return {
          isTabActive: true,
          notification: "sandglassOrange",
          message: (
            <>
              Nous avons entamé les démarches Consuel pour organiser le contrôle
              de conformité de votre installation.
            </>
          ),
        };
      case "Envoyé": // En cours
        return {
          isTabActive: true,
          notification: "sandglassOrange",
          message: (
            <>
              Nous avons entamé les démarches Consuel pour organiser le contrôle
              de conformité de votre installation.
            </>
          ),
        };
      case "Compléments demandés": // En cours
        return {
          isTabActive: true,
          notification: "sandglassOrange",
          message: (
            <>
              Nous avons entamé les démarches Consuel pour organiser le contrôle
              de conformité de votre installation.
            </>
          ),
        };
      case "Visite à planifier": // À planifier
        return {
          isTabActive: true,
          notification: "sandglassOrange",
          message: <>Tucoenergie vous contactera pour planifier la visite.</>,
        };
      case "Visite planifiée": // Visite planifiée
        return {
          isTabActive: true,
          isCancelMeetingDisplayed: true,
          notification: "sandglassOrange",

          message: (
            <>
              <p>
                La visite du Consuel est planifiée le{" "}
                {formatTabDate(chantierInformation.dateVisiteConsuel, false)}
              </p>
            </>
          ),
        };
      case "Validé": // Obtenu
        return {
          isTabActive: false,
          notification: "validatedBlue",
          message: (
            <>
              La visite du Consuel a été réalisée. Vous avez obtenu votre
              attestation de conformité électrique.
            </>
          ),
        };
      case "Mise en conformité demandée": // En cours
        return {
          isTabActive: true,
          notification: "sandglassOrange",
          message: (
            <>
              Suite à la visite du Consuel, une mise en conformité a été
              demandée. Vous serez contacté par votre installateur dans les plus
              brefs délais.
            </>
          ),
        };
      case "Mise en conformité réalisée": // En cours
        return {
          isTabActive: true,
          notification: "sandglassOrange",
          message: (
            <>
              Suite à la visite du Consuel, une mise en conformité a été
              réalisée.
            </>
          ),
        };
      default: // Aucune information disponible
        return {
          isTabActive: false,
          notification: "",
          message: <>Aucune information disponible.</>,
        };
    }
  })();

  return consuelData;
};
