import "./registerWelcome.scss";

// Libraries import
import React from "react";

export const RegisterWelcome: React.FC = () => {
  return (
    <div className="register-welcome">
      <div className="register-welcome-message">
        <p>Bonjour, bienvenue sur votre portail client TUCO !</p>
      </div>
    </div>
  );
};
