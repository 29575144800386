import "./VisiteTechniqueTabContent.scss";

// Libraries import
import React from "react";

// Services import
import { formatDataTabVisitesTechniques } from "../../../services/ProjectFollowUp/SuiviChantier/formatDataTabVisitesTechniques";
import { notificationDictionary } from "../../../data/notificationDictionary";

// Images import
import HouseSearching from "../../../assets/images/ProjectFollowUp/house_searching.svg";

// Interfaces import
import { IChantierInformation } from "../../../interfaces/interfaces";
import { ModalContainer } from "../../Modal/Modal";
import { useModal } from "../../../services/useModal";
import { ModalRescheduleRDV } from "../ModalRescheduleRDV/ModalRescheduleRDV";

// Local interfaces declaration
interface Props {
  chantierInformation: IChantierInformation;
}

export const VisiteTechniqueTabContent: React.FC<Props> = ({
  chantierInformation,
}) => {
  const { toggleModal, isModalVisible } = useModal(); // Modal setup : Custom hook that handles the display of the modal on the screen.

  const visitesTechniquesFormatedData =
    formatDataTabVisitesTechniques(chantierInformation);

  return (
    <div className="VisiteTechniqueTabContent">
      {visitesTechniquesFormatedData?.length == 0 ? (
        <div className="VisiteTechniqueTabContent__Visite">
          <p>Aucune visite technique n’a été planifiée à date.</p>
        </div>
      ) : (
        visitesTechniquesFormatedData?.map((visiteTechnique, index) => {
          return (
            <div
              className="VisiteTechniqueTabContent__VisiteContainer"
              key={index}
            >
              {!visiteTechnique?.isBlockHided && (
                <div className="VisiteTechniqueTabContent__Visite" key={index}>
                  <div className="VisiteTechniqueTabContent__Picto">
                    {visiteTechnique.notification != "" && (
                      <img
                        src={
                          notificationDictionary[visiteTechnique.notification]
                        }
                        alt="Picto alert"
                      />
                    )}
                  </div>
                  <div className="VisiteTechniqueTabContent__Text">
                    <div className="VisiteTechniqueTabContent__Title">
                      <p>{visiteTechnique.productName}</p>
                    </div>
                    <div className="VisiteTechniqueTabContent__Date">
                      {visiteTechnique.message}
                      {visiteTechnique.isCancelMeetingDisplayed && (
                        <div className="PriseEnMainTabContent__OpenModal">
                          <p
                            onClick={(e) => {
                              e.stopPropagation();
                              toggleModal();
                            }}
                          >
                            Je ne pourrai pas être présent au rdv
                          </p>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              )}
            </div>
          );
        })
      )}
      <div className="VisiteTechniqueTabContent__Image">
        <img
          src={HouseSearching}
          alt="Woman searching an house"
          width={122}
          height={109}
        />
      </div>
      {isModalVisible && (
        <ModalContainer
          onClick={(e) => {
            e.stopPropagation();
            toggleModal();
          }}
        >
          <ModalRescheduleRDV />
        </ModalContainer>
      )}
    </div>
  );
};
