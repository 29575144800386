import "./RaccordementTabContent.scss";

// Libraries import
import React from "react";

// Services import
import { formatDataTabRaccordement } from "../../../services/ProjectFollowUp/DemarchesAdministratives/formatDataTabRaccordement";
import { useModal } from "../../../services/useModal";
import { notificationDictionary } from "../../../data/notificationDictionary";

// Components import
import { ModalContainer } from "../../Modal/Modal";
import { ModalRescheduleRDV } from "../ModalRescheduleRDV/ModalRescheduleRDV";

// Images import
import ImageRaccordement from "../../../assets/images/ProjectFollowUp/image_raccordement.svg";

//interface import
import { IChantierInformation } from "../../../interfaces/interfaces";

//Local interfaces
interface Props {
  chantierInformation: IChantierInformation;
}

export const RaccordementTabContent: React.FC<Props> = ({
  chantierInformation,
}) => {
  const { toggleModal, isModalVisible } = useModal(); // Modal setup : Custom hook that handles the display of the modal on the screen.

  const { message, notification, isCancelMeetingDisplayed, isBlockHided } =
    formatDataTabRaccordement(chantierInformation);

  return (
    <div className="RaccordementTabContent">
      {!isBlockHided && (
        <div className="RaccordementTabContent__Raccordement">
          <div className="RaccordementTabContent__Picto">
            {notification != "" && (
              <img
                src={notificationDictionary[notification]}
                alt="Picto alert"
              />
            )}
          </div>
          <div className="RaccordementTabContent__Text">
            <div className="RaccordementTabContent__Title">
              <p>Demande de raccordement :</p>
            </div>
            <div className="RaccordementTabContent__Message">
              {message}
              {isCancelMeetingDisplayed && (
                <div className="PriseEnMainTabContent__OpenModal">
                  <p
                    onClick={(e) => {
                      e.stopPropagation();
                      toggleModal();
                    }}
                  >
                    Je ne pourrai pas être présent au rdv
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
      <div className="RaccordementTabContent__Image">
        <img
          src={ImageRaccordement}
          alt="Working man"
          width={122}
          height={109}
        />
      </div>
      {isModalVisible && (
        <ModalContainer
          onClick={(e) => {
            e.stopPropagation();
            toggleModal();
          }}
        >
          <ModalRescheduleRDV />
        </ModalContainer>
      )}
    </div>
  );
};
