import { IUploadFileData } from "../../interfaces/interfaces";

// complexRawBase64 : create complex base64 string. Trasnform the base64 into a string that will be accepted by backend
// Parameters
// ----------
// file: the file to upload
//
// ----------
// Returns the string

export const complexRawBase64 = (file: IUploadFileData): string => {
  const extension = file.fileType.substring(file.fileType.lastIndexOf("/") + 1);
  const fileNameWithoutExtension = file.fileName.split(".")[0];

  return file.fileContentBase64
    .toString()
    .concat(":", fileNameWithoutExtension, ".", extension);
};
