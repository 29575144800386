import React, { useState, useEffect } from "react";
import "./ProjectFollowUp.scss";

// Libraries import
import { useNavigate, Link, useParams } from "react-router-dom";

// Images import
import PictoExclamationWhite from "../../assets/images/Pictos/picto_exclamation_white.svg";

// Components import
import { Layout } from "../../components/Layout/Layout";
import { ButtonGoBack } from "../../components/ButonGoBack/ButtonGoBack";
import { ButtonSelectTab } from "../../components/ButtonSelectTab/ButtonSelectTab";
import { ChantierContent } from "../../containers/ProjectFollowUp/ChantierContent/ChantierContent";
import { DemarchesAdministrativesContent } from "../../containers/ProjectFollowUp/DemarchesAdministrativesContent/DemarchesAdministrativesContent";
import { VisiteTechniqueTabContent } from "../../containers/ProjectFollowUp/VisiteTechniqueTabContent/VisiteTechniqueTabContent";
import { InstallationTabContent } from "../../containers/ProjectFollowUp/InstallationTabContent/InstallationTabContent";
import { PriseEnMainTabContent } from "../../containers/ProjectFollowUp/PriseEnMainTabContent/PriseEnMainTabContent";
import { AccordMairieTabContent } from "../../containers/ProjectFollowUp/AccordMairieTabContent/AccordMairieTabContent";
import { RaccordementTabContent } from "../../containers/ProjectFollowUp/RaccordementTabContent/RaccordementTabContent";
import { ConsuelTabContent } from "../../containers/ProjectFollowUp/ConsuelTabContent/ConsuelTabContent";
import { RemboursementAidesTabContent } from "../../containers/ProjectFollowUp/RemboursementAidesTabContent/RemboursementAidesTabContent";
import { LoadingComponent } from "@web/shared/dist/components/Old/LoadingComponent/LoadingComponent";
import { ErrorMessage } from "@web/shared/dist/components/Old/ErrorMessage/ErrorMessage";
import { DemarchesAdministrativesFallbackMessage } from "../../containers/ProjectFollowUp/DemarchesAdministrativesFallbackMessage/DemarchesAdministrativesFallbackMessage";

// Services import
import { useGetSuiviProjet } from "../../services/ProjectFollowUp/useGetSuiviProjet";
import { useAppSelector } from "../../redux/store/hook";
import { formatDataTabRaccordement } from "../../services/ProjectFollowUp/DemarchesAdministratives/formatDataTabRaccordement";
import { formatDataTabConsuel } from "../../services/ProjectFollowUp/DemarchesAdministratives/formatDataTabConsuel";
import { formatDataTabAccordMairie } from "../../services/ProjectFollowUp/DemarchesAdministratives/formatDataTabAccordMairie";
import { formatDataTabAides } from "../../services/ProjectFollowUp/DemarchesAdministratives/formatDataTabAides";
import { formatDataTabVisitesTechniques } from "../../services/ProjectFollowUp/SuiviChantier/formatDataTabVisitesTechniques";
import { formatDataTabInstallation } from "../../services/ProjectFollowUp/SuiviChantier/formatDataTabInstallation";
import { formatDataTabPriseEnMain } from "../../services/ProjectFollowUp/SuiviChantier/formatDataTabPriseEnMain";
import { isAccordMairieTabDisplayed } from "../../services/ProjectFollowUp/DemarchesAdministratives/isAccordMairieTabDisplayed";
import { isAidesTabDisplayed } from "../../services/ProjectFollowUp/DemarchesAdministratives/isAidesTabDisplayed";
import { isConsuelTabDisplayed } from "../../services/ProjectFollowUp/DemarchesAdministratives/isConsuelTabDisplayed";
import { isRaccordementTabDisplayed } from "../../services/ProjectFollowUp/DemarchesAdministratives/isRaccordementTabDisplayed";

// Local interfaces declaration
type MonChantierPageParams = {
  commandId: string;
};

// import { dataTest } from "./dataForTest";

export const ProjectFollowUp: React.FC = () => {
  const navigate = useNavigate();
  const { commandId } = useParams<MonChantierPageParams>();
  const { requestChantierInformation, isLoading, errorMessage } =
    useGetSuiviProjet();
  const { chantierInformation, userAuthInfo, nbdocumentstoUpload } =
    useAppSelector((state) => state);

  // STATES DECLARATION
  const [isChantierTabOpen, setIsChantierTabOpen] = useState<boolean>(true); // Sate that handle the blue buttons tabs
  const [areChantierMobileTabsOpen, setAreChantierMobileTabsOpen] = useState<
    boolean[]
  >([false, false, false]); // State that handle the chantier's sub tabs
  const [areChantierDesktopTabsOpen, setAreChantierDesktopTabsOpen] = useState<
    boolean[]
  >([false, false, false]); // We differenciate mobile tabs'state from desktop's because their behaviour is not the same (ex: in mobile the opening of a tab should not close the other one open)

  const [areAdministrativeMobileTabsOpen, setAreAdministrativeMobileTabsOpen] =
    useState<boolean[]>([false, false, false, false]); // State that handle the administrative's sub tabs

  const [
    areAdministrativeDesktopTabsOpen,
    setAreAdministrativeDesktopTabsOpen,
  ] = useState<boolean[]>([false, false, false, false]); // Idem we need to differenciate desktop state from mobile state

  // Event handlers : update tab's states when click on a tab. Behaviour between mobile and desktop is not the same
  const onClickOnChantierTab = (index: number) => {
    setAreChantierMobileTabsOpen((prevState) => {
      const newState = [...prevState];
      newState[index] = !prevState[index];
      return newState;
    });
    setAreChantierDesktopTabsOpen((prevState) => {
      const newState = prevState.map(() => false);
      newState[index] = !prevState[index];
      return newState;
    });
  };

  const onClickOnAdministrativeTab = (index: number) => {
    setAreAdministrativeMobileTabsOpen((prevState) => {
      const newState = [...prevState];
      newState[index] = !prevState[index];
      return newState;
    });
    setAreAdministrativeDesktopTabsOpen((prevState) => {
      const newState = prevState.map(() => false);
      newState[index] = !prevState[index];
      return newState;
    });
  };

  useEffect(() => {
    if (commandId) {
      if (commandId !== chantierInformation?.commandId) {
        // If we don't have yet information linked to the commandId we request it
        requestChantierInformation(userAuthInfo.userToken, commandId);
      }
    } else {
      navigate("/"); // If we don't have the commandId and the chantierId we can't load the data -> redirection to landing page to get them
    }
  }, []);

  useEffect(() => {
    setAreChantierDesktopTabsOpen((prevState) => {
      if (
        formatDataTabVisitesTechniques(chantierInformation)?.find(
          (visiteTechnique) => visiteTechnique.isTabActive
        )?.isTabActive
      ) {
        return [true, false, false];
      }
      if (formatDataTabInstallation(chantierInformation).isTabActive) {
        return [false, true, false];
      }
      if (formatDataTabPriseEnMain(chantierInformation).isTabActive) {
        return [false, false, true];
      }
      return prevState;
    });

    setAreAdministrativeDesktopTabsOpen((prevState) => {
      if (
        isAccordMairieTabDisplayed(chantierInformation) &&
        formatDataTabAccordMairie(chantierInformation).isTabActive
      ) {
        return [true, false, false, false];
      }
      if (
        isConsuelTabDisplayed(chantierInformation) &&
        formatDataTabConsuel(chantierInformation).isTabActive
      ) {
        return [false, true, false, false];
      }
      if (
        isRaccordementTabDisplayed(chantierInformation) &&
        formatDataTabRaccordement(chantierInformation).isTabActive
      ) {
        return [false, false, true, false];
      }
      if (
        isAidesTabDisplayed(chantierInformation) &&
        formatDataTabAides(chantierInformation).isTabActive
      ) {
        return [false, false, false, true];
      }
      return prevState;
    });
  }, [chantierInformation.commandId]);

  const isDemarcheAdministrativeFallbackMessageDisplayed =
    !isAccordMairieTabDisplayed(chantierInformation) &&
    !isRaccordementTabDisplayed(chantierInformation) &&
    !isConsuelTabDisplayed(chantierInformation) &&
    !isAidesTabDisplayed(chantierInformation); // If no tab are displayed => we display a fallback message

  return (
    <div className="ProjectFollowUp">
      {nbdocumentstoUpload && nbdocumentstoUpload > 0 && (
        <div className="ProjectFollowUp__Banner">
          <div className="ProjectFollowUp__BannerMessage">
            <img
              src={PictoExclamationWhite}
              alt="Picto exclamation point white"
            />
            <p>{`Il manque ${
              nbdocumentstoUpload || "des"
            } document(s) pour compléter votre dossier`}</p>
          </div>
          <div className="ProjectFollowUp__BannerLink">
            <Link to="/transmettre_mes_documents">
              Transmettre vos documents
            </Link>
          </div>
        </div>
      )}
      <div className="ProjectFollowUp__TabArea">
        <Layout>
          <div className="ProjectFollowUp__Container">
            <div className="ProjectFollowUp__SubHeader">
              <div className="ProjectFollowUp__GoBackButton">
                <ButtonGoBack
                  name="Mes projets"
                  onClick={() => navigate("/")}
                />
              </div>
              <div className="ProjectFollowUp__SubHeaderMessage">
                <p>Suivez simplement l’état d’avancement de votre projet !</p>
              </div>
            </div>
            <div className="ProjectFollowUp__Tabs">
              <div className="ProjectFollowUp__Tab">
                <ButtonSelectTab
                  name="Chantier"
                  isActive={isChantierTabOpen}
                  onClick={() => {
                    setIsChantierTabOpen((state) => !state);
                  }}
                />
              </div>
              <div className="ProjectFollowUp__Tab">
                <ButtonSelectTab
                  name="Démarches administratives"
                  isActive={!isChantierTabOpen}
                  onClick={() => {
                    setIsChantierTabOpen((state) => !state);
                  }}
                />
              </div>
            </div>

            {isLoading ? (
              <div className="ProjectFollowUp__LoadingComponent">
                <LoadingComponent />
              </div>
            ) : errorMessage ? (
              <div className="ProjectFollowUp__ErrorMessage">
                <ErrorMessage errorMessage={errorMessage} />
              </div>
            ) : (
              <div className="ProjectFollowUp__Content">
                {isChantierTabOpen ? (
                  <ChantierContent
                    areChantierMobileTabsOpen={areChantierMobileTabsOpen}
                    areChantierDesktopTabsOpen={areChantierDesktopTabsOpen}
                    onClickOnChantierTab={onClickOnChantierTab}
                    chantierInformation={chantierInformation}
                  />
                ) : (
                  <DemarchesAdministrativesContent
                    areAdministrativeMobileTabsOpen={
                      areAdministrativeMobileTabsOpen
                    }
                    areAdministrativeDesktopTabsOpen={
                      areAdministrativeDesktopTabsOpen
                    }
                    onClickOnAdministrativeTab={onClickOnAdministrativeTab}
                    chantierInformation={chantierInformation}
                    isDemarcheAdministrativeFallbackMessageDisplayed={
                      isDemarcheAdministrativeFallbackMessageDisplayed
                    }
                  />
                )}
              </div>
            )}
          </div>
        </Layout>
      </div>

      <div className="ProjectFollowUp__TabContentDesktop desktopOnly">
        {/* Content display only in desktop with conditionnal rendering according to which tab or subtab is active */}
        <Layout>
          {isLoading ? (
            "" // No loading component to not duplicate loading spinner (cf. above)
          ) : errorMessage ? (
            <ErrorMessage errorMessage={""} /> // Error message empty to not duplicate error message (cf. above)
          ) : (
            <div className="ProjectFollowUp__TabsContainer">
              {isChantierTabOpen && (
                <div className="ProjectFollowUp__SuiviChantierTabsContent">
                  {areChantierDesktopTabsOpen[0] && (
                    <VisiteTechniqueTabContent
                      chantierInformation={chantierInformation}
                    />
                  )}
                  {areChantierDesktopTabsOpen[1] && (
                    <InstallationTabContent
                      chantierInformation={chantierInformation}
                    />
                  )}
                  {areChantierDesktopTabsOpen[2] && (
                    <PriseEnMainTabContent
                      chantierInformation={chantierInformation}
                    />
                  )}
                </div>
              )}
              {!isChantierTabOpen &&
                (isDemarcheAdministrativeFallbackMessageDisplayed ? (
                  <DemarchesAdministrativesFallbackMessage />
                ) : (
                  <div className="ProjectFollowUp__SuiviAdministratifTabsContent">
                    {areAdministrativeDesktopTabsOpen[0] && (
                      <AccordMairieTabContent
                        chantierInformation={chantierInformation}
                      />
                    )}
                    {areAdministrativeDesktopTabsOpen[1] && (
                      <ConsuelTabContent
                        chantierInformation={chantierInformation}
                      />
                    )}
                    {areAdministrativeDesktopTabsOpen[2] && (
                      <RaccordementTabContent
                        chantierInformation={chantierInformation}
                      />
                    )}
                    {areAdministrativeDesktopTabsOpen[3] && (
                      <RemboursementAidesTabContent
                        chantierInformation={chantierInformation}
                      />
                    )}
                  </div>
                ))}
            </div>
          )}
        </Layout>
      </div>
    </div>
  );
};
