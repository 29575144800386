import "./paymentInfoBlock.scss";

// Libraries import
import React from "react";

// Components import
import { PayementInfoDetailsBlock } from "./PayementInfoDetailsBlock/PayementInfoDetailsBlock";
import {
  IreglementComptants,
  IreglementFinancements,
} from "../../../interfaces/interfaces";

// Local interface declaration
interface Props {
  montantCommande: number | null;
  codePromo: string | null;
  montantRemise: number | null;
  montantTotalRecu: number | null;
  aides:
    | null
    | {
        name: string | null;
        montant: number | null;
        isDeducted: boolean; // If true : aides déduites, if false : aides non déduites
      }[];
  reglementComptants: IreglementComptants[] | null;
  reglementFinancements: IreglementFinancements[] | null;
}

export const PaymentInfoBlock: React.FC<Props> = ({
  montantCommande,
  montantRemise,
  aides,
  montantTotalRecu,
  reglementComptants,
  reglementFinancements,
}) => {
  // TEMPORARY WAITING FOR BACKEND UPDATE
  const resteACharge = montantCommande
    ? montantCommande -
      (aides?.reduce(
        (acc, currentValue) =>
          acc + ((currentValue.isDeducted && currentValue?.montant) || 0),
        0
      ) || 0)
    : null;

  const coutProjet = (montantCommande || 0) + (montantRemise || 0);

  // TO PUT BACK AFTER BACKEND UPDATE
  // const resteACharge = montantCommande
  //   ? montantCommande -
  //     (montantRemise || 0) -
  //     (aides?.reduce(
  //       (acc, currentValue) =>
  //         acc + ((currentValue.isDeducted && currentValue?.montant) || 0),
  //       0
  //     ) || 0)
  //   : null;

  return (
    <div className="paymentInfoBlock">
      <div className="paymentInfoBlock__MainContainer">
        <div className="paymentInfoBlock__PriceInfo">
          <div className="paymentInfoBlock__PriceInfoLine">
            <p>Coût du projet</p>
            <p>
              {coutProjet
                ? new Intl.NumberFormat("fr-FR").format(coutProjet) + " €"
                : "Montant non disponible"}
            </p>
          </div>
          {aides?.map((aide, index) => {
            return (
              <div className="paymentInfoBlock__PriceInfoLine" key={index}>
                <p>{aide.name}</p>
                {aide.isDeducted ? (
                  <p>
                    {aide.montant
                      ? "- " +
                        new Intl.NumberFormat("fr-FR").format(aide.montant) +
                        " €"
                      : "Montant non disponible"}
                  </p>
                ) : (
                  <p>
                    {aide.montant ? (
                      <>
                        {new Intl.NumberFormat("fr-FR").format(aide.montant) +
                          " € "}{" "}
                        <span>(non déduite)</span>
                      </>
                    ) : (
                      "Montant non disponible"
                    )}
                  </p>
                )}
              </div>
            );
          })}
          {montantRemise ? (
            <div className="paymentInfoBlock__PriceInfoLine">
              <p>Remise TUCO</p>
              <p>
                {"- " +
                  new Intl.NumberFormat("fr-FR").format(montantRemise) +
                  " €"}
              </p>
            </div>
          ) : null}
          <div className="paymentInfoBlock__LineSeparation" />

          <div className="paymentInfoBlock__PriceInfoLastLine">
            <p>Reste à charge</p>
            <p>
              {resteACharge
                ? new Intl.NumberFormat("fr-FR").format(resteACharge) + " €"
                : "Reste à charge non disponible"}
            </p>
          </div>
        </div>
        <div className="paymentInfoBlock__PaymentInfo">
          <p>
            <strong>Modalités de paiement</strong>
          </p>
          {reglementFinancements && reglementFinancements.length != 0 ? (
            <>
              <p>
                Organisme de financement&nbsp;:{" "}
                {reglementFinancements[0].organismedeFinancement}
              </p>
              <p>
                Durée du financement&nbsp;:{" "}
                {reglementFinancements[0].nbEcheances} mois
              </p>
              <p>
                Mensualités&nbsp;: {reglementFinancements[0].monthlyPayment}€
              </p>
            </>
          ) : (
            <div className="paymentInfoBlock__PaymentInfo">
              <div className="paymentInfoBlock__PaymentInfoModalité">
                <p>
                  Le Client règlera l’intégralité du prix lorsque TUCO lui aura
                  livré les matériels et aura procédé à leurs travaux
                  d’installation.
                </p>
              </div>
            </div>
          )}
        </div>
      </div>

      <PayementInfoDetailsBlock
        PayementDetailsData={reglementComptants}
        progresseBarData={{ montantTotalRecu, resteACharge }}
      />
    </div>
  );
};
