import "./RemboursementAidesTabContent.scss";

// Libraries import
import React from "react";

// Components import
import { RemboursementAidesContentDetail } from "./RemboursementAidesMRPContent/RemboursementAidesContentDetail";

// Services import
import { formatDataTabAides } from "../../../services/ProjectFollowUp/DemarchesAdministratives/formatDataTabAides";

// Images import
import ImageRemboursementAides from "../../../assets/images/ProjectFollowUp/image_remboursement_aides.svg";

// Interfaces import
import { IChantierInformation } from "../../../interfaces/interfaces";

//local Interface
interface Props {
  chantierInformation: IChantierInformation;
}

export const RemboursementAidesTabContent: React.FC<Props> = ({
  chantierInformation,
}) => {
  const { aidesMPRData, aidesCEEData } =
    formatDataTabAides(chantierInformation);

  return (
    <div className="RemboursementAidesTabContent">
      <div className="RemboursementAidesTabContent__CardContainer">
        {!aidesCEEData.isBlockHided && (
          <RemboursementAidesContentDetail
            data={aidesCEEData}
            type="CEE"
            chantierInformation={chantierInformation}
          />
        )}

        {!aidesMPRData.isBlockHided && (
          <RemboursementAidesContentDetail
            data={aidesMPRData}
            type="MPR"
            chantierInformation={chantierInformation}
          />
        )}
        {chantierInformation.primeAutoConso && (
          <RemboursementAidesContentDetail
            type="PrimeAutoConso"
            chantierInformation={chantierInformation}
          />
        )}
      </div>
      <div className="RemboursementAidesTabContent__Image">
        <img
          src={ImageRemboursementAides}
          alt="Wallet"
          width={122}
          height={109}
        />
      </div>
    </div>
  );
};
