import "./userAccount.scss";

// Libraries imports
import React from "react";

// Components imports
import { UserAccountHero } from "../../containers/UserAccountContent/UserAccountHero/UserAccountHero";
import { UserAccountContent } from "../../containers/UserAccountContent/UserAccountContent/UserAccountContent";
import { Layout } from "../../components/Layout/Layout";
import { BannerContainer } from "../../components/BannerContainer/BannerContainer";

//Service import
import { useAppSelector } from "../../redux/store/hook";
import { CodeParrainBlock } from "../../containers/UserAccountContent/CodeParrainBlock/CodeParrainBlock";

export const UserAccount: React.FC = () => {
  const { userProfileInfo } = useAppSelector((states) => states);

  return (
    <div className="MyAccount">
      <div className="MyAccount__BannerMobile mobileOnly">
        <BannerContainer backgroundColor="Blue">
          <UserAccountHero
            userFirstName={userProfileInfo && userProfileInfo.firstname}
          />
        </BannerContainer>
      </div>
      <Layout>
        <div className="MyAccount__Container">
          <div className="MyAccount__BannerDesktop desktopOnly">
            <BannerContainer backgroundColor="Blue">
              <UserAccountHero
                userFirstName={userProfileInfo && userProfileInfo.firstname}
              />
            </BannerContainer>
          </div>
          <div className="MyAccount__Content">
            <div className="MyAccount__DynamicMargin" />
            <UserAccountContent
              userProfileInfo={userProfileInfo && userProfileInfo}
            />
          </div>
          {userProfileInfo.codeParrainage && (
            <div className="MyAccount__ParrainageContainer">
              <div className="MyAccount__DynamicMargin" />
              <CodeParrainBlock codeParrain={userProfileInfo.codeParrainage} />
            </div>
          )}
        </div>
      </Layout>
    </div>
  );
};
