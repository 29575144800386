import "./ButtonSelectTab.scss";

// Libraries import
import React from "react";

// Interfaces declaration
interface Props {
  name?: string;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  isActive?: boolean;
  type?: "button" | "submit" | "reset" | undefined;
  notification?:
    | "sandglassOrange"
    | "sandglassGrey"
    | "exclamationPointOrange"
    | "exclamationPointWhite"
    | "validatedBlue";
}

import PictoSandglassOrange from "../../assets/images/Pictos/picto_sandglass_orange.svg";
import PictoSandglassGrey from "../../assets/images/Pictos/picto_sandglass_grey.svg";
import PictoExclamationPointOrange from "../../assets/images/Pictos/picto_exclamation_orange.svg";
import PictoExclamationPointWhite from "../../assets/images/Pictos/picto_exclamation_white.svg";
import PictoValidatedBlue from "../../assets/images/Pictos/picto_validated_blue.svg";

export const ButtonSelectTab: React.FC<Props> = ({
  name = "Tab",
  onClick,
  isActive = true,
  type = "button",
  notification,
}) => {
  const notificationDictionary = {
    sandglassOrange: PictoSandglassOrange,
    sandglassGrey: PictoSandglassGrey,
    exclamationPointOrange: PictoExclamationPointOrange,
    exclamationPointWhite: PictoExclamationPointWhite,
    validatedBlue: PictoValidatedBlue,
  };

  return (
    <div className={`ButtonSelectTab`}>
      {notification && (
        <div className={`ButtonSelectTab__Notification`}>
          <img
            src={notificationDictionary[notification]}
            alt="Picto notification"
          />
        </div>
      )}
      <button
        className={`ButtonSelectTab__Button ${
          isActive ? "ButtonSelectTab__Active" : ""
        }`}
        onClick={onClick}
        type={type}
      >
        {name}
      </button>
    </div>
  );
};
