// Format status received from CRM to display the right status to the user
export const formatStatusTabConsuel = (
  statusConsuel?: string | null
): string => {
  switch (statusConsuel) {
    case "A envoyer":
      return "En cours";
    case "Envoyé":
      return "En cours";
    case "Compléments demandés":
      return "En cours";
    case "Visite à planifier":
      return "À planifier";
    case "Visite planifiée":
      return "Visite planifiée";
    case "Validé":
      return "Obtenu";
    case "Mise en conformité demandée":
      return "En cours";
    case "Mise en conformité réalisée":
      return "En cours";
    default:
      return "Information non disponible";
  }
};
