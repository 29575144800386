import React from "react";
import { IChantierInformation, ITabData } from "../../../interfaces/interfaces";

// Services import
import { formatTabDate } from "../../../services/ProjectFollowUp/formatTabDate";

// formatDataTabInstallation : return various values to set Raccordement tab
// Parameters
// ----------
// chantierInformation
//
// Returns
// ----------
// object : raccordementData => data about raccordement tab
//     Boolean : isTabActive
//                    to active tab when Demarches Administratives is activated
//     String : notification
//                    to set the notification icon
//     JSX element : message
//                    to set message in Raccordement tab
// boolean : isRaccordementTabDisplayed
//                    display Raccordement tab when Demarches Administratives is activated
//
//

export const formatDataTabInstallation = (
  chantierInformation: IChantierInformation
): ITabData => {
  const { status: statusInstallation, dateInstallation } = chantierInformation;

  //Function setRaccordementDataFunction
  const installationData = ((): ITabData => {
    // The comments at the right of the status are the status displayed to the user
    switch (statusInstallation) {
      case "A planifier": // À planifier
        return {
          isTabActive: true,
          notification: "sandglassOrange",
          message: (
            <>Tucoenergie vous contactera pour planifier l&apos;installation.</>
          ),
        };
      case "Planifiée": // Planifiée
        return {
          isTabActive: true,
          isCancelMeetingDisplayed: true,
          notification: "sandglassOrange",
          message: <p>Planifiée le {formatTabDate(dateInstallation, false)}</p>,
        };
      case "En cours": // En cours
        return {
          isTabActive: true,
          isCancelMeetingDisplayed: true,
          notification: "sandglassOrange",
          message: <>Installation en cours.</>,
        };
      case "Dossier à régulariser": // À planifier
        return {
          isTabActive: true,
          notification: "sandglassOrange",
          message: (
            <p>Tucoenergie vous contactera pour planifier l’installation.</p>
          ),
        };
      case "A commander": // À planifier
        return {
          isTabActive: true,
          notification: "sandglassOrange",
          message: (
            <p>Tucoenergie vous contactera pour planifier l’installation.</p>
          ),
        };
      case "Commande envoyée": // À planifier
        return {
          isTabActive: true,
          notification: "sandglassOrange",
          message: (
            <p>Tucoenergie vous contactera pour planifier l’installation.</p>
          ),
        };
      case "Terminé": // Réalisée
        return {
          isTabActive: false,
          notification: "validatedBlue",
          message: <p>Réalisée le {formatTabDate(dateInstallation, false)}</p>,
        };
      default:
        return {
          isTabActive: false,
          notification: "",
          message: (
            <>Tucoenergie vous contactera pour planifier l&apos;installation.</>
          ),
        };
    }
  })();

  return installationData;
};
