import "./ModalUploadDocumentsContent.scss";

// Libraries imports
import React, { ReactElement, useCallback, useState } from "react";
import { useDropzone, FileWithPath, FileRejection } from "react-dropzone";

// Components Imports
import { MainButton } from "@web/shared/dist/components/Old/MainButton/MainButton";
import { ErrorMessage } from "@web/shared/dist/components/Old/ErrorMessage/ErrorMessage";
import { LoadingComponent } from "@web/shared/dist/components/Old/LoadingComponent/LoadingComponent";
import { UserMessage } from "../../../components/UserMessage/UserMessage";
import { DropZone } from "../../../components/DropZone/DropZone";
import { TextInput } from "@web/shared/dist/components/Old/TextInput/TextInput";

// Service imports
import { useAppSelector } from "../../../redux/store/hook";
import { useUploadDocument } from "../../../services/uploadDocuments/useUploadDocument";
import { fileSizeValidator } from "../../../services/uploadDocuments/fileSizeValidator";
import { UploadFileDisplay } from "../uploadFileListDisplay/UploadFileDisplay";
import { useFormatUploadedFile } from "../../../services/uploadDocuments/useFormatUploadedFile";

// Interface imports
import {
  IuploadDocumentsInformation,
  IUploadFileData,
} from "../../../interfaces/interfaces";

// Local interfaces declaration
interface Props {
  toggleModal: () => void;
  clickedDocument: IuploadDocumentsInformation | null;
  getUploadedDocumentInfo: () => void;
}

export const ModalUploadDocumentsContent = ({
  toggleModal,
  clickedDocument,
  getUploadedDocumentInfo,
}: Props): ReactElement => {
  // Hooks calls
  const userId = useAppSelector((states) => states.userAuthInfo.userId);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [uploadedFiles, setUploadedFiles] = useState<IUploadFileData[]>([]);
  const [AddDocumentFileName, setAddDocumenFileName] = useState<string>("");

  const {
    formatUploadedAcceptedFile,
    formatUploadedRejectedFile,
    errorMessage: formatErrorMessage,
    setErrorMessage: setFormatErrorMessage,
  } = useFormatUploadedFile();

  const {
    uploadDocument,
    isLoading,
    errorMessage: uploadFileErrorMessage,
    setErrorMessage: setUploadFileErrorMEssage,
    isUploadSuccessfull,
  } = useUploadDocument();

  // Event handlers

  // Save files dropped by the user in a state in the right format
  const onDrop = useCallback(
    (acceptedFiles: FileWithPath[], rejectedFiles: FileRejection[]) => {
      // Reset error messages
      setErrorMessage("");
      setFormatErrorMessage("");
      setUploadFileErrorMEssage("");

      // Format accepted uploaded file and save them in state
      acceptedFiles.forEach(async (file) => {
        formatUploadedAcceptedFile(file, userId, setUploadedFiles);
      });

      // Format rejected uploaded file and save them in state
      rejectedFiles.forEach(async (file) => {
        formatUploadedRejectedFile(file, userId, setUploadedFiles);
      });
    },
    []
  );

  // Hook handleling the on drop event (see https://react-dropzone.js.org/)
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    validator: fileSizeValidator,
    accept: ["image/png", "image/jpeg", "application/pdf"],
  });

  // Delete onDropped files
  const onClickRemoveFile = (fileNameToRemove: string) => {
    setUploadedFiles((state) => {
      const newState = [...state].filter(
        (file) => file.fileName !== fileNameToRemove
      );
      return newState;
    });
    // Reset error messages
    setErrorMessage("");
    setFormatErrorMessage("");
    setUploadFileErrorMEssage("");
  };

  // Upload the files that are stored in the state.
  // docType : type of the file updated.
  const onValidateUpload = async () => {
    if (uploadedFiles.length > 0) {
      uploadDocument(
        uploadedFiles,
        clickedDocument?.fileName || AddDocumentFileName || "Document inconnu",
        () => {
          setTimeout(() => {
            // If upload successfull, update document info and close modal
            getUploadedDocumentInfo();
            toggleModal();
          }, 1500);
        }
      );
    }
  };

  return (
    <div
      className="modalUploadDocumentContent"
      data-testid="modal-upload-container"
    >
      <h2 className="modalUploadDocumentContent__DocumentName">
        {clickedDocument?.fileName || "Ajouter un autre document"}
      </h2>
      {!clickedDocument && (
        <div className="modalUploadDocumentContent__AddDocumentInput">
          <p>Nom du document *</p>
          <TextInput
            value={AddDocumentFileName}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              setAddDocumenFileName(event.target.value)
            }
            placeholder="Attestation..."
          />
        </div>
      )}
      <input data-testid="drag-drop-settings" {...getInputProps()} />

      {/* Conditionnal rendering : display uploaded file if it exists */}
      {uploadedFiles.length > 0 ? (
        <div className="modalUploadDocumentContent__UploadedFile">
          <UploadFileDisplay
            filesToUpload={uploadedFiles}
            onClickRemoveFile={onClickRemoveFile}
          />
        </div>
      ) : (
        <div className="modalUploadDocumentContent__EmptySpace" />
      )}

      <DropZone getRootProps={getRootProps} isDragActive={isDragActive} />

      <div className="modalUploadDocumentContent__Bottom">
        {/* Conditionnal rendering : display error message or loading spinner or successfull message or upload button*/}
        {errorMessage || formatErrorMessage || uploadFileErrorMessage ? (
          <ErrorMessage
            errorMessage={
              errorMessage || formatErrorMessage || uploadFileErrorMessage
            }
            className="modalUploadDocumentContent__ErrorMessage"
          />
        ) : isLoading ? (
          <LoadingComponent diameter={50} />
        ) : isUploadSuccessfull ? (
          <UserMessage userMessage="Fichier envoyé !" />
        ) : (
          <MainButton
            onClick={onValidateUpload}
            name="Transmettre mon document"
            dataTestId="send-document-button"
          />
        )}
      </div>
    </div>
  );
};
