// Interface import
import {
  IChantierInformation,
  INotification,
} from "../../../interfaces/interfaces";

// Services import
import { formatDataTabVisitesTechniques } from "./formatDataTabVisitesTechniques";

// Local interfaces declaration
interface IFunctionReturn {
  globalStatusVisitesTechniques: string;
  globalNotificationVisitesTechniques: INotification;
}

// Hook
export const formatStatusTabVisitesTechniques = (
  chantierInformations: IChantierInformation
): IFunctionReturn => {
  const formatVisiteTechniqueStatus = (statusCRM: string) => {
    switch (statusCRM) {
      case "À planifier":
        return "À planifier";
      case "En cours":
        return "Planifiée";
      case "Planifiée":
        return "Planifiée";
      case "Réalisée":
        return "Réalisée";
      case "Annulée":
        return "Annulée";
      case "Non réalisable - à replanifier":
        return "À replanifier";
      default:
        return "Information non disponible";
    }
  };

  const listOfUniqueVisiteTechniqueWithNonNullishStatutVT =
    chantierInformations.visiteTechniqueInfos?.filter(
      (visiteTechnique, index, array) =>
        visiteTechnique?.statutVT &&
        array
          .map((visiteTechnique) => visiteTechnique?.statutVT)
          .indexOf(visiteTechnique?.statutVT) === index
    ); // We keep maximum one visit technique per day

  const globalStatusVisitesTechniques =
    listOfUniqueVisiteTechniqueWithNonNullishStatutVT &&
    listOfUniqueVisiteTechniqueWithNonNullishStatutVT?.length > 1
      ? "En cours"
      : formatVisiteTechniqueStatus(
          listOfUniqueVisiteTechniqueWithNonNullishStatutVT?.[0]?.statutVT ||
            "Information non disponible"
        ); // If more that two different status -> "En cours"

  const globalNotificationVisitesTechniques: INotification =
    listOfUniqueVisiteTechniqueWithNonNullishStatutVT &&
    listOfUniqueVisiteTechniqueWithNonNullishStatutVT?.length > 1
      ? "sandglassOrange"
      : formatDataTabVisitesTechniques(chantierInformations)?.[0]
          ?.notification || "";

  return {
    globalStatusVisitesTechniques,
    globalNotificationVisitesTechniques,
  };
};
