import "./registerWindow.scss";

// Libraries import
import React from "react";

// Components import
import { RegisterWelcome } from "../RegisterWelcome/RegisterWelcome";
import { RegisterForm } from "../RegisterForm/RegisterForm";

export const RegisterWindow: React.FC = () => {
  return (
    <div className="register-window">
      <RegisterWelcome />
      <RegisterForm />
    </div>
  );
};
