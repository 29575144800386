// Interfaces import
import { IChantierInformation } from "../../../interfaces/interfaces";

export const isAccordMairieTabDisplayed = (
  chantierInformation: IChantierInformation
): boolean => {
  return chantierInformation.statutMairie &&
    chantierInformation.statutMairie !== "Non nécessaire"
    ? true
    : false;
};
