import "./authenticationCredentials.scss";

// Libraries import
import React, { useState, KeyboardEvent, ReactElement, useEffect } from "react";
import debounce from "lodash.debounce";
import { useLocation } from "react-router-dom";

// Components import
import { TextInput } from "@web/shared/dist/components/Old/TextInput/TextInput";
import { PasswordInput } from "@web/shared/dist/components/Old/PasswordInput/PasswordInput";
import { ErrorMessage } from "@web/shared/dist/components/Old/ErrorMessage/ErrorMessage";
import { LoadingComponent } from "@web/shared/dist/components/Old/LoadingComponent/LoadingComponent";

// Service import
import { useLoginUser } from "../../../services/login/useLoginUser";
import { useGetUserProfileInfo } from "../../../services/useGetUserProfileInfo";

// Interface import
import { IDataSendFromRegistrationToLogin } from "../../../interfaces/interfaces";

//Other imports
import { useAppSelector } from "../../../redux/store/hook";
import { ButtonConnect } from "../../../components/ButtonConnect/ButtonConnect";

// Local interface declaration
interface Props {
  isForgotPasswordClicked: boolean;
  setIsForgotPasswordClicked: (value: boolean) => void;
}

export const AuthenticationCredentials = ({
  setIsForgotPasswordClicked,
}: Props): ReactElement => {
  // Hook calls
  const {
    requestLogin,
    isLoading: isAuthenticateUserIsLoading,
    errorMessage,
  } = useLoginUser();
  const { getUserProfileInfo, isLoading: isGetProfileInfoIsLoading } =
    useGetUserProfileInfo();
  const { userAuthInfo, userProfileInfo } = useAppSelector((states) => states);
  const [userName, setUserName] = useState<string>("");
  const [userPassword, setUserPassword] = useState<string>("");
  const { state } = useLocation() as {
    state: IDataSendFromRegistrationToLogin;
  };
  const message = state?.message;

  // Events handlers
  const onTextInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUserName(event.target.value);
  };

  const onPasswordInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setUserPassword(event.target.value);
  };

  // Connect when clicking on connect button. Debounce function : max 1 call every 350ms.
  const onClickConnectButton = debounce(
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      event.preventDefault();
      requestLogin(userName, userPassword);
    },
    350,
    { leading: true }
  );

  const onClickForgotPassword = () => {
    setIsForgotPasswordClicked(true);
  };

  // Connect when pressing on enter key. Debounce function : max 1 call every 350ms.
  const handleConnectionOnPress = debounce(
    (event: KeyboardEvent<HTMLInputElement>) => {
      if (event.code === "Enter") {
        requestLogin(userName, userPassword);
      }
    },
    350,
    {
      leading: true,
    }
  );

  useEffect(() => {
    // When token exist (i.e we have authenticated the user) and we don't already have user profile info in Redux store-> get user profile information from local storage or from backend.
    if (userAuthInfo.userToken && !userProfileInfo.id) {
      getUserProfileInfo(userAuthInfo.userToken);
    }
  }, [userAuthInfo.userToken]);

  return (
    <form className="authentication-credentials">
      {isAuthenticateUserIsLoading || isGetProfileInfoIsLoading ? (
        <div className="authentication-credentials__loading-container">
          <LoadingComponent diameter={180} />
        </div>
      ) : (
        <>
          <div className="authentication-credentials__inputs">
            <div className="authentication-credentials__login-notice">
              {message && <p className="register-successfull">{message}</p>}
            </div>
            <div className="authentication-credentials__TextInput">
              <TextInput
                value={userName}
                onChange={onTextInputChange}
                onKeyPress={handleConnectionOnPress}
                placeholder="Identifiant*"
                autoComplete="username"
              />
            </div>
            <div className="authentication-credentials__PasswordInput">
              <PasswordInput
                value={userPassword}
                onChange={onPasswordInputChange}
                onKeyPress={handleConnectionOnPress}
                placeholder="Mot de passe*"
                autoComplete="current-password"
              />
            </div>
          </div>

          <div className="authentication-message">
            {errorMessage && <ErrorMessage errorMessage={errorMessage} />}
          </div>

          {/* <div className="authentication__rememberMe">
            <AuthenticationRememberMe
              isRememberMeClicked={isRememberMeClicked}
              setIsRememberMeClicked={setIsRememberMeClicked}
            />
          </div> */}
          <div className="authentication-buttons">
            <ButtonConnect
              onClick={onClickConnectButton}
              type="submit"
              name="Connexion"
              data-testid="button-connect"
            />
            <button
              className="go-to-forget-password"
              onClick={onClickForgotPassword}
              data-testid="button-forgot-password"
            >
              Mot de passe oublié ?
            </button>
          </div>
        </>
      )}
    </form>
  );
};
