// libraries import
import { useState } from "react";

// Custom hook that handle the visibility of the modal.
// 
// "Parameters"
// ----------
// visible: boolean
//     Boolean. Is the modal visible or not ? 
//     if visible === true , the modal is displayed on the screen
// 
// toggle: function
//     Changes the visible state to the opposite state. (if visible === true, when  function is called, visible === false) 
// 
// Returns
// ----------
// Toggle and visible

interface useModalInterface {
  isModalVisible: boolean;
  toggleModal: () => void
}

export const useModal = (): useModalInterface => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const toggleModal = ():void => {
    setIsModalVisible(!isModalVisible);
  }

  return { toggleModal, isModalVisible };
};
