// Libraries import
import { useState } from "react";
import { useAxiosPut } from "../useAxiosPut";

// Interfaces import
import {
  IUpdatePasswordBodyRequest,
  IUpdtatePasswordResponse,
} from "../../interfaces/interfaces";

// useUpdatePassword : return tools to updtate password from user account page
// Parameters
// ----------
// RAS
//
// Returns
// ----------
// Function UpdatePassword
//     Function to call to updtate password from user account page
// Boolean isLoading
//     Indicate if the asynchronous request is on going
// String errorMessage
//     Error message (no parameters or request error message)

export const useUpdatePassword = () => {
  const BACKEND_URL = process.env.REACT_APP_API_AUTHENTICATION_URL;
  const {
    axiosPutRequest,
    isLoading: axiosIsLoading,
    errorMessage: axiosErrorMessage,
  } = useAxiosPut();
  const [errorMessage, setErrorMessage] = useState<string>("");

  // UpdatePassword : send Put request to updtate password from user account page
  // Parameters
  // ----------
  // userID: String
  //      the id of the user
  //oldPassword : string
  //      the old password
  //newPassword : string
  //      the new password
  //newPasswordConfirmed
  //      the password confirmed
  //Returns
  // ----------
  // Boolean -> indicate if the request is successfull or not

  const UpdatePassword = async (
    userID: string,
    userToken: string,
    oldPassword: string,
    newPassword: string,
    newPasswordConfirmed: string
  ): Promise<boolean> => {
    setErrorMessage("");

    if (oldPassword && newPassword && newPasswordConfirmed) {
      if (newPassword !== newPasswordConfirmed) {
        setErrorMessage("Vos deux mots de passe sont différents");
      } else if (newPassword === oldPassword) {
        setErrorMessage(
          "Veuillez choisir un mot de passe différent de l'ancien"
        );
      } else {
        const updatePassworBodyRequest: IUpdatePasswordBodyRequest = {
          userID,
          oldPassword,
          newPassword,
        };

        const response = await axiosPutRequest<
          string & IUpdtatePasswordResponse
        >(
          `${BACKEND_URL}/api/User/${userID}/updatePassword`,
          updatePassworBodyRequest,
          {
            headers: { Authorization: `Bearer ${userToken}` },
          }
        );

        if (response && response.data === "Success") {
          setErrorMessage(
            "Mot de passe changé avec succés ! Vous allez maintenant être déconnecté"
          );
          return true;
        } else {
          setErrorMessage(axiosErrorMessage);
        }
      }
    } else {
      setErrorMessage("Merci de remplir tous les champs");
    }

    return false;
  };

  return {
    UpdatePassword,
    isLoading: axiosIsLoading,
    errorMessage: errorMessage ? errorMessage : axiosErrorMessage,
  };
};
