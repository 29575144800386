import "./businessContactView.scss";

// Libraries import
import React, { useEffect } from "react";

// Component import
import { LoadingComponent } from "@web/shared/dist/components/Old/LoadingComponent/LoadingComponent";

// Services import
import { useAppSelector } from "../../../redux/store/hook";
import { useRequestProjectManagerInfo } from "../../../services/contactPage/useRequestProjectManagerInfo";

// Images import
import PictoTelephone from "../../../assets/images/picto_telephone.png";

export const BusinessContactView: React.FC = () => {
  const { projectManagerInfo, userProfileInfo, userAuthInfo } = useAppSelector(
    (states) => states
  );
  const { requestProjectManagerInfo, isLoading, errorMessage } =
    useRequestProjectManagerInfo();

  useEffect(() => {
    // If projectManagerId exist and we don't already have the project manager info in Redux store-> get project manager information from CRM.
    if (userProfileInfo.projectManagerId && !projectManagerInfo.email) {
      requestProjectManagerInfo(
        userAuthInfo.userToken,
        userProfileInfo.projectManagerId
      );
    }
  }, [userProfileInfo.projectManagerId]);

  return (
    <div className="business-contact-view">
      <div className="picto">
        <img src={PictoTelephone} alt="picto-telephone" />
      </div>

      <h3>Contactez votre conseiller</h3>
      <p>
        Votre conseiller vous accompagne tout au long de votre projet
        d&apos;installation.
      </p>
      {isLoading ? (
        <LoadingComponent diameter={60} />
      ) : errorMessage || !projectManagerInfo.email ? (
        <p className="contact-error-message">
          Nous n&apos;avons pas réussi à récupérer les informations de votre
          conseiller.
        </p>
      ) : (
        <div className="contact-information">
          {/*Static data temporary -> To make dynamic in next version*/}
          <p>{`${projectManagerInfo.firstname} ${projectManagerInfo.lastname}`}</p>
          <p>{projectManagerInfo.email}</p>
          <p>{projectManagerInfo.phone}</p>
        </div>
      )}
    </div>
  );
};
