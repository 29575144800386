//App reducer actions types
export const SET_USER_AUTH_INFO = "SET_USER_AUTH_INFO";
export const SET_USER_PROFILE_INFO = "SET_USER_PROFILE_INFO";
export const SET_PROJECT_MANAGER_INFO = "SET_PROJECT_MANAGER_INFO";
export const SET_UPLOAD_DOCUMENTS_INFO = "SET_UPLOAD_DOCUMENTS_INFO";
export const SET_COMMAND_INFORMATION = "SET_COMMAND_INFORMATION";
export const SET_INITAL_STATE = "SET_INITAL_STATE";
export const UPDATE_UPLOAD_DOCUMENT_STATUS = "UPDATE_UPLOAD_DOCUMENT_STATUS";
export const SET_USER_ORDERS = "SET_USER_ORDERS";
export const SET_USER_DOCUMENTS = "SET_USER_DOCUMENTS";
export const SET_SELECTED_ORDER = "SET_SELECTED_ORDER";
export const SET_USER_ALL_PROJECTS = "SET_USER_ALL_PROJECTS";
export const SET_CHANTIER_INFORMATION = "SET_CHANTIER_INFORMATION";
export const SET_DOWNLOAD_DOCUMENTS_INFO = "SET_DOWNLOAD_DOCUMENTS_INFO";
export const SET_USER_NUMBER_DOCUMENT_TO_UPLOAD =
  "SET_USER_NUMBER_DOCUMENT_TO_UPLOAD";
