import React from "react";

//style import
import "./remboursementAidesContentDetail.scss";

//Service import
import { notificationDictionary } from "../../../../data/notificationDictionary";

//Interface import
import {
  IChantierInformation,
  ITabData,
} from "../../../../interfaces/interfaces";
//Local interface
interface Props {
  data?: ITabData;
  type: "MPR" | "CEE" | "PrimeAutoConso";
  chantierInformation: IChantierInformation;
}

export const RemboursementAidesContentDetail: React.FC<Props> = ({
  data,
  type,
  chantierInformation,
}) => {
  const montantPrime =
    type === "MPR"
      ? chantierInformation.montantMPR
      : type === "CEE"
      ? chantierInformation.montantCEE
      : type === "PrimeAutoConso"
      ? chantierInformation.primeAutoConso
      : null;

  return (
    <div className="RemboursementAidesContentDetail__AideContainer">
      <div className="RemboursementAidesContentDetail__Aide">
        {data?.notification && (
          <div className="RemboursementAidesContentDetail__Picto">
            <img
              src={notificationDictionary[data.notification]}
              alt="Picto alert"
            />
          </div>
        )}

        {type == "PrimeAutoConso" && (
          <div className="RemboursementAidesContentDetail__Picto">
            <img
              src={notificationDictionary["validatedBlue"]}
              alt="Picto alert"
            />
          </div>
        )}

        <div className="RemboursementAidesContentDetail__Text">
          <div className="RemboursementAidesContentDetail__Title">
            {type === "MPR" ? (
              <p>MaPrimeRénov&apos;</p>
            ) : type === "CEE" ? (
              <p>CEE</p>
            ) : (
              <p>Prime à l’autoconsommation</p>
            )}
          </div>
          <div className="RemboursementAidesContentDetail__message">
            {data?.message ? (
              <p>{data.message}</p>
            ) : (
              <p>
                Pour récupérer votre prime à l’autoconsommation, vous devrez
                fournir certains documents pour attester de la pose de panneaux
                solaires sur le toit de votre maison.
                <br />
                Vous pouvez en faire la demande lors de votre raccordement. Elle
                sera ensuite répartie sur une durée de cinq ans et versée
                directement par EDF Obligation d’Achat, en même temps que les
                montants qui vous seront versés suite à la revente de votre
                surplus d’énergie.
              </p>
            )}
          </div>
          <div className="RemboursementAidesContentDetail__Information">
            {montantPrime && data?.notification != "exclamationPointRed" ? (
              <p>
                Montant de l&apos;aide estimée : <span>{montantPrime}€</span>
              </p>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
      {/* <div className="RemboursementAidesContentDetail__AsterisqueOne">
        <p>
          *Votre dossier d&apos;aide est en cours de traitement par TUCO.
        </p>
      </div> */}
    </div>
  );
};
