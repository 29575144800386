import "./tucoenergieAdressView.scss";

// Libraries import
import React from "react";

// Images import
import PictoLocation from "../../../assets/images/picto_adresse-postale.png";

export const TucoenergieAdressView: React.FC = () => {
  return (
    <div className="tucoenergie-adress-view">
      <div className="picto">
        <img src={PictoLocation} alt="picto-location" />
      </div>

      <h3>Notre adresse</h3>
      <div className="tucoenergie-adress">
        <p>TUCO ENERGIE</p>
        <p>Tour Ariane</p>
        <p>5, place de la Pyramide</p>
        <p>92088 Paris La Défense Cedex</p>
      </div>
    </div>
  );
};
