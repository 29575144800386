  // Parameters
  // ----------
  // jwtToken: String
  //     the authentication JWT token
  //
  // Returns
  // ----------
  // Number or null
  //     time until token expiration, in milliseconds

export const getTokenExpirationDate = (jwtToken?: string): number | null => {
    if (!jwtToken) {
        return null;
    }

    const jwt = JSON.parse(atob(jwtToken.split('.')[1]));

    // multiply by 1000 to convert seconds into milliseconds
    return jwt && jwt.exp && jwt.exp * 1000 || null;
};
