//  library imports
import React, { ReactElement } from "react";
import "./uploadFileDisplay.scss";

// component imports
import { ButtonExitCross } from "../../../components/ButtonExitCross/ButtonExitCross";

// service imports
import { formatBytes } from "../../../services/uploadDocuments/formatBytes";

// interface imports
import { IUploadFileData } from "../../../interfaces/interfaces";

interface Props {
  filesToUpload: IUploadFileData[];
  onClickRemoveFile: (fileNameToRemove: string) => void;
}

export const UploadFileDisplay = ({
  filesToUpload,
  onClickRemoveFile,
}: Props): ReactElement => {
  return (
    <div className="uploadFileDisplay">
      {filesToUpload.map((file, index) => {
        return (
          <div className="uploadFileDisplay__FileInformation" key={index}>
            <p>
              <span>{file.fileName}</span> - {formatBytes(file.fileSize)}
            </p>
            <div
              className="uploadFileDisplay__DeleteButton"
              onClick={() => onClickRemoveFile(file.fileName)}
            >
              <ButtonExitCross
                diameter={18}
                onClick={() => onClickRemoveFile(file.fileName)}
              />
            </div>
          </div>
        );
      })}
    </div>
  );
};
