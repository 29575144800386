import "./singleFileDisplay.scss";

// Libraries imports
import React, { ReactElement } from "react";

// Images imports
import PictoDocument from "../../assets/images/uploadDocuments/picto-document.png";

// Local interface declaration
export interface DocumentSentInterface {
  fileName?: string | null;
  fileLink?: string;
}

export const SingleFileDisplay: React.FC<DocumentSentInterface> = ({
  fileName = "Nom inconnu",
  fileLink,
}): ReactElement => {
  const regex = /_/g;
  const fileNameFormatted =
  fileName?.replace(regex, " ")
    
  // EventHandler
  const downloadFile = (
    //we use this function to avoid bugs when fileName doesn't have .pdf at the end
    fileLink: string | undefined,
    fileName: string | null
  ) => {
    if (fileLink && fileName) {
      fetch(fileLink)
        .then((f) => f.blob())
        .then((blob) => {
          const newFileUrl = window.URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.href = newFileUrl;
          document.body.appendChild(link);
          link.download = fileName;
          link.click();
          document.body.removeChild(link);
          setTimeout(() => {
            URL.revokeObjectURL(newFileUrl);
          }, 100);
        });
    }
  };

  return (
    <div
      className="singleFileDisplay"
      style={{ cursor: fileLink ? "pointer" : "default" }}
      onClick={() => downloadFile(fileLink, fileName)}
    >
      <div className="singleFileDisplay__DocumentName">
        <p>{fileNameFormatted}</p>
      </div>

      <button
        className="singleFileDisplay__Picto"
        style={{ cursor: fileLink ? "pointer" : "default" }}
      >
        <img src={PictoDocument} alt="Pictogramme d'un document" />
      </button>
    </div>
  );
};
