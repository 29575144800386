import React from "react";

//Style import
import "./userAccountContent.scss";

//picto import
import updatePicto from "../../../assets/images/update-picto.svg";

//Interface import
import { IUserProfileInfo } from "../../../interfaces/interfaces";
import { useModal } from "../../../services/useModal";
import { ModalContainer } from "../../Modal/Modal";
import { ModalUpdatePassword } from "../ModalUpdatePassword/ModalUpdatePassword";

interface Props {
  userProfileInfo: IUserProfileInfo;
}

export const UserAccountContent: React.FC<Props> = ({ userProfileInfo }) => {
  const { toggleModal, isModalVisible } = useModal(); // Modal setup : Custom hook that handles the display of the modal on the screen.

  const splitIndex = userProfileInfo.adresse?.search(/\d{5}/);

  const zipCode =
    splitIndex === -1 ? "" : userProfileInfo.adresse?.slice(splitIndex);
  const adresseFormat = zipCode
    ? userProfileInfo.adresse?.slice(0, splitIndex)
    : userProfileInfo.adresse;

  return (
    <div className="UserAccountContent">
      <h2>Mes informations</h2>
      <div className="UserAccountContent__infos">
        <h5>Adresse</h5>
        {adresseFormat ? (
          <>
            <p>{adresseFormat}</p>
            <p>{zipCode}</p>
          </>
        ) : (
          "Pas d'informations"
        )}
      </div>
      <div className="UserAccountContent__infos">
        <h5>Numéro de contact</h5>
        <p>
          {userProfileInfo.phone ? userProfileInfo.phone : "Pas d'informations"}
        </p>
      </div>
      <div className="UserAccountContent__infos">
        <h5>Email de connexion / contact</h5>
        <p>
          {userProfileInfo.email ? userProfileInfo.email : "Pas d'informations"}
        </p>
      </div>
      <div className="UserAccountContent__infos">
        <div>
          <h5>Mot de passe</h5>
          <img
            src={updatePicto}
            alt="updtate picto"
            data-testid="update-picto"
            onClick={() => {
              toggleModal();
            }}
          />
        </div>
        <p>********</p>
      </div>
      {isModalVisible && (
        <ModalContainer onClick={toggleModal}>
          <ModalUpdatePassword onClick={toggleModal} />
        </ModalContainer>
      )}
    </div>
  );
};
