import React from "react";

// Services import
import { formatVisiteTechniqueDate } from "./formatVisiteTechniqueDate";

// Interface import
import {
  IChantierInformation,
  ITabData,
  IVisiteTechniqueInfo,
} from "../../../interfaces/interfaces";

// Hook
export const formatDataTabVisitesTechniques = (
  chantierInformation: IChantierInformation
): ITabData[] | undefined => {
  const uniqueVisitesTechniques: IVisiteTechniqueInfo[] = [];

  // If two visites techniques have the same date, we keep the first one and we add to it the product name (or the typeDeProduit if the product name doesn't exist) of the second one.
  chantierInformation.visiteTechniqueInfos?.forEach((visiteTechnique) => {
    const index = uniqueVisitesTechniques?.findIndex(
      (uniqueVisiteTechnique) =>
        uniqueVisiteTechnique.dateVisite === visiteTechnique.dateVisite
    );
    if (index === -1) {
      uniqueVisitesTechniques.push(visiteTechnique);
    } else {
      // We need to handle the case where there is no productName in one or the other visiteTechnique
      const newVisiteTechnique = { ...uniqueVisitesTechniques?.[index] };
      if (newVisiteTechnique.productName) {
        newVisiteTechnique.productName =
          uniqueVisitesTechniques?.[index]?.productName +
          ", " +
          (visiteTechnique.productName?.toLocaleLowerCase() ||
            visiteTechnique.typeDeProduit?.toLocaleLowerCase() ||
            "");
      } else if (
        newVisiteTechnique.typeDeProduit !== visiteTechnique.typeDeProduit
      ) {
        newVisiteTechnique.typeDeProduit =
          uniqueVisitesTechniques?.[index]?.typeDeProduit +
            ", " +
            visiteTechnique.typeDeProduit?.toLocaleLowerCase() || "";
      }
      uniqueVisitesTechniques[index] = newVisiteTechnique;
    }
  });

  const visitesTechniquesFormatedData = uniqueVisitesTechniques?.map(
    (visiteTechnique) => {
      return ((): ITabData => {
        // The comments at the right of the status are the status displayed to the user
        switch (visiteTechnique.statutVT) {
          case "À planifier": // À planifier
            return {
              isTabActive: true,
              notification: "sandglassOrange",
              productName:
                visiteTechnique.productName ||
                visiteTechnique.typeDeProduit ||
                undefined,
              message: <>La visite technique est en cours de planification.</>,
            };
          case "En cours": // Planifiée
            return {
              isTabActive: true,
              isCancelMeetingDisplayed: true,
              notification: "sandglassOrange",
              productName:
                visiteTechnique.productName ||
                visiteTechnique.typeDeProduit ||
                undefined,
              message: (
                <p>Planifiée le {formatVisiteTechniqueDate(visiteTechnique)}</p>
              ),
            };
          case "Planifiée": // Planifiée
            return {
              isTabActive: true,
              isCancelMeetingDisplayed: true,
              notification: "sandglassOrange",
              productName:
                visiteTechnique.productName ||
                visiteTechnique.typeDeProduit ||
                undefined,
              message: (
                <p>Planifiée le {formatVisiteTechniqueDate(visiteTechnique)}</p>
              ),
            };
          case "Réalisée": // Réalisée
            return {
              isTabActive: false,
              notification: "validatedBlue",
              productName:
                visiteTechnique.productName ||
                visiteTechnique.typeDeProduit ||
                undefined,
              message: (
                <p>Réalisée le {formatVisiteTechniqueDate(visiteTechnique)}</p>
              ),
            };
          case "Annulée": // Annulée
            return {
              isTabActive: false,
              notification: "exclamationPointOrange",
              isBlockHided: true,
              productName:
                visiteTechnique.productName ||
                visiteTechnique.typeDeProduit ||
                undefined,
              message: <p>La visite technique a été annulée.</p>,
            };
          case "Non réalisable - à replanifier": // À replanifier
            return {
              isTabActive: true,
              notification: "exclamationPointOrange",
              productName:
                visiteTechnique.productName ||
                visiteTechnique.typeDeProduit ||
                undefined,
              message: (
                <p>
                  La visite technique n&apos;a pas pu être réalisée. Tucoenergie
                  vous recontactera pour planifier une nouvelle date.
                </p>
              ),
            };
          default: // Information non disponible
            return {
              isTabActive: false,
              notification: "",
              productName:
                visiteTechnique.productName ||
                visiteTechnique.typeDeProduit ||
                undefined,
              message: <>Aucune information disponible à date.</>,
            };
        }
      })();
    }
  );

  return visitesTechniquesFormatedData;
};
