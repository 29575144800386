import styles from "./buttonAction.module.scss";

import React, { FC } from "react";

//Import imgae
import actionWhiteArrow from "../../assets/images/action-white-arrow.svg";
import actionOrangeArrow from "../../assets/images/action-orange-arrow.svg";

// Local interface declaration
interface Props {
  isWhite?: boolean;
}

export const ButtonAction: FC<Props> = ({ isWhite = false }) => {
  return (
    <div
      className={
        styles.buttonAction + " " + `${isWhite ? styles.white : styles.orange}`
      }
    >
      <img
        src={actionOrangeArrow}
        alt="action-arrow"
        className={styles.arrowWhite}
      />

      <img
        src={actionWhiteArrow}
        alt="action-arrow"
        className={styles.arrowOrange}
      />
    </div>
  );
};
