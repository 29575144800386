import "./noDocument.scss";

// Libraries import
import React from "react";

// Local interface declaration
interface Props {
  text?: string;
}

export const NoDocument: React.FC<Props> = ({ text = "Aucun document" }) => {
  return (
    <p className="no-document" data-testid="no-document">
      {text}
    </p>
  );
};
