import React, { useState, KeyboardEvent } from "react";
import { ErrorMessage } from "@web/shared/dist/components/Old/ErrorMessage/ErrorMessage";
import { LoadingComponent } from "@web/shared/dist/components/Old/LoadingComponent/LoadingComponent";
import { MainButton } from "@web/shared/dist/components/Old/MainButton/MainButton";
import { PasswordInput } from "@web/shared/dist/components/Old/PasswordInput/PasswordInput";

import { useAppSelector } from "../../../redux/store/hook";
import { useDisconnectApp } from "../../../services/useDisconnectApp";
import { useUpdatePassword } from "../../../services/UserAccount/useUpdatePassword";

import "./modalUpdatePassword.scss";

interface Props {
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
}

export const ModalUpdatePassword: React.FC<Props> = ({ onClick }: Props) => {
  const { userAuthInfo } = useAppSelector((states) => states);
  const { UpdatePassword, errorMessage, isLoading } = useUpdatePassword();
  const { disconnectApp } = useDisconnectApp();

  const [oldPassword, setOldPassword] = useState<string>("");
  const [newPassworld, setNewPassworld] = useState<string>("");
  const [newPasswordConfirmed, setNewPasswordConfirmed] = useState<string>("");

  const onValidateUpdatePassword = async () => {
    if (
      userAuthInfo.userId &&
      userAuthInfo.userToken &&
      (await UpdatePassword(
        userAuthInfo.userId,
        userAuthInfo.userToken,
        oldPassword,
        newPassworld,
        newPasswordConfirmed
      ))
    ) {
      setTimeout(disconnectApp, 5000);
    }
  };

  const onEnterValidateUpdatePassword = async (
    event: KeyboardEvent<HTMLInputElement>
  ) => {
    if (event.code === "Enter") {
      if (
        userAuthInfo.userId &&
        userAuthInfo.userToken &&
        (await UpdatePassword(
          userAuthInfo.userId,
          userAuthInfo.userToken,
          oldPassword,
          newPassworld,
          newPasswordConfirmed
        ))
      ) {
        setTimeout(disconnectApp, 5000);
      }
    }
  };

  return (
    <div className="ModalUpdatePassword">
      <h2 className="ModalUpdatePassword__ModaleTitle">
        Modifier mon mot de passe de connexion
      </h2>
      <div className="ModalUpdatePassword__PasswordInput">
        <p>Je saisis mon mot de passe actuel</p>
        <PasswordInput
          value={oldPassword}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            setOldPassword(event.target.value)
          }
          autoComplete="new-password"
          placeholder="Mot de passe actuel"
        />
      </div>
      <div className="ModalUpdatePassword__PasswordInput">
        <p>Je saisis mon nouveau mot de passe</p>
        <PasswordInput
          value={newPassworld}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            setNewPassworld(event.target.value)
          }
          autoComplete="new-password"
          placeholder="Nouveau mot de passe"
        />
      </div>
      <div className="ModalUpdatePassword__PasswordInput">
        <p>Je confirme mon nouveau mot de passe</p>
        <PasswordInput
          value={newPasswordConfirmed}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            setNewPasswordConfirmed(event.target.value)
          }
          onKeyPress={onEnterValidateUpdatePassword}
          autoComplete="new-password"
          placeholder="Confirmation mot de passe"
        />
      </div>

      {isLoading ? (
        <LoadingComponent diameter={50} />
      ) : (
        <div className="ModalUpdatePassword__ErrorMessage">
          <ErrorMessage errorMessage={errorMessage} />{" "}
        </div>
      )}

      <div className="ModalUpdatePassword__ButtonContainer">
        <MainButton onClick={onClick} name="Annuler" color="grey" />

        <MainButton onClick={onValidateUpdatePassword} name="Enregistrer" />
      </div>
    </div>
  );
};
