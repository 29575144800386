// Interfaces import
import { IChantierInformation } from "../../../interfaces/interfaces";

export const isRaccordementTabDisplayed = (
  chantierInformation: IChantierInformation
): boolean => {
  return chantierInformation.statutRaccordement &&
    chantierInformation.statutRaccordement !== "Non nécessaire"
    ? true
    : false;
};
