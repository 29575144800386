import "./noDocumentFound.scss";

// Libraries import
import React from "react";

export const NoDocumentFound: React.FC = () => {
  return (
    <p className="no-document-found">
      Aucun document lié à votre commande n&apos;a été trouvé.
    </p>
  );
};
