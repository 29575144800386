// Other imports
import { useAppDispatch } from "../redux/store/hook";
import { setInitalStateAction } from "../redux/appActions";

// Local interface declaration
interface IFunctionReturn {
  disconnectApp: () => void;
}

// useDisconnectApp : return function to disconnect from the App
// Parameters
// ----------
// RAS
//
// Returns
// ----------
// Function disconnectApp
//     Function to disconnect from the app and delete/reset all local storage and redux state
export const useDisconnectApp = (): IFunctionReturn => {
  const dispatch = useAppDispatch();

  // disconnectApp : disconnect from the app and delete/reset all local storage and redux state
  // Parameters
  // ----------
  // RAS
  // Returns
  // ----------
  // Void
  const disconnectApp = (): void => {
    localStorage.removeItem("userAuthInfo");
    localStorage.removeItem("userProfileInfo");
    sessionStorage.removeItem("userOrders");
    dispatch(setInitalStateAction());
  };
  return { disconnectApp };
};
