import "./DocumentToSend.scss";

// Libraries imports
import React, { ReactElement } from "react";

// Components imports
import { ButtonUpload } from "../../../components/ButtonUpload/ButtonUpload";

// Services import
import { IuploadDocumentsInformation } from "../../../interfaces/interfaces";

// Local interface declaration
export interface DocumentToSendInterface {
  document: IuploadDocumentsInformation;
  onClickUploadDocument: (clickedDoc: IuploadDocumentsInformation) => void;
}

export const DocumentToSend: React.FC<DocumentToSendInterface> = ({
  document,
  onClickUploadDocument,
}): ReactElement => {
  return (
    <div className="DocumentToSend" data-testid="document-to-send">
      <div className="DocumentToSend__DocumentName">
        <p>{document.fileName}</p>
      </div>
      <div className="DocumentToSend__UploadButton DocumentToSend__Mobile">
        <ButtonUpload
          title="Joindre"
          onClick={() => onClickUploadDocument(document)}
        />
      </div>
      <div className="DocumentToSend__UploadButton DocumentToSend__Desktop">
        <ButtonUpload
          title="Joindre un fichier"
          onClick={() => onClickUploadDocument(document)}
        />
      </div>
    </div>
  );
};
