import "./progressBar.scss";

// Libraries import
import React from "react";

// Local interface declaration
interface Props {
  montantTotalRecu?: number | null;
  resteACharge?: number | null;
}

export const ProgressBar: React.FC<Props> = ({
  montantTotalRecu,
  resteACharge,
}) => {
  const percentage =
    Math.round(((montantTotalRecu || 0) / (resteACharge || 1)) * 100) > 100
      ? 100
      : Math.round(((montantTotalRecu || 0) / (resteACharge || 1)) * 100);

  const resteAPayer =
    Math.round((resteACharge || 0) - (montantTotalRecu || 0)) > 0
      ? Math.round((resteACharge || 0) - (montantTotalRecu || 0))
      : 0;

  return (
    <div className="progressBar">
      <div className="progressBar__Content">
        <div
          className="progressBar__Progression"
          style={{ height: "24px", width: `${percentage}%` }}
        >
          <p className="progressBar__ProgressionPercentage">
            {/* If percentage is too small : not enough space available to display the percentage */}
            {percentage > 14 ? percentage + " %" : ""}
          </p>
        </div>
      </div>
      <div className="progressBar__Information">
        <p className="progressBar__Payed">
          {new Intl.NumberFormat("fr-FR").format(
            Math.round(montantTotalRecu || 0)
          ) + " €"}
        </p>
        <p className="progressBar__LeftToPay">
          {"reste à payer " +
            new Intl.NumberFormat("fr-FR").format(resteAPayer) +
            " €"}
        </p>
      </div>
    </div>
  );
};
