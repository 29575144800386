// For now component only used in "Send message page", wich is currently not used in the app
import "./buttonUpload.scss";

// Libraries import
import React from "react";

// Interfaces declaration
interface Props {
  title: string;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
}

export const ButtonUpload: React.FC<Props> = ({
  onClick,
  title = "Uploader",
}) => {
  return (
    <button
      className="buttonUpload"
      onClick={onClick}
      data-testid="button-upload"
    >
      {title}
    </button>
  );
};
