import styles from "./logo.module.scss";
import React, { FC } from "react";

import logoTuco from "../../assets/images/HeaderElements/main-logo.png";

export const Logo: FC = () => {
  return (
    <div className={styles.logo}>
      {/* eslint-disable */}

      <img src={logoTuco} alt="Tucoenergie logo" height={46} width={261} />

      {/* eslint-enable */}
    </div>
  );
};
